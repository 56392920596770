import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import ItemsList from '@shared/ui/display/ItemsList';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { StepContainer, StepTitle } from '@shared/ui/sidebars/shared';
import { ContainerColumn } from '@shared/ui/display/Containers';
import Button from '@shared/ui/buttons/Button';
import { BodyLarge, BodyLargeBold } from '@shared/ui/display/Typography';
import ShareLinkInput from '@shared/ui/inputs/ShareLinkInput';
import { getAbsolutePath } from '@app/routing/helpers';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import usePortfolioCreate from '../usePortfolioCreate';
const FeaturesContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    marginBottom: props.theme.spacing_sizes.s * 5,
}));
const FeatureElement = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 0.5,
    padding: props.theme.spacing_sizes.xm,
    borderRadius: 8,
    background: '#F8FAFF',
    boxSizing: 'border-box',
}));
const FeatureTitle = styled(BodyLargeBold)(props => ({
    fontSize: 16,
    color: props.theme.palette.primary.main,
}));
const FeatureDescription = styled(BodyLarge)(() => ({
    fontSize: 16,
}));
const VerificationBannerContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const TitleContainer = styled.div(props => ({
    backgroundColor: props.theme.palette.primary.main,
    padding: `${props.theme.spacing_sizes.xs * 2}px`,
    textAlign: 'center',
    borderRadius: '12px 12px 0 0',
}));
const BannerTitle = styled(BodyLargeBold)(props => ({
    fontSize: 16,
    fontWeight: 600,
    color: props.theme.palette.text.disabled,
    lineHeight: '24px',
    textAlign: 'center',
}));
const DetailsContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    padding: `${props.theme.spacing_sizes.xs * 2.5}px 
        ${props.theme.spacing_sizes.s * 5}px ${props.theme.spacing_sizes.s * 5}px`,
    background: '#F8FAFF',
    borderRadius: '0 0 12px 12px',
    boxSizing: 'border-box',
    alignItems: 'center',
}));
const PortfolioName = styled.span(() => ({
    fontWeight: 600,
}));
const VerificationBanner = ({ title, content, }) => (_jsxs(VerificationBannerContainer, { children: [_jsx(TitleContainer, { children: title }), _jsx(DetailsContainer, { children: content })] }));
const Success = ({ handleClose }) => {
    const theme = useTheme();
    const { goToNextStep, currentUserProfile, sidebarFormValues, isVerified, } = usePortfolioCreate({ handleClose });
    const { t } = useTranslation();
    const { routes } = useAppNavigation();
    const { title, features, unverifiedBannerTitle, unverifiedBannerDescription, shareLink, shareLinkLabel, } = {
        title: Trans({
            i18nKey: 'portfolio.create_portfolio.steps.success.title',
            ns: 'common',
            components: [
                _jsx(PortfolioName, {}, 'portfolio-name'),
            ],
            values: {
                portfolioName: sidebarFormValues.portfolioName,
            },
        }),
        features: t('portfolio.create_portfolio.steps.success.features', { ns: 'common', returnObjects: true }),
        unverifiedBannerTitle: t('portfolio.create_portfolio.steps.success.unverified_banner.title', { ns: 'common' }),
        unverifiedBannerDescription: t('portfolio.create_portfolio.steps.success.unverified_banner.description', { ns: 'common', returnObjects: true }),
        shareLink: `${document.location.origin}${getAbsolutePath(routes.PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION, {
            nickname: String(currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.username),
            portfolioId: '1',
        })}`,
        shareLinkLabel: t('portfolio.create_portfolio.steps.success.share_link', { ns: 'common' }),
    };
    return (_jsx(SidebarInner, { cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepContainer, { title: _jsx(StepTitle, { children: title }), content: (_jsxs(_Fragment, { children: [_jsx(FeaturesContainer, { children: features
                            .map(({ title: featureTitle, description, }, index) => (_jsxs(FeatureElement, { children: [_jsx(FeatureTitle, { children: featureTitle }), _jsx(FeatureDescription, { children: description })] }, `feature-${index}`))) }), !isVerified && (_jsx(VerificationBanner, { title: _jsx(BannerTitle, { children: unverifiedBannerTitle }), content: (_jsxs(_Fragment, { children: [_jsx(ItemsList, { list: unverifiedBannerDescription, listItemStyles: {
                                        '&.MuiListItem-root:not(:last-child)': {
                                            marginBottom: theme.spacing_sizes.xm,
                                        },
                                        '& .MuiListItemIcon-root': {
                                            marginRight: theme.spacing_sizes.xs * 1.75,
                                        },
                                    }, listItemTextStyles: {
                                        span: {
                                            fontSize: 14,
                                            lineHeight: '17px',
                                            fontWeight: 500,
                                            color: theme.palette.text.primary,
                                        },
                                    }, itemPadding: '0px' }), _jsx(Button, { children: t('portfolio.create_portfolio.steps.success.unverified_banner.verification_btn', { ns: 'common' }) })] })) }))] })), buttons: (_jsxs(_Fragment, { children: [_jsx(ShareLinkInput, { label: shareLinkLabel, link: shareLink }), _jsx(Button, { size: 'large', fullWidth: true, onClick: () => goToNextStep(), children: t('portfolio.create_portfolio.steps.success.continue_btn', { ns: 'common' }) })] })), buttonsGap: theme.spacing_sizes.s * 5 }) }));
};
export default Success;
