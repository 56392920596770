import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as EditIcon } from '@icons/wolfkit-light/pencil-light.svg';
import { ContainerColumn } from '@shared/ui/display/Containers';
import Icon from '@shared/ui/icons/Icon';
const Container = styled(ContainerColumn)(() => ({
    position: 'relative',
    width: 'auto',
    height: 'auto',
}));
const Badge = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${props.theme.spacing_sizes.xs}px`,
    color: `${props.theme.customColors.surface.surface}`,
    borderRadius: `${props.theme.spacing_sizes.s * 5}px`,
    border: `3px solid ${props.theme.customColors.surface.surface}`,
    background: `${props.theme.palette.primary.main}`,
    position: 'absolute',
    left: -3,
    top: -3,
    cursor: props.disabled ? 'default' : 'pointer',
}));
const EditBadge = ({ children, onClick = () => { }, disabled = undefined, }) => {
    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };
    return (_jsxs(Container, { children: [_jsx(Badge, { onClick: handleClick, disabled: disabled, children: _jsx(Icon, { size: 16, IconComponent: EditIcon }) }), children] }));
};
export default EditBadge;
