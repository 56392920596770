var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { mapPortfolioFromResponse, usePortfolioOutletContext } from '@entities/portfolio';
import { useGetPortfolioQuery } from '@shared/api/portfolio';
import { useGetProfileByIdQuery } from '@shared/api/profile';
import { PortfolioOverview } from '@features/portfolio/portfolio-overview';
import Banner from '@shared/ui/display/Banner';
import activityData from './activity.json';
import emptyActivityData from './empty_activity.json';
import TRADING_PAIRS_DATA from './trading_pairs.json';
// using lazy loading of graphs to speed up the responsiveness of ui for their initial load and render
const PortfolioInfoGraphsLazy = lazy(() => import('./PortfolioInfoGraphs'));
const TradingActivityLazy = lazy(() => import('@entities/wallet').then(module => ({ default: module.TradingActivity })));
const TopAssetsLazy = lazy(() => import('./TopAssets'));
const PortfolioInformationContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s * 5,
}));
const PortfolioInnerContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
}));
const ContainerColumnStyled = styled(ContainerColumn)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const GeneralPortfolioInformation = () => {
    const { t } = useTranslation();
    const { portfolioId, isClosedTabs } = usePortfolioOutletContext();
    const { currentData: portfolio, isLoading } = useGetPortfolioQuery({ portfolioId }, {
        skip: typeof portfolioId === 'undefined',
        selectFromResult: (_a) => {
            var { currentData } = _a, rest = __rest(_a, ["currentData"]);
            return (Object.assign(Object.assign({}, rest), { currentData: currentData ? mapPortfolioFromResponse(currentData) : undefined }));
        },
    });
    const { currentData: traderProfile } = useGetProfileByIdQuery({ id: portfolio === null || portfolio === void 0 ? void 0 : portfolio.profileId }, { skip: typeof (portfolio === null || portfolio === void 0 ? void 0 : portfolio.profileId) === 'undefined' });
    const activity = activityData.map((activityMonth) => (Object.assign(Object.assign({}, activityMonth), { bins: activityMonth.dates })));
    const emptyActivity = emptyActivityData.map((activityMonth) => (Object.assign(Object.assign({}, activityMonth), { bins: activityMonth.dates })));
    const tradingPairsData = TRADING_PAIRS_DATA.sort((a, b) => b.tradesCount - a.tradesCount);
    const bannerText = t('pages.portfolios.general_portfolio_information.closed_portfolio_banner', {
        ns: 'common',
        date: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.deletedAt) ?
            format(new Date(portfolio.deletedAt), 'dd MMM yyyy, HH:mm') :
            '',
    });
    return (_jsxs(PortfolioInformationContainer, { children: [isClosedTabs && (_jsx(Banner, { variant: 'history', text: bannerText, elevated: true })), _jsxs(PortfolioInnerContainer, { children: [(portfolio || isLoading) && (
                    // TODO-FIX: update request logic after backend improvements
                    // TODO-FIX: add skeletons & loading state
                    _jsx(PortfolioOverview, { portfolio: portfolio, 
                        // TODO-FIX: add after b2q & subscription enabling
                        isCopyAvailable: false, isLoading: isLoading })), _jsxs(ContainerColumnStyled, { children: [_jsx(Suspense, { children: _jsx(PortfolioInfoGraphsLazy, { isLoading: isLoading }) }), _jsx(Suspense, { children: _jsx(TradingActivityLazy
                                // data={(activity as TradingActivityMonth[]).reverse()}
                                , { 
                                    // data={(activity as TradingActivityMonth[]).reverse()}
                                    data: emptyActivity.reverse(), totalTrades: 0, activeDays: 0, maxTradesMonthly: 0, isLoading: isLoading }) }), _jsx(Suspense, { children: _jsx(TopAssetsLazy, { data: [], isLoading: isLoading }) })] })] })] }));
};
export default GeneralPortfolioInformation;
