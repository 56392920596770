var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FollowIcon } from '@icons/custom/user-add-light.svg';
import { ReactComponent as UnfollowIcon } from '@icons/custom/user-delete-light.svg';
import { useAppSelector } from '@app/store/Hooks';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import Button from '@shared/ui/buttons/Button';
import { BodySemiBold } from '@shared/ui/display/Typography';
import useProfileActions from '../api/useProfileActions';
const Text = withTextEllipsis(BodySemiBold);
const ProfileActionButton = (_a) => {
    var { profileId, actionType } = _a, props = __rest(_a, ["profileId", "actionType"]);
    const { t } = useTranslation();
    const myProfileId = useAppSelector(state => { var _a; return (_a = state.user.user) === null || _a === void 0 ? void 0 : _a.activeProfileId; });
    const { followProfile, isFollowProfileInProgress, unfollowProfile, isUnfollowProfileInProgress, } = useProfileActions();
    const variant = useMemo(() => {
        let result = 'filled';
        switch (actionType) {
            case 'unfollow':
            case 'remove':
                result = 'tinted';
                break;
            case 'follow':
            default:
                result = 'filled';
        }
        return result;
    }, [actionType]);
    const icon = useMemo(() => {
        let result = FollowIcon;
        switch (actionType) {
            case 'unfollow':
            case 'remove':
                result = UnfollowIcon;
                break;
            case 'follow':
            default:
                result = FollowIcon;
        }
        return result;
    }, [actionType]);
    const text = useMemo(() => {
        let result = t('actions.follow', { ns: 'common' });
        switch (actionType) {
            case 'unfollow':
                result = t('actions.unfollow', { ns: 'common' });
                break;
            case 'remove':
                result = t('actions.remove', { ns: 'common' });
                break;
            case 'follow':
            default:
                result = t('actions.follow', { ns: 'common' });
        }
        return result;
    }, [actionType, t]);
    const isLoading = useMemo(() => (isFollowProfileInProgress || isUnfollowProfileInProgress), [isFollowProfileInProgress, isUnfollowProfileInProgress]);
    const onClick = useCallback(() => {
        if (actionType === 'follow') {
            followProfile(profileId);
        }
        else if (actionType === 'unfollow') {
            unfollowProfile(profileId);
        }
    }, [
        actionType,
        profileId,
        followProfile,
        unfollowProfile,
    ]);
    if (profileId === myProfileId) {
        return null;
    }
    return (_jsx(Button, Object.assign({ variant: variant, color: 'primary', size: 'small', startIcon: !isLoading ? icon : undefined, onClick: onClick, isLoading: isLoading }, props, { children: _jsx(Text, { tooltipText: text, letterSpacing: '-0.084px', children: text }) })));
};
export default ProfileActionButton;
