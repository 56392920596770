var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { BodySemiBold } from '@shared/ui/display/Typography';
import Button from '@shared/ui/buttons/Button';
const LoadMoreContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const withLazyLoading = (TableComponent) => {
    const TableWithLazyLoading = (_a) => {
        var { hasMore, isLoading, onLoadMore, loadingVariant = 'skeleton' } = _a, props = __rest(_a, ["hasMore", "isLoading", "onLoadMore", "loadingVariant"]);
        const { t } = useTranslation();
        return (_jsxs(_Fragment, { children: [_jsx(TableComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                , Object.assign({}, props, { loadingVariant: loadingVariant, isLoading: isLoading && loadingVariant === 'skeleton' })), _jsxs(LoadMoreContainer, { children: [!isLoading &&
                            hasMore && (_jsx(Button, { variant: 'tinted', color: 'primary', size: 'small', onClick: onLoadMore, children: _jsx(BodySemiBold, { children: t('actions.show_more', { ns: 'common' }) }) })), (isLoading && loadingVariant === 'loading-indicator') && (
                        // TODO: move size to theme
                        _jsx(CircularProgress, { size: 32 }))] })] }));
    };
    return TableWithLazyLoading;
};
export default withLazyLoading;
