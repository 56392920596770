import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { BodyMedium, BodySmall, TextEmphasizedSemiBold } from '@shared/ui/display/Typography';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
    gap: 4,
}));
const SecondaryInfoContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
}));
const TradingPairContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
    gap: 4,
}));
const TotalPnlContainer = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    color: props.theme.palette.text.secondary,
    gap: '0.5ch',
}));
const TotalPnlValue = withPercentageIncomeBadge(BodySmall);
const AssetTradesInfo = ({ tradingPair, pnl, tradesCount, tradingPairColor = undefined, className = undefined, style = undefined, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsxs(Container, { className: className, style: style, children: [typeof tradingPair === 'object' && (_jsxs(TradingPairContainer, { children: [_jsx(BodySmall, { color: tradingPairColor, children: `${tradingPair.name}` }), _jsx(TextEmphasizedSemiBold, { children: `${tradingPair.percentage} %` })] })), typeof tradingPair === 'number' && (_jsx(BodyMedium, { children: `${tradingPair} ${t('plural_variables.trading_pair', { ns: 'common', count: tradingPair })}` })), _jsxs(SecondaryInfoContainer, { children: [_jsxs(TotalPnlContainer, { children: [_jsx(TotalPnlValue, { positiveColor: 'secondary', negativeColor: 'secondary', percentageIncome: pnl }), _jsx(BodySmall, { children: t('portfolio.total_pnl') })] }), _jsx(BodySmall, { color: theme.palette.text.secondary, children: `${tradesCount} ${t('plural_variables.trade', { ns: 'common', count: tradesCount })}` })] })] }));
};
export default AssetTradesInfo;
