import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { ReactComponent as ArrowLeftIcon } from '@icons/wolfkit-solid/chevron-left-solid.svg';
import { ReactComponent as ArrowRightIcon } from '@icons/wolfkit-solid/chevron-right-solid.svg';
import { ContainerRow } from '@shared/ui/display/Containers';
import CarouselPaginationButton from '../CarouselPaginationButton';
import CarouselNavigationButton from '../CarouselNavigationButton';
const ControlsContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: props.theme.spacing_sizes.m,
}));
const PaginationButtonsContainer = styled(ContainerRow)(props => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs,
    margin: `0 ${props.theme.spacing_sizes.l}px`,
}));
const CarouselControls = ({ pagesLength, currentPageIndex, disabled = false, onNavigateLeft = () => { }, onNavigateRight = () => { }, onNavigateToPageIndex = () => { }, }) => {
    const pages = useMemo(() => {
        const pagesArr = [];
        for (let i = 0; i < pagesLength; i += 1) {
            pagesArr.push(i);
        }
        return pagesArr;
    }, [pagesLength]);
    const displayLeftButton = pagesLength > 1 && currentPageIndex > 0;
    const displayRightButton = pagesLength > 1 && currentPageIndex < pagesLength - 1;
    return (_jsxs(ControlsContainer, { children: [_jsx(CarouselNavigationButton, { display: displayLeftButton, disabled: disabled, IconComponent: ArrowLeftIcon, onClick: onNavigateLeft }), _jsx(PaginationButtonsContainer, { children: pages.map((_, idx) => (_jsx(CarouselPaginationButton, { selected: idx === currentPageIndex, disabled: disabled, onClick: () => onNavigateToPageIndex(idx) }, idx))) }), _jsx(CarouselNavigationButton, { display: displayRightButton, disabled: disabled, IconComponent: ArrowRightIcon, onClick: onNavigateRight })] }));
};
export default CarouselControls;
