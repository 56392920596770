import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatUSD } from '@shared/lib/numbers';
import { BodyMediumSemiBold } from '@shared/ui/display/Typography';
import IconText from '@shared/ui/icons/IconText';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
const WalletName = styled.span(props => ({
    fontWeight: 500,
    color: props.theme.palette.text.primary,
}));
const WalletTypeComponent = styled.span(props => ({
    marginLeft: props.theme.spacing_sizes.s,
    textTransform: 'uppercase',
}));
const WalletAmount = styled(BodyMediumSemiBold)(props => ({
    color: props.theme.palette.text.primary,
}));
const WalletValue = ({ item }) => {
    const { t } = useTranslation();
    const walletTypeNames = t('wallet_type', { ns: 'common', returnObjects: true });
    return (_jsxs(_Fragment, { children: [_jsx(IconText, { IconComponent: (item.exchangeType) ? (_jsx(ExchangeIcon, { type: item.exchangeType, size: 20, keepOriginalColor: true })) : undefined, text: (_jsxs(_Fragment, { children: [item.walletName && _jsx(WalletName, { children: item.walletName }), item.walletType && (_jsx(WalletTypeComponent, { children: walletTypeNames[item.walletType] }))] })) }), _jsx(WalletAmount, { children: formatUSD(item.amount) })] }));
};
export { WalletValue, WalletName, WalletTypeComponent as WalletType };
