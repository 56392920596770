import styled from '@emotion/styled';
import { Button as MuiButton } from '@mui/material';
import { ReactComponent as BinanceIconComponent, } from '@icons/exchanges/binance-with-text.svg';
import { BodyMedium } from '@shared/ui/display/Typography';
import Button from '@shared/ui/buttons/Button';
const LinkContainer = styled.div(props => ({
    display: 'flex',
    justifyContent: 'center',
    gap: `0 ${props.theme.spacing_sizes.xs * 1.25}px`,
}));
const Title = styled.h1(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 5,
    userSelect: 'none',
}));
const ButtonContainer = styled.div(props => ({
    width: 'inherit',
    maxWidth: 458,
    display: 'flex',
    justifyContent: 'center',
    gap: `0 ${props.theme.spacing_sizes.m}px`,
}));
// add to overrides as auth-button
const AuthButton = styled(MuiButton)(props => ({
    border: `1px solid ${props.theme.customColors.button.pill.border}`,
    color: props.theme.palette.text.primary,
    lineHeight: '20px',
    borderRadius: 6,
    padding: '13px 24px',
    '&:hover': {
        cursor: 'pointer',
        border: `1px solid ${props.theme.customColors.button.pill.border}`,
        outline: `1px solid ${props.theme.customColors.button.pill.border}`,
        color: props.theme.palette.text.primary,
        backgroundColor: 'transparent',
    },
}));
// add to overrides as submit btn?
const SubmitButton = Button;
const ButtonDivider = styled(BodyMedium)(props => ({
    color: props.theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: '19px',
}));
const BinanceIcon = styled(BinanceIconComponent)(() => ({
    width: 105,
    height: 20,
}));
const Text = styled(BodyMedium)(() => ({
    lineHeight: '24px',
}));
export { AuthButton, ButtonContainer, LinkContainer, Title, SubmitButton, ButtonDivider, BinanceIcon, Text, };
