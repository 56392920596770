import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import FormWrapper from '@pages/auth/ui/components/FormWrapper';
import ChangePasswordForm from './ui/ChangePasswordForm';
import usePasswordChange from './lib/usePasswordChange';
const ChangePassword = () => {
    const { t } = useTranslation();
    const { changePassword, isChanging } = usePasswordChange();
    return (_jsx(FormWrapper, { titleText: t('change_password.title', { ns: 'common' }), renderInnerForm: (_jsx(ChangePasswordForm, { onSubmit: changePassword, isLoading: isChanging })) }));
};
export default ChangePassword;
