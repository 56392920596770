import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '@shared/ui/inputs/Input';
import Button from '@shared/ui/buttons/Button';
import { StepFormContainer, Title } from '@widgets/sidebars/shared';
import { LocationSelect } from '@features/countries';
import { addressDetailsValidationSchema } from './validation';
import { StepTitleCounter } from '../shared';
const InputContainer = styled.div(props => ({
    marginBottom: `${props.theme.spacing_sizes.l}px`,
    width: '100%',
}));
const AddressDetails = ({ goToNextStep, initValues }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { isValid } } = useForm({
        defaultValues: initValues,
        resolver: yupResolver(addressDetailsValidationSchema(t))
    });
    return (_jsx(StepFormContainer, { title: (_jsxs(Title, { children: [_jsx(StepTitleCounter, { children: "2/4" }), t('profile.create_trader.steps.address_details.title', { ns: 'common' })] })), content: (_jsxs(_Fragment, { children: [_jsx(InputContainer, { children: _jsx(Controller, { name: 'addressLine', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { label: t('profile.create_trader.steps.address_details.address_line.title', { ns: 'common' }), placeholder: t('profile.create_trader.steps.address_details.address_line.placeholder', { ns: 'common' }), InputLabelProps: { shrink: true }, required: true }))) }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'additionalAddressLine', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { label: t('profile.create_trader.steps.address_details.additional_address_line.title', { ns: 'common' }), placeholder: t('profile.create_trader.steps.address_details.additional_address_line.placeholder', { ns: 'common' }), InputLabelProps: { shrink: true }, required: true }))) }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'city', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { label: t('profile.create_trader.steps.address_details.city.title', { ns: 'common' }), placeholder: t('profile.create_trader.steps.address_details.city.placeholder', { ns: 'common' }), InputLabelProps: { shrink: true }, required: true }))) }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'region', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { label: t('profile.create_trader.steps.address_details.region.title', { ns: 'common' }), placeholder: t('profile.create_trader.steps.address_details.region.placeholder', { ns: 'common' }), InputLabelProps: { shrink: true } }))) }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'country', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(LocationSelect, { value: field.value, onChange: field.onChange, onBlur: field.onBlur, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, label: t('profile.create_trader.steps.address_details.country.title', { ns: 'common' }), placeholder: t('profile.create_trader.steps.address_details.country.placeholder', { ns: 'common' }), required: true, fullWidth: true }));
                        } }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'postalCode', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { label: t('profile.create_trader.steps.address_details.postal_code.title', { ns: 'common' }), placeholder: t('profile.create_trader.steps.address_details.postal_code.placeholder', { ns: 'common' }), InputLabelProps: { shrink: true } }))) }) })] })), buttons: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, type: 'submit', disabled: !isValid, children: t('profile.create_trader.steps.final.btn_text', { ns: 'common' }) })), onSubmit: handleSubmit(goToNextStep) }));
};
export default AddressDetails;
