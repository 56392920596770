import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { BodyMedium } from '@shared/ui/display/Typography';
import { ContainerColumn } from '@shared/ui/display/Containers';
const Container = styled(ContainerColumn)((props) => ({
    alignItems: 'center',
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const OverallWalletsCount = ({ wallets, priorityWalletType, }) => {
    const { t } = useTranslation();
    const { walletsCount, portfoliosCount, } = useMemo(() => ({
        walletsCount: wallets.length,
        portfoliosCount: wallets.reduce((count, item) => count + (item.wallet_type === 'portfolio' ? 1 : 0), 0),
    }), [
        wallets,
    ]);
    const components = [];
    if (priorityWalletType === 'wallet') {
        components.push((_jsx(BodyMedium, { children: `${t('overview.wallets')}: ${walletsCount}` }, 'wallets')));
        if (walletsCount > 0) {
            components.push((_jsx(BodyMedium, { children: `${t('overview.portfolios')}: ${portfoliosCount}` }, 'portfolios')));
        }
    }
    else if (priorityWalletType === 'portfolio') {
        components.push((_jsx(BodyMedium, { children: `${t('overview.portfolios')}: ${portfoliosCount}` }, 'portfolios')), (_jsx(BodyMedium, { children: `${t('overview.wallets')}: ${walletsCount}` }, 'wallets')));
    }
    return (_jsx(Container, { children: components.map((component) => (component)) }));
};
export default OverallWalletsCount;
