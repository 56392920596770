import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerRow } from '@shared/ui/display/Containers';
import CurrencyTrend from './CurrencyTrend';
const PanelContainer = styled.div((props) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: props.theme.spacing_sizes.l,
}));
const CurrencyTrendContainer = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const Divider = styled.div((props) => ({
    display: 'flex',
    width: 0,
    height: '22px',
    borderRight: `2px solid ${props.theme.customColors.menu.border}`,
}));
const CurrencyTrendsPanel = () => {
    const currencyTrends = [{
            valueToSell: 'USDT', valueToBy: 'BTC', percentage: 1.58, trend: 'down', price: '$ 25.882',
        }, {
            valueToSell: 'USDT', valueToBy: 'BTC', percentage: 1.58, trend: 'down', price: '$ 25.882',
        },
        {
            valueToSell: 'USDT', valueToBy: 'BTC', percentage: 1.58, trend: 'down', price: '$ 25.882',
        },
        {
            valueToSell: 'USDT', valueToBy: 'ETH', percentage: 2.11, trend: 'up', price: '$ 1.489',
        }];
    return (_jsx(PanelContainer, { children: currencyTrends.map((currencyTrend, index) => (_jsxs(CurrencyTrendContainer, { children: [_jsx(CurrencyTrend, { currencyTrend: currencyTrend }), (currencyTrends.length > 1 &&
                    index !== currencyTrends.length - 1) && (_jsx(Divider, {}))] }, index))) }));
};
export default CurrencyTrendsPanel;
