import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { formatPortfolioPeriodDate } from '@utils/date-time';
import { BodySmallSemiBold } from '@shared/ui/display/Typography';
const formatDate = (dateString, languageCode) => {
    try {
        const date = new Date(dateString);
        return formatPortfolioPeriodDate(date, languageCode);
    }
    catch (ex) {
        return '_'; // TODO: handle
    }
};
const DatesText = styled(BodySmallSemiBold)(props => ({
    color: props.theme.palette.text.secondary,
    lineHeight: '15px',
}));
const PastMonthProfitDates = ({ startDate, endDate, }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const start = formatDate(startDate, currentLanguage);
    const end = formatDate(endDate, currentLanguage);
    return (_jsx(DatesText, { children: `${start} - ${end}` }));
};
export default PastMonthProfitDates;
