const getWalletFromExchangesList = (exchanges, walletId) => {
    var _a;
    let exchangePointer = 0;
    let walletPointer = 0;
    while (exchangePointer <= exchanges.length - 1) {
        if (walletId === ((_a = exchanges[exchangePointer].wallets[walletPointer]) === null || _a === void 0 ? void 0 : _a.id)) {
            return exchanges[exchangePointer].wallets[walletPointer];
        }
        if (walletPointer === exchanges[exchangePointer].wallets.length - 1) {
            exchangePointer += 1;
            walletPointer = 0;
        }
        else {
            walletPointer += 1;
        }
    }
    return undefined;
};
export { 
// eslint-disable-next-line import/prefer-default-export
getWalletFromExchangesList, };
