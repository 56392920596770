import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useAppSelector } from '@app/store/Hooks';
import { IsDefined } from '@shared/lib/js-ts';
import PortfolioCard from '../PortfolioCard';
import { isUserPortfolioOwner, isUserSubsbribedToPortfolio } from '../../lib';
const ProfilePortfolioCard = ({ portfolio = undefined, viewType, selected = false, disabled = false, onAction = () => { }, onClick = () => { }, isLoading = false, portfolioSubscriptions = [], }) => {
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const { user } = useAppSelector(state => state.user);
    const isUserOwner = isUserPortfolioOwner(portfolio, user);
    const isUserTrader = (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER';
    const isArchived = IsDefined(portfolio === null || portfolio === void 0 ? void 0 : portfolio.deletedAt);
    const displayState = isArchived ? 'archive' : undefined;
    const isCurrentUserSubscribed = !isUserOwner ? isUserSubsbribedToPortfolio(portfolioSubscriptions, user) : false;
    const actionType = useMemo(() => {
        if (isArchived) {
            return 'view_archive';
        }
        if (isUserTrader && isUserOwner) {
            return 'edit';
        }
        if (!isUserTrader &&
            !isUserOwner &&
            !isCurrentUserSubscribed) {
            return 'subscribe';
        }
        if (!isUserTrader &&
            !isUserOwner &&
            isCurrentUserSubscribed) {
            return 'unsubscribe';
        }
        if (isCurrentUserSubscribed) {
            return 'view';
        }
        return 'none';
    }, [
        isArchived,
        isUserTrader,
        isUserOwner,
        isCurrentUserSubscribed,
    ]);
    return (_jsx(PortfolioCard, { portfolio: portfolio, variant: 'elevated', viewType: viewType, primaryActionType: actionType, selected: selected, disabled: disabled, onAction: onAction, displayState: displayState, onClick: onClick, isLoading: isLoading }));
};
export default ProfilePortfolioCard;
