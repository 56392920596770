const mapTraderProfileFieldsToReq = (values) => {
    const { displayName, username, location, } = values;
    return {
        displayName,
        username,
        location: (location === null || location === void 0 ? void 0 : location.length) ? location : undefined,
        type: 'TRADER',
    };
};
const mapFormValuesToTraderVerificationRequestBody = (formValues) => {
    let requestBody;
    if (formValues.firstName &&
        formValues.lastName &&
        formValues.addressLine &&
        formValues.additionalAddressLine &&
        formValues.city &&
        formValues.country &&
        formValues.countryIdentityName &&
        formValues.docType &&
        formValues.docNumber &&
        formValues.docMainSideImage) {
        const documents = [formValues.docMainSideImage];
        if (formValues.docBackSideImage) {
            documents.push(formValues.docBackSideImage);
        }
        requestBody = {
            name: formValues.firstName,
            surname: formValues.lastName,
            first_line: formValues.addressLine,
            second_line: formValues.additionalAddressLine,
            city: formValues.city,
            region: formValues.region || undefined,
            country: formValues.country,
            post_zip_code: formValues.postalCode || undefined,
            country_of_residence: formValues.countryIdentityName,
            tax_number: formValues.taxIdentityNumber || undefined,
            document_type: formValues.docType,
            document_number: formValues.docNumber,
            documents,
        };
    }
    return requestBody;
};
export { mapTraderProfileFieldsToReq, mapFormValuesToTraderVerificationRequestBody, };
