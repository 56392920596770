import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowBackIcon } from '@icons/wolfkit-light/arrow-left-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { Subtitle } from '@shared/ui/display/Typography';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
const Container = styled.div(props => ({
    display: 'inline-flex',
    width: 'fit-content',
    alignItems: 'center',
    cursor: 'pointer',
    color: props.theme.palette.text.primary,
    gap: props.theme.spacing_sizes.xs,
}));
const IconContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: props.theme.spacing_sizes.xs,
}));
const BackButton = ({ className = undefined, text = undefined, onClick = undefined, }) => {
    const { t } = useTranslation();
    const { rrdNavigate } = useAppNavigation();
    const displayText = text || t('navigation.go_back', { ns: 'common' });
    const onClickInternal = () => {
        if (onClick) {
            onClick();
        }
        else {
            rrdNavigate(-1);
        }
    };
    return (_jsxs(Container, { className: className, onClick: onClickInternal, children: [_jsx(IconContainer, { children: _jsx(Icon, { size: 16, IconComponent: ArrowBackIcon }) }), _jsx(Subtitle, { children: displayText })] }));
};
export default BackButton;
