import styled from '@emotion/styled';
const ContainerColumn = styled.div(() => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
}));
const ContainerRow = styled.div(() => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
}));
const SurfaceSection = styled.section((props) => ({
    borderRadius: props.theme.spacing_sizes.xs,
    border: `1px solid ${props.theme.customColors.surface.outline}`,
    backgroundColor: props.theme.customColors.surface.surface,
}));
const SurfaceColumn = SurfaceSection.withComponent(ContainerColumn);
const SurfaceRow = SurfaceSection.withComponent(ContainerRow);
const SurfaceShadowSection = styled.section(() => ({
    boxShadow: '0px 4px 8px -6px rgba(24, 39, 75, 0.04)',
}));
const SurfaceColumnWithShadow = SurfaceShadowSection.withComponent(SurfaceColumn);
const SurfaceRowWithShadow = SurfaceShadowSection.withComponent(SurfaceRow);
const CenterSpacedRow = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const HighlightedContainer = styled('div', { shouldForwardProp: propName => propName !== 'padding' })((props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: props.padding || props.theme.spacing_sizes.m,
    borderRadius: props.theme.shape.borderRadius,
    backgroundColor: '#F5FAFF', // TODO: move color
    boxSizing: 'border-box',
    minHeight: '56px',
    width: '100%',
}));
const alignToCenter = (component) => styled(component)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const NarrowSection = styled.section((props) => ({
    boxSizing: 'border-box',
    height: 'auto',
    maxWidth: '1368px',
    paddingLeft: props.theme.spacing_sizes.l,
    paddingRight: props.theme.spacing_sizes.l,
}));
export { ContainerColumn, ContainerRow, SurfaceSection, SurfaceColumn, SurfaceColumnWithShadow, SurfaceRow, SurfaceRowWithShadow, CenterSpacedRow, HighlightedContainer, alignToCenter, NarrowSection, };
