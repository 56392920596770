import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { BodyLargeSemiBold } from '@shared/ui/display/Typography';
const Container = styled(ContainerColumn)((props) => ({
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    gap: props.theme.spacing_sizes.l,
}));
const EmptyViewInsertable = ({ title, BodyImgComponent, minHeight = undefined, className = undefined, }) => (_jsxs(Container, { className: className, style: {
        minHeight,
    }, children: [BodyImgComponent, _jsx(BodyLargeSemiBold, { children: title })] }));
export default EmptyViewInsertable;
