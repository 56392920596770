import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import styled from '@emotion/styled';
import { Body } from '@shared/ui/display/Typography';
import { IsDefined } from '@utils/js-ts';
import ColumnSortedAscendingIcon from '../ColumnSortedAscendingIcon';
import ColumnSortedDescendingIcon from '../ColumnSortedDescendingIcon';
const HeaderContainer = styled.div(props => (Object.assign({ display: 'flex', alignItems: 'center', gap: 4, color: props.theme.palette.text.secondary }, (props.isSortable && {
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover,&:active': {
        color: props.theme.customColors.portfolio.hover,
    },
}))));
const TableHeaderCellRenderer = ({ className = undefined, header, children, }) => {
    const [isHovered, setIsHovered] = useState(false);
    const isSortable = () => header.column.getCanSort();
    const sortDirection = () => (header.column.getIsSorted() || header.column.getFirstSortDir());
    const showIcon = () => isSortable() && (!!header.column.getIsSorted() || isHovered);
    return (_jsxs(HeaderContainer, { className: className, isSortable: isSortable(), onMouseOver: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false), children: [_jsxs(Body, { children: [IsDefined(children) && (children), !IsDefined(children) &&
                        typeof header.column.columnDef.header === 'string' && (header.column.columnDef.header)] }), showIcon() &&
                sortDirection() === 'asc' && (_jsx(ColumnSortedAscendingIcon, {})), showIcon() &&
                sortDirection() === 'desc' && (_jsx(ColumnSortedDescendingIcon, {}))] }));
};
export default TableHeaderCellRenderer;
