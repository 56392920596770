import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import useAuth from '../../../../../lib/useAuth';
import FormWrapper from '../../../../components/FormWrapper';
import ResetPasswordForm from './ResetPasswordForm';
const ResetPassword = () => {
    const { t } = useTranslation();
    const { closeResetForm, returnToSignIn } = useAuth();
    return (_jsx(FormWrapper, { renderInnerForm: _jsx(ResetPasswordForm, {}), titleText: t('auth.reset_password.steps.reset_password.title', { ns: 'common' }), backLinkProps: {
            text: t('auth.reset_password.steps.backlink_text', { ns: 'common' }),
            onClick: returnToSignIn,
        }, handleReset: closeResetForm, showNavigation: true }));
};
export default ResetPassword;
