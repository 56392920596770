import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import InputPassword from '@shared/ui/inputs/InputPassword';
import SubmitButtonComponent from '@shared/ui/buttons/Button';
import changePasswordValidationSchema from '../../model/validation';
const InputContainer = styled.div `
    margin-bottom: 24px;
    width: inherit;
`;
const SubmitButton = styled(SubmitButtonComponent) `
    width: 100%;
`;
const Form = styled.form `
    width: 100%;
    max-width: 450px;
`;
const INIT_VALUES = {
    password: '',
    repeatPassword: '',
};
const ChangePasswordForm = ({ onSubmit: submitFn, isLoading }) => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        defaultValues: INIT_VALUES,
        resolver: yupResolver(changePasswordValidationSchema(t)),
    });
    const onSubmit = (values) => {
        submitFn(values.password);
    };
    return (_jsxs(Form, { onSubmit: handleSubmit(onSubmit), children: [_jsx(InputContainer, { children: _jsx(Controller, { name: 'password', control: control, render: ({ field, fieldState }) => {
                        var _a, _b;
                        return (_jsx(InputPassword, { "data-testid": 'password_change-password', InputLabelProps: { shrink: true }, label: t('change_password.password_label', { ns: 'common' }), placeholder: t('change_password.password_placeholder', { ns: 'common' }), value: field.value, onChange: field.onChange, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: isLoading }));
                    } }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'repeatPassword', control: control, render: ({ field, fieldState }) => {
                        var _a, _b;
                        return (_jsx(InputPassword, { "data-testid": 'repeat-password_change-password', InputLabelProps: { shrink: true }, label: t('change_password.repeat_password_label', { ns: 'common' }), placeholder: t('change_password.repeat_password_placeholder', { ns: 'common' }), value: field.value, onChange: field.onChange, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: isLoading }));
                    } }) }), _jsx(SubmitButton, { "data-testid": 'submit_change-password', type: 'submit', isLoading: isLoading, disabled: isLoading, children: t('change_password.submit_btn_text', { ns: 'common' }) })] }));
};
export default ChangePasswordForm;
