import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter } from 'react-router-dom';
import deepmerge from 'deepmerge';
import { Suspense } from 'react';
import { ErrorComponent, RouterErrorElement } from '@shared/ui/errors/Error';
import OverviewWalletDetails from '@src/pages/overview-wallet-details';
import ProfilePortfolios from '@pages/profile-portfolios';
import ProfileFollowers from '@pages/profile-followers';
import ProfileFollowing from '@pages/profile-following';
import ProfileCopiers from '@pages/profile-copiers';
import ProfileActivity from '@pages/profile-activity';
import GeneralPortfolioInformation from '@pages/general-portfolio-information';
import Profile from '@pages/profile';
import MyProfile from '@pages/my-profile';
import { Auth } from '@pages/auth';
import Marketplace from '@pages/marketplace';
import { Settings } from '@pages/settings';
import { SettingsSubscription } from '@pages/settings-subscription';
import PersonalInformation from '@pages/settings-personal-information';
import HomeController, { Home } from '@pages/Home';
import ConfirmEmail from '@pages/ConfirmEmail';
import ChangePassword from '@pages/change-password';
import MarketplaceList from '@pages/marketplace-list';
import PlatformBalance from '@pages/platform-balance';
import Overview from '@pages/Overview';
import Support from '@pages/Support';
import DevComponents from '@pages/DevComponents';
import { IsDefined } from '@utils/js-ts';
import PortfolioCopiers from '@pages/profile-portfolio-copiers';
import ProfileClosedPortfolios from '@pages/profile-closed-portfolios';
import PortfolioCurrentPositions from '@pages/portfolio-current-positions';
import PortfolioPositionsHistory from '@pages/portfolio-positions-history';
import routes from './routes';
import { RouteNames } from './types';
import LayoutGeneral from '../layouts/LayoutGeneral';
import LayoutAuthorized from '../layouts/LayoutAuthorized';
const getErrorElementByRoute = (routeName) => {
    let element;
    switch (routeName) {
        default:
            element = _jsx(RouterErrorElement, {});
    }
    return element;
};
const getElementByRoute = (routeName) => {
    let element;
    switch (routeName) {
        case (RouteNames.PROFILE):
            element = _jsx(Profile, {});
            break;
        case (RouteNames.MY_PROFILE):
            element = _jsx(MyProfile, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS):
        case (RouteNames.PROFILE_PORTFOLIOS):
            element = _jsx(ProfilePortfolios, {});
            break;
        case (RouteNames.MY_PROFILE_CLOSED_PORTFOLIOS):
        case (RouteNames.PROFILE_CLOSED_PORTFOLIOS):
            element = _jsx(ProfileClosedPortfolios, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION):
        case (RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION):
        case (RouteNames.PROFILE_CLOSED_PORTFOLIOS_PORTFOLIO_INFORMATION):
        case (RouteNames.MY_PROFILE_CLOSED_PORTFOLIOS_PORTFOLIO_INFORMATION):
            element = _jsx(GeneralPortfolioInformation, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS):
        case (RouteNames.MY_PROFILE_CLOSED_PORTFOLIOS_POSITIONS):
        case (RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS):
        case (RouteNames.PROFILE_CLOSED_PORTFOLIOS_POSITIONS):
            element = _jsx(PortfolioCurrentPositions, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY):
        case (RouteNames.MY_PROFILE_CLOSED_PORTFOLIOS_HISTORY):
        case (RouteNames.PROFILE_CLOSED_PORTFOLIOS_HISTORY):
        case (RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY):
            element = _jsx(PortfolioPositionsHistory, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS):
        case (RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS):
            element = _jsx(PortfolioCopiers, {});
            break;
        case (RouteNames.MY_PROFILE_FOLLOWERS):
        case (RouteNames.PROFILE_FOLLOWERS):
            element = _jsx(ProfileFollowers, {});
            break;
        case (RouteNames.MY_PROFILE_FOLLOWINGS):
        case (RouteNames.PROFILE_FOLLOWINGS):
            element = _jsx(ProfileFollowing, {});
            break;
        case (RouteNames.MY_PROFILE_ACTIVITY):
        case (RouteNames.PROFILE_ACTIVITY):
            element = _jsx(ProfileActivity, {});
            break;
        case (RouteNames.MY_PROFILE_COPIERS):
        case (RouteNames.PROFILE_COPIERS):
            element = _jsx(ProfileCopiers, {});
            break;
        case (RouteNames.MARKETPLACE):
            element = _jsx(Marketplace, {});
            break;
        case (RouteNames.MARKETPLACE_LIST):
            element = _jsx(MarketplaceList, {});
            break;
        case (RouteNames.SETTINGS):
            element = _jsx(Settings, {});
            break;
        case (RouteNames.SETTINGS_PERSONAL_INFORMATION):
            element = _jsx(PersonalInformation, {});
            break;
        case (RouteNames.SETTINGS_SUBSCRIPTION):
            element = _jsx(SettingsSubscription, {});
            break;
        case (RouteNames.AUTH):
            element = _jsx(Auth, {});
            break;
        case (RouteNames.CONFIRM_EMAIL):
            element = _jsx(ConfirmEmail, {});
            break;
        case (RouteNames.CHANGE_PASSWORD):
            element = _jsx(ChangePassword, {});
            break;
        case (RouteNames.NON_ROUTABLE_LAYOUT_GENERAL):
            element = (_jsx(Suspense, { fallback: null, children: _jsx(LayoutGeneral, {}) }));
            break;
        case (RouteNames.NON_ROUTABLE_LAYOUT_AUTHORIZED):
            element = (_jsx(Suspense, { fallback: null, children: _jsx(LayoutAuthorized, {}) }));
            break;
        case (RouteNames.NON_ROUTABLE_LAYOUT_HOME):
            element = _jsx(Home, {});
            break;
        case (RouteNames.HOME):
            element = _jsx(HomeController, {});
            break;
        case (RouteNames.HOME_OVERVIEW):
            element = _jsx(Overview, {});
            break;
        case (RouteNames.OVERVIEW_WALLET_DETAILS):
            element = _jsx(OverviewWalletDetails, {});
            break;
        case (RouteNames.HOME_BALANCE):
            element = _jsx(PlatformBalance, {});
            break;
        case (RouteNames.NON_ROUTABLE_NOT_FOUND):
            element = (_jsx(ErrorComponent, { variant: 'not_found' }));
            break;
        case (RouteNames.SUPPORT):
            element = _jsx(Support, {});
            break;
        case (RouteNames.DEV_COMPONENTS):
            element = _jsx(DevComponents, {});
            break;
        default:
    }
    return element;
};
const injectElementsWithHandle = (routesConfig) => {
    const resultRoutes = deepmerge({}, routesConfig);
    for (let i = 0; i < resultRoutes.length; i += 1) {
        const route = resultRoutes[i];
        if (IsDefined(route.name)) {
            const element = getElementByRoute(route.name);
            if (element) {
                route.element = element;
            }
            const errorElement = getErrorElementByRoute(route.name);
            if (errorElement) {
                route.errorElement = errorElement;
            }
        }
        if (route.helpers) {
            route.handle = route.helpers;
        }
        if (route.children) {
            route.children = injectElementsWithHandle(route.children);
        }
    }
    return resultRoutes;
};
const router = createBrowserRouter(injectElementsWithHandle(routes));
export default router;
