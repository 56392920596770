import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import isPropValid from '@emotion/is-prop-valid';
import { BodyLargeSemiBold, BodyMediumSemiBold, } from '@shared/ui/display/Typography';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { ReactComponent as VerificationIconComponent } from '@icons/custom/trader-verified.svg';
import Icon from '@shared/ui/icons/Icon';
const StepContainerComponent = styled('div', { shouldForwardProp: prop => isPropValid(prop) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: props.theme.baseSize * 70,
    height: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    padding: props.containerPadding || `${props.theme.spacing_sizes.xs * 2}px 0 0 0`,
    gap: props.containerGap,
}));
const StepForm = styled('form', { shouldForwardProp: prop => isPropValid(prop) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: props.theme.baseSize * 70,
    height: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    padding: props.containerPadding || `${props.theme.spacing_sizes.xs * 2}px 0 0 0`,
    gap: props.containerGap,
}));
const InnerElements = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    alignItems: props.contentAlign,
    justifyContent: props.contentJustify,
    flex: props.contentFlex,
}));
const ButtonsGroup = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: props.buttonsGap,
    width: '100%',
    justifyContent: 'end',
    flex: props.buttonsFlex,
}));
const TitleContainer = styled.div(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
}));
const Title = styled(BodyLargeSemiBold)(() => ({
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '-0.456px',
}));
const StepNumber = styled.span(props => ({
    color: props.theme.customColors.input.borderHover,
    lineHeight: '24px',
}));
const StepTextComponent = styled.span(() => ({
    lineHeight: '24px',
}));
const StepTextContainer = styled(BodyMediumSemiBold, { shouldForwardProp: propName => propName !== 'margin' })(props => ({
    alignSelf: 'start',
    margin: props.margin,
    letterSpacing: '-0.176px',
}));
const StepText = ({ stepNumber, text, margin = '0 0 24px 0', }) => (_jsxs(StepTextContainer, { margin: margin, children: [_jsx(StepNumber, { children: stepNumber }), ' ', _jsx(StepTextComponent, { children: text })] }));
const StepContainer = ({ content, title = undefined, buttons = undefined, buttonsFlex = undefined, contentAlign = 'center', contentJustify = 'center', contentFlex = undefined, buttonsGap = 12, containerGap = undefined, containerPadding = undefined, }) => (_jsxs(StepContainerComponent, { containerGap: containerGap, containerPadding: containerPadding, children: [_jsx(TitleContainer, { children: title || null }), _jsx(InnerElements, { contentAlign: contentAlign, contentJustify: contentJustify, contentFlex: contentFlex, children: content }), _jsx(ButtonsGroup, { buttonsGap: buttonsGap, buttonsFlex: buttonsFlex, children: buttons || null })] }));
const StepFormContainer = ({ content, title = undefined, buttons = undefined, buttonsFlex = undefined, onSubmit = undefined, contentAlign = 'center', contentJustify = 'center', contentFlex = undefined, buttonsGap = 12, containerGap = undefined, containerPadding = undefined, }) => (_jsxs(StepForm, { onSubmit: onSubmit, containerGap: containerGap, containerPadding: containerPadding, children: [_jsx(TitleContainer, { children: title || null }), _jsx(InnerElements, { contentAlign: contentAlign, contentJustify: contentJustify, contentFlex: contentFlex, children: content }), _jsx(ButtonsGroup, { buttonsGap: buttonsGap, buttonsFlex: buttonsFlex, children: buttons || null })] }));
const DividerContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    alignItems: 'center',
    margin: `${props.theme.spacing_sizes.xm}px 0`,
}));
const DividerText = styled(BodyMediumSemiBold)(() => ({
    lineHeight: '24px',
    color: '#ABB4BB',
}));
const DividerBorder = styled.span(() => ({
    display: 'block',
    width: '100%',
    backgroundColor: '#ABB4BB',
    height: 1,
}));
const Divider = () => {
    const { t } = useTranslation();
    return (_jsxs(DividerContainer, { children: [_jsx(DividerBorder, {}), _jsx(DividerText, { children: t('overview.balance_management.divider_text') }), _jsx(DividerBorder, {})] }));
};
const VerificationBannerContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const VerificationTitleContainer = styled.div(props => ({
    backgroundColor: props.theme.palette.primary.main,
    padding: `${props.theme.spacing_sizes.xs * 2}px`,
    textAlign: 'center',
    borderRadius: '12px 12px 0 0',
}));
const DetailsContainer = styled(ContainerColumn)(props => ({
    position: 'relative',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    padding: `${props.theme.spacing_sizes.xs * 2.5}px 
        ${props.theme.spacing_sizes.s * 5}px ${props.theme.spacing_sizes.s * 5}px`,
    background: '#F8FAFF',
    borderRadius: '0 0 12px 12px',
    boxSizing: 'border-box',
    alignItems: 'center',
}));
const VerificationIconContainer = styled.div(() => ({
    position: 'absolute',
    opacity: 0.2,
}));
const TitleTextContainer = styled.div(() => ({
    position: 'relative',
}));
const VerificationBanner = ({ title, content, }) => (_jsxs(VerificationBannerContainer, { children: [_jsxs(VerificationTitleContainer, { children: [_jsx(VerificationIconContainer, { children: _jsx(Icon, { IconComponent: VerificationIconComponent, color: '#97AFEF', size: 90 }) }), _jsx(TitleTextContainer, { children: title })] }), _jsx(DetailsContainer, { children: content })] }));
export { StepContainer, StepFormContainer, Title, StepText, Divider, VerificationBanner, };
