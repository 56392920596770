import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import ApiKeyRow from './ApiKeyRow';
import ApiKeysEmpty from './ApiKeysEmpty';
const LoadingContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    minHeight: '320px',
}));
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const ApiKeysManagement = () => {
    const { data, isLoading, } = useGetExchangeAccountsQuery();
    if (isLoading) {
        return (_jsx(LoadingContainer, { children: _jsx(LoadingIndicator, { isLoading: true, takeFullContainerSpace: true }) }));
    }
    if (data && data.length > 0) {
        return (_jsx(Container, { children: data.map((exchange) => (_jsx(ApiKeyRow, { exchange: exchange }, exchange.id))) }));
    }
    return (_jsx(ApiKeysEmpty, {}));
};
export default ApiKeysManagement;
