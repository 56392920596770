import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useCallback, useState } from 'react';
import { useWizard } from '@shared/ui/misc/Wizard';
import ButtonCustom from '@shared/ui/buttons/ButtonCustom';
import { ContainerColumn } from '@shared/ui/display/Containers';
import useAuth from '../../../lib/useAuth';
import { maskEmail } from '../../../lib/utils';
import ResendEmailCountdownTimer from '../../../../../shared/ui/misc/ResendEmailCountdownTimer';
import FormWrapper from '../FormWrapper';
import { Text } from '../shared';
const Container = styled(ContainerColumn)((props) => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
    maxWidth: 420,
    textAlign: 'center',
    gap: props.theme.spacing_sizes.s * 5,
}));
const SubmitButton = styled(ButtonCustom)(props => ({
    lineHeight: '20px',
    padding: `${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.m}px`,
}));
const ButtonContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.m,
    width: 'max-content',
    height: 'auto',
}));
const EmailConfirmationStep = () => {
    const { t } = useTranslation();
    const { goBack, goTo } = useWizard();
    const { getEmailVerificationLinkReq, isGettingEmailVerificationLink, emailForConfirmation, } = useAuth();
    const [displayTimer, setDisplayTimer] = useState(false);
    const hideTimer = () => {
        setDisplayTimer(false);
    };
    const sendEmailVerificationLink = useCallback(() => {
        if (emailForConfirmation) {
            getEmailVerificationLinkReq({ email: emailForConfirmation });
            setDisplayTimer(true);
        }
    }, [
        emailForConfirmation,
        getEmailVerificationLinkReq,
        setDisplayTimer,
    ]);
    return (_jsx(FormWrapper, { titleText: t('auth.sign_up.steps.verification.email.title', { ns: 'common' }), renderInnerForm: (_jsxs(Container, { children: [_jsx(Text, { children: t('auth.sign_in.steps.verification.email.text', { email: maskEmail(emailForConfirmation || ''), ns: 'common' }) }), _jsxs(ButtonContainer, { children: [_jsx(SubmitButton, { variant: 'contained', size: 'medium', onClick: sendEmailVerificationLink, isLoading: isGettingEmailVerificationLink, disabled: isGettingEmailVerificationLink || displayTimer, disableRipple: true, disableElevation: true, children: t('auth.reset_password.steps.confirmation.resend_link_text', { ns: 'common' }) }), displayTimer && (_jsx(ResendEmailCountdownTimer, { onTimeOver: hideTimer }))] })] })), backLinkProps: {
            text: t('auth.sign_up.steps.backlink_text', { ns: 'common' }),
            onClick: goBack,
        }, handleReset: () => goTo(0), showNavigation: true }));
};
export default EmailConfirmationStep;
