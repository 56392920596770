import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { numberToFixed2 } from '@shared/lib/numbers';
import { Body } from '@shared/ui/display/Typography';
import QuestionMarkTooltip from '@shared/ui/tooltips/QuestionMarkTooltip';
const Container = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const OrderSize = ({ order, totalBalance, }) => {
    const { t } = useTranslation();
    const text = useMemo(() => `${numberToFixed2(order)} USDT`, [order]);
    return (_jsxs(Container, { children: [_jsx(Body, { children: text }), _jsx(QuestionMarkTooltip
            // title='4.56% of balance'
            , { 
                // title='4.56% of balance'
                title: t('table.order_size', { percent: '4.56' }), placement: 'top-start', arrow: true })] }));
};
const RenderOrderSize = ({ cellParams }) => (_jsx(OrderSize, { order: cellParams.value, totalBalance: cellParams.value }));
export default RenderOrderSize;
