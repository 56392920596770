import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { ContainerRow } from '@shared/ui/display/Containers';
import { BodyMediumSemiBold, BodySemiBold } from '@shared/ui/display/Typography';
import { formatUSDT } from '@shared/lib/numbers';
import { TooltipElementWidth } from './constants';
const TooltipElementContainer = styled(ContainerRow, { shouldForwardProp: (propName) => propName !== 'borderColor' })((props) => ({
    maxWidth: TooltipElementWidth,
    boxSizing: 'border-box',
    height: 28,
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xm,
    borderRadius: 6,
    backgroundColor: '#11181CBF',
    borderLeft: `4px solid ${props.borderColor}`,
    padding: `${props.theme.spacing_sizes.xs * 0.5}px ${props.theme.spacing_sizes.xs}px`,
}));
const TooltipNameContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 0.25,
}));
const TooltipDate = styled(BodyMediumSemiBold)(() => ({
    margin: 0,
}));
const Amount = styled(BodySemiBold)(() => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}));
const TooltipInfo = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.s,
}));
const AvatarWrapper = styled.div(() => ({
    minWidth: 20,
    maxWidth: 20,
    pointerEvents: 'none',
    borderRadius: '50%',
}));
const TooltipElement = ({ date, amount, profileImage = undefined, profileName = undefined, borderColor = undefined, }) => {
    const theme = useTheme();
    return (_jsx(TooltipElementContainer, { borderColor: borderColor, children: _jsxs(TooltipInfo, { children: [_jsx(AvatarWrapper, { children: _jsx(AvatarImage, { imageUrl: profileImage, publicName: profileName, size: 'avatarSwitcher' }) }), _jsxs(TooltipNameContainer, { children: [_jsx(TooltipDate, { children: date }), _jsx(Amount, { color: theme.palette.text.disabled, lineHeight: '20px', children: formatUSDT(amount, { maximumFractionDigits: 0 }) })] })] }) }));
};
export default TooltipElement;
