import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import isPropValid from '@emotion/is-prop-valid';
import { ContainerRow } from '@shared/ui/display/Containers';
import { PortfolioTypes } from '../types';
const Container = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.xs,
}));
const TypeButton = styled('button', { shouldForwardProp: prop => isPropValid(prop) })((props) => (Object.assign({ display: 'inline-flex', cursor: 'pointer', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center', outline: 0, margin: 0, userSelect: 'none', padding: '9px 12px', borderRadius: props.theme.shape.borderRadius, fontStyle: 'normal', fontSize: '14px', fontWeight: 500, lineHeight: '20px', color: props.theme.palette.text.secondary, backgroundColor: props.theme.customColors.surface.surface, border: '1px solid transparent', '&:hover': {
        color: props.theme.palette.primary.main,
        borderColor: props.theme.palette.primary.main,
    } }, props.isActive && ({
    color: props.theme.palette.primary.main,
    borderColor: props.theme.palette.primary.main,
    boxShadow: '0px 0px 0px 2px #E1E8FB',
}))));
const PortfolioTypeSwitch = ({ currentType, onTypeChange, }) => {
    const { t } = useTranslation();
    const handleTypeChange = (type) => {
        onTypeChange(type);
    };
    const getPortfolioTypeDisplayName = useCallback((type) => {
        let displayName;
        switch (type) {
            case PortfolioTypes.All:
                displayName = t('marketplace.main_view.filters.all');
                break;
            case PortfolioTypes.Spot:
                displayName = t('marketplace.main_view.filters.spot');
                break;
            case PortfolioTypes.Margin:
                displayName = t('marketplace.main_view.filters.margin');
                break;
            default:
        }
        return displayName;
    }, [t]);
    return (_jsx(Container, { children: Object.values(PortfolioTypes).map((key) => (_jsx(TypeButton, { isActive: key === currentType, onClick: () => handleTypeChange(key), children: getPortfolioTypeDisplayName(key) }, key))) }));
};
export default PortfolioTypeSwitch;
