const definePortfoliosListAmount = ({ isError, isFetchingPortfolios, isFetchingPortfolioSubscriptions, portfoliosList, fetchingPortfoliosType = undefined, subscribedPortfolios, }) => {
    if (fetchingPortfoliosType === 'portfolios' &&
        !isError && !isFetchingPortfolios && portfoliosList) {
        return String(portfoliosList.length);
    }
    if (fetchingPortfoliosType === 'subscribed-portfolios' &&
        !isError && !isFetchingPortfolioSubscriptions && subscribedPortfolios) {
        return String(subscribedPortfolios.length);
    }
    return '';
};
export default definePortfoliosListAmount;
