import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as TraderIcon } from '@icons/wolfkit-light/crown-light.svg';
import { Body } from '@shared/ui/display/Typography';
import Icon from '@shared/ui/icons/Icon';
import Badge from '@shared/ui/badges/Badge';
const BadgeStyled = styled(Badge)(() => ({
    userSelect: 'none',
}));
const BodyStyled = styled(Body, { shouldForwardProp: propName => propName !== 'textSize' && propName !== 'textHeight' })(props => ({
    fontSize: props.textSize,
    lineHeight: props.textHeight,
}));
const TraderBadge = ({ color = 'warning', size = 'medium', textSize = undefined, textHeight = undefined, }) => {
    const { t } = useTranslation();
    return (_jsx(BadgeStyled, { variant: 'tinted', color: color, startAdornment: (_jsx(Icon, { size: 14, IconComponent: TraderIcon })), size: size, children: _jsx(Body, { style: {
                fontSize: textSize,
                lineHeight: textHeight,
            }, children: t('profile.type.trader') }) }));
};
export default TraderBadge;
