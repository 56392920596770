var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { WalletsProfitGraph, checkIfWalletSupported, getWalletsFromExchanges, mapSupportedWalletsDict, } from '@entities/wallet';
import { ContainerRow } from '@shared/ui/display/Containers';
import BackButton from '@shared/ui/navigation/BackButton';
import PageFrameContainer from '@shared/ui/layout/PageFrameContainer';
import { ExchangeWallet } from '@features/wallet/exchange-wallet';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import { useGetExchangeAccountsQuery, useGetSupportedWalletTypesQuery } from '@shared/api/exchange';
import WALLETS_PROFIT_DATA from './mocks';
import prepareWalletsData from './utils';
import ConnectedPortfoliosList from './ConnectedPortfoliosList';
const HeaderContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.l,
    justifyContent: 'space-between',
    maxHeight: '340px',
}));
const OverviewWalletDetails = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { navigate, routes } = useAppNavigation();
    const { walletId } = useParams();
    const { data: { wallets }, isFetching: areWalletsFetching, } = useGetExchangeAccountsQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: {
                    exchangesList: (data || []),
                    wallets: getWalletsFromExchanges(data || []),
                } }));
        },
    });
    const { data: { data: supportedWalletsDict } } = useGetSupportedWalletTypesQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: mapSupportedWalletsDict(data) }));
        },
    });
    const { wallet, isSupported } = useMemo(() => {
        const walletVal = wallets.find((w) => w.id === walletId);
        const isWalletSupported = walletVal ?
            checkIfWalletSupported(walletVal, supportedWalletsDict) : false;
        return { wallet: walletVal, isSupported: isWalletSupported };
    }, [
        wallets,
        supportedWalletsDict,
        walletId,
    ]);
    const walletsData = useMemo(() => prepareWalletsData(WALLETS_PROFIT_DATA), []);
    const navigateToOverview = () => {
        navigate(routes.HOME_OVERVIEW);
    };
    return (_jsx(PageFrameContainer, { header: !!wallet && (_jsxs(_Fragment, { children: [_jsx(BackButton, { text: t('pages.overview.overview', { ns: 'common' }), onClick: navigateToOverview }), _jsxs(HeaderContainer, { children: [_jsx(ExchangeWallet, { variant: 'embedded', wallet: wallet, portfolios: [], isSupported: isSupported }), _jsx(WalletsProfitGraph, { walletsData: walletsData })] })] })), gap: theme.spacing_sizes.l, headerStyles: {
            padding: '42px 0 30px 0',
        }, bottom: (_jsx(ConnectedPortfoliosList, {})), isLoading: areWalletsFetching, error: undefined }));
};
export default OverviewWalletDetails;
