import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatUSD } from '@shared/lib/numbers';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { Body, BodyLargeSemiBold } from '@shared/ui/display/Typography';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
import { getExchangeTypeDisplayText, getWalletTypeDisplayText } from './locale';
const Container = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const NameContainer = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const BodyWithEllipsis = withTextEllipsis(Body);
const ActiveBalancePieDetails = ({ balancePie, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const providerDisplayText = getExchangeTypeDisplayText(balancePie.exchange.type, t);
    const typeDisplayText = getWalletTypeDisplayText(balancePie.type, t);
    return (_jsxs(Container, { children: [_jsxs(NameContainer, { children: [_jsx(ExchangeIcon, { size: 20, type: balancePie.exchange.type }), _jsx(BodyWithEllipsis, { tooltipText: balancePie.exchange.properties.connectionName, children: balancePie.exchange.properties.connectionName })] }), _jsx(BodyLargeSemiBold, { children: formatUSD(parseFloat(balancePie.totalAssetsUsd)) }), _jsx(BodyWithEllipsis, { tooltipText: `${providerDisplayText} ${typeDisplayText}`, color: theme.palette.text.secondary, children: `${providerDisplayText} ${typeDisplayText}` })] }));
};
export default ActiveBalancePieDetails;
