import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockIcon } from '@icons/wolfkit-light/clock-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { useGetProfilePortfolios, useProfileInfo } from '@entities/profile';
import ProfileTabLabel from './ProfileTabLabel';
import definePortfoliosListAmount from './utils';
const PortfolioIconSized = () => (_jsx(Icon, { size: 16, IconComponent: ClockIcon }));
const ClosedPortfoliosTabLabel = ({ profileNickname = undefined, isSelected = false, isMyProfile, }) => {
    const { t } = useTranslation();
    const { profile } = useProfileInfo(profileNickname);
    const { isFetchingPortfolios, isFetchingPortfolioSubscriptions, isError, portfoliosList, subscribedPortfoliosList, fetchingPortfoliosType } = useGetProfilePortfolios({
        profileId: profile === null || profile === void 0 ? void 0 : profile.id,
        profileType: profile === null || profile === void 0 ? void 0 : profile.type,
        isVerifiedTrader: profile === null || profile === void 0 ? void 0 : profile.isVerifiedTrader,
        isMyProfile,
        isClosedPortfolios: true,
    });
    const amount = definePortfoliosListAmount({
        fetchingPortfoliosType,
        isError,
        isFetchingPortfolios,
        isFetchingPortfolioSubscriptions,
        portfoliosList,
        subscribedPortfolios: subscribedPortfoliosList,
    });
    return (_jsx(ProfileTabLabel, { IconComponent: PortfolioIconSized, text: t('profile.tabs.closed_portfolios', { ns: 'translation' }), amount: amount, selected: isSelected }));
};
export default ClosedPortfoliosTabLabel;
