import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { BodyMedium } from '@shared/ui/display/Typography';
import { ContainerColumn, ContainerRow as ContainerRowComponent } from '@shared/ui/display/Containers';
import Textarea from '@shared/ui/inputs/Textarea';
import ButtonCustom from '@shared/ui/buttons/ButtonCustom';
import AttachButton from '@shared/ui/buttons/AttachButton';
import SEND_REQUEST_INIT_VALUES from './constants';
import UnauthorizedStateInputs from './UnauthorizedStateInputs';
import AuthorizedStateInputs from './AuthorizedStateInputs';
import validationSchema from './validation';
const Form = styled.form(props => ({
    paddingTop: props.theme.spacing_sizes.s,
}));
const ContainerRow = styled(ContainerRowComponent)(() => ({
    height: 'auto',
}));
const ButtonsContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.m,
}));
const FormInnerContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.l,
    marginBottom: props.theme.spacing_sizes.xl,
}));
const Text = styled(BodyMedium)(props => ({
    lineHeight: '28px',
    letterSpacing: '-0.224px',
    marginBottom: props.theme.spacing_sizes.l,
}));
const SubmitButton = styled(ButtonCustom)(props => ({
    padding: `${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.l}px`,
    lineHeight: '20px',
    letterSpacing: '-0.084px',
    minWidth: 83,
}));
const SendRequestForm = ({ onSubmit, publicName = undefined, nickname = undefined, imageUrl = undefined, isAuthorized, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const fileErrors = useMemo(() => ({
        EXCEEDED_FILE_SIZE: t('support.send_request.messages.exceeded_file_size'),
        INCORRECT_MIME_TYPE: t('support.send_request.messages.invalid_file_type'),
    }), [t]);
    const { control, handleSubmit, setValue, setError, clearErrors, formState: { errors }, } = useForm({
        defaultValues: Object.assign(Object.assign({}, SEND_REQUEST_INIT_VALUES), { isAuthorized }),
        resolver: yupResolver(validationSchema(t)),
    });
    const handleFileUpload = (file) => {
        setValue('attach', file);
        clearErrors('attach');
    };
    const handleFileError = (errorMessage) => {
        setError('attach', { message: errorMessage });
    };
    const hasNameError = isAuthorized === false &&
        errors && errors.name && errors.name.message ?
        Boolean(((_a = errors.name.message) === null || _a === void 0 ? void 0 : _a.length) > 0) : false;
    const hasErrors = errors && ((_b = Object.keys(errors)) === null || _b === void 0 ? void 0 : _b.length) > 0;
    return (_jsxs(Form, { onSubmit: handleSubmit(onSubmit), children: [_jsx(Text, { children: t('support.send_request.text') }), _jsxs(FormInnerContainer, { children: [isAuthorized ? (_jsx(AuthorizedStateInputs, { control: control, publicName: publicName, nickname: nickname, imageUrl: imageUrl })) : (_jsx(UnauthorizedStateInputs, { control: control, hasNameError: hasNameError })), _jsx(ContainerColumn, { children: _jsx(Controller, { name: 'message', control: control, render: ({ field, fieldState }) => {
                                var _a;
                                return (_jsx(Textarea, Object.assign({}, field, { label: t('support.send_request.message.title'), placeholder: t('support.send_request.message.placeholder'), InputLabelProps: { shrink: true }, size: 'small', maxRows: 6, minRows: 6, maxCount: 1000, notificationStatus: ((_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message) ? 'error' : undefined, required: true })));
                            } }) })] }), _jsxs(ButtonsContainer, { children: [_jsx(SubmitButton, { size: 'medium', variant: 'contained', type: 'submit', disableElevation: true, disableRipple: true, disabled: hasErrors, children: t('support.send_request.submit_btn') }), _jsx(AttachButton, { text: t('support.send_request.attach.title'), hint: t('support.send_request.attach.hint'), onUpload: handleFileUpload, onError: handleFileError, errors: fileErrors, maxSize: 15000000, mimeType: ['image/png', 'image/jpeg'] })] })] }));
};
export default SendRequestForm;
