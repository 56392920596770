import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import { BodySemiBold } from '@shared/ui/display/Typography';
const getInheritFontStyles = (inheritFontStyles) => (inheritFontStyles ? ({
    '*': {
        fontSize: 'inherit',
        lineHeight: 'inherit',
    },
}) : ({}));
const Container = styled.div(props => (Object.assign({ display: 'flex', alignItems: 'center', color: props.theme.palette.text.secondary, gap: props.containerGap || props.theme.spacing_sizes.s, flex: props.containerFlex }, getInheritFontStyles(props.inheritFontStyles))));
const Text = withTextEllipsis(BodySemiBold);
const IconText = ({ className = undefined, inheritFontStyles = false, children = undefined, text = undefined, IconComponent = undefined, onClick = undefined, containerGap = undefined, containerFlex = undefined, textColor = undefined, textLineHeight = undefined, textFontSize = undefined, textFontWeight = undefined, }) => (_jsxs(Container, { className: className, inheritFontStyles: inheritFontStyles, onClick: onClick, containerGap: containerGap, containerFlex: containerFlex, children: [IconComponent || null, children, !children &&
            !!text && (_jsx(Text, { style: {
                fontSize: textFontSize,
                fontWeight: textFontWeight,
            }, lineHeight: textLineHeight, color: textColor, tooltipText: typeof text === 'string' ? text : '', children: text }))] }));
export default IconText;
