var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';
import restApiAuthorized from '@app/store/api/restApiAuthorized';
import { isEqualShallow } from '@shared/lib/js-ts';
import { getPortfolioPnlData, getPortfolios, getPortfolioTransactions } from './mocks';
const portfoliosApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        createPortfolio: builder.mutation({
            query: ({ portfolioName, profileId, subscriptionPrice, walletId, }) => ({
                url: '/api/v1/portfolio',
                body: {
                    portfolioName,
                    profileId,
                    subscriptionPrice,
                    walletId,
                },
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['CurrentUserPortfolios'],
        }),
        updatePortfolio: builder.mutation({
            query: (_a) => {
                var { portfolioId } = _a, optionalParams = __rest(_a, ["portfolioId"]);
                return ({
                    url: `/api/v1/portfolio/${portfolioId}`,
                    body: Object.assign({}, optionalParams),
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            },
            invalidatesTags: ['CurrentUserPortfolios'],
        }),
        closePortfolio: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/portfolio/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CurrentUserPortfolios'],
        }),
        getPortfolios: builder.query({
            query: ({ page, page_size, status }) => ({
                url: '/api/v1/portfolio',
                method: 'GET',
                params: {
                    page,
                    page_size,
                    status,
                },
            }),
            providesTags: ['MarketplacePortfolios'],
            // merge current cache with new results
            merge: (currentCache, response, { arg }) => {
                if (arg.page > 1) {
                    currentCache.items.push(...response.items);
                    return currentCache;
                }
                return response;
            },
            // remove queryArgs from cache entry name to store all of the results in one cache entry
            serializeQueryArgs: ({ endpointName, endpointDefinition, }) => defaultSerializeQueryArgs({
                endpointName,
                queryArgs: {},
                endpointDefinition,
            }),
            // refetch when args change
            forceRefetch({ currentArg, previousArg }) {
                return !isEqualShallow(currentArg, previousArg);
            },
        }),
        getTopPortfolios: builder.query({
            query: () => ({
                url: '/api/v1/auth/me',
                method: 'GET',
            }),
            providesTags: ['PopularPortfolios'],
            transformResponse: () => getPortfolios(3),
        }),
        getPortfolio: builder.query({
            query: ({ portfolioId }) => ({
                url: `/api/v1/portfolio/${portfolioId}`,
                method: 'GET',
            }),
            providesTags: (result, error, { portfolioId }) => [{ type: 'Portfolios', id: portfolioId }],
        }),
        getProfilePortfolios: builder.query({
            query: ({ profileId, assessmentPassed }) => ({
                url: `/api/v1/profile/${profileId}/portfolio`,
                method: 'GET',
                params: typeof assessmentPassed === 'boolean' ?
                    { assessmentPassed } : undefined
            }),
            providesTags: (result) => [
                ...(result || []).map(p => ({ type: 'Portfolios', id: p.id })),
                'Portfolios',
            ],
        }),
        getCurrentProfilePortfolios: builder
            .query({
            query: ({ profileId, assessmentPassed }) => ({
                url: `/api/v1/profile/${profileId}/portfolio`,
                method: 'GET',
                params: typeof assessmentPassed === 'boolean' ?
                    { assessmentPassed } : undefined
            }),
            providesTags: (result) => (result ?
                [...result.map(p => ({ type: 'CurrentUserPortfolios', id: p.id })), 'CurrentUserPortfolios'] :
                ['CurrentUserPortfolios']),
        }),
        getCurrentProfilePortfolioSubscriptions: builder
            .query({
            query: ({ profileId }) => ({
                url: `/api/v1/profile/${profileId}/portfolio_subscriptions`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            providesTags: (res, err, { profileId }) => [{ type: 'ProfilePortfolioSubscriptions', id: profileId }]
        }),
        getPortfolioCopiers: builder.query({
            query: ({ portfolioId, skip, take }) => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            providesTags: (result) => (result ?
                [
                    ...result.map(c => ({ type: 'PortfolioCopiers', id: c.id })),
                    'PortfolioCopiers',
                ] :
                ['PortfolioCopiers']),
            // eslint-disable-next-line max-len
            transformResponse: (response, meta, { skip, take, sortByDelay }) => [],
        }),
        getPortfolioCopiersQueueDetails: builder
            .query({
            query: ({ portfolioId }) => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            // eslint-disable-next-line max-len
            // TODO-FIX: would be fixed when portfolio copiers would be available
            // transformResponse: () => getCopiersQueueDetails()
            // as unknown as PortfolioCopiersQueueDetails,
            transformResponse: () => ({
                first: undefined,
                last: undefined,
                copiers_count: 0,
                delay_step: 0.01,
                projected_income: {
                    total: 0,
                    step: 0,
                },
                avg_price_per_position: 0,
            }),
        }),
        startCopying: builder.mutation({
            query: (_a) => {
                var { portfolioId } = _a, rest = __rest(_a, ["portfolioId"]);
                return ({
                    url: '/api/v1/portfolio-subscriptions',
                    body: Object.assign(Object.assign({}, rest), { portfolioId }),
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            },
            invalidatesTags: (res) => [
                { type: 'PortfolioSubscriptions', id: res === null || res === void 0 ? void 0 : res.portfolioId },
                { type: 'ProfilePortfolioSubscriptions', id: res === null || res === void 0 ? void 0 : res.subscriberProfileId }
            ]
        }),
        updateCopying: builder.mutation({
            query: (_a) => {
                var { subscriptionId } = _a, rest = __rest(_a, ["subscriptionId"]);
                return ({
                    url: `/api/v1/portfolio-subscriptions/${subscriptionId}`,
                    body: Object.assign({}, rest),
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            },
            invalidatesTags: (res) => [
                { type: 'PortfolioSubscriptions', id: res === null || res === void 0 ? void 0 : res.portfolioId },
                { type: 'ProfilePortfolioSubscriptions', id: res === null || res === void 0 ? void 0 : res.subscriberProfileId }
            ]
        }),
        stopCopying: builder.mutation({
            query: ({ subscriptionId }) => ({
                url: `/api/v1/portfolio-subscriptions/${subscriptionId}/cancel`,
                method: 'PATCH',
            }),
            invalidatesTags: (res) => [
                { type: 'PortfolioSubscriptions', id: res === null || res === void 0 ? void 0 : res.portfolioId },
                { type: 'ProfilePortfolioSubscriptions', id: res === null || res === void 0 ? void 0 : res.subscriberProfileId }
            ]
        }),
        getPortfolioSubscriptions: builder.query({
            query: ({ portfolioId, page = 1, pageSize = 10 }) => ({
                url: '/api/v1/portfolio-subscriptions',
                method: 'GET',
                params: {
                    portfolio_id: portfolioId,
                    page,
                    page_size: pageSize,
                }
            }),
            providesTags: (res, err, args) => [{ type: 'PortfolioPositions', id: args.portfolioId }],
        }),
        getPortfolioCurrentPositions: builder
            .query({
            query: ({ portfolioId, skip, take, sortByPnl, }) => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            // eslint-disable-next-line max-len
            // TODO-FIX: would be fixed when positions would be available
            // transformResponse: (response, meta, { skip, take, sortByPnl }) =>
            // getCurrentPositions(
            // skip, take, sortByPnl
            // ) as unknown as GetPortfolioPositionsResponse,
            transformResponse: () => ({
                data: [],
                count: 0,
            }),
            // TODO-FIX: should be updated\removed after enpoint clarification
            providesTags: (res, err, args) => [{ type: 'PortfolioPositions', id: args.portfolioId }],
        }),
        getPortfolioPositionsHistory: builder
            .query({
            query: ({ portfolioId, skip, take, }) => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            // eslint-disable-next-line max-len
            // TODO-FIX: would be fixed when positions would be available
            // transformResponse: (response, meta, { skip, take }) =>
            //  getPositionsHistory(skip, take) as unknown as GetPortfolioPositionsHistoryResponse,
            transformResponse: () => ({
                data: [],
                count: 0,
            }),
        }),
        getPortfolioPnl: builder.query({
            query: () => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            transformResponse: () => getPortfolioPnlData(),
        }),
        getProfilePortfolioTransactions: builder.query({
            query: () => ({
                url: '/api/v1/auth/me',
                // body: {},
                method: 'GET',
            }),
            transformResponse: () => getPortfolioTransactions(),
        })
    }),
});
export default portfoliosApi;
export const { useCreatePortfolioMutation, useGetTopPortfoliosQuery, useLazyGetTopPortfoliosQuery, useGetPortfolioCopiersQuery, useGetPortfolioCopiersQueueDetailsQuery, useStartCopyingMutation, useGetPortfolioCurrentPositionsQuery, useGetPortfolioPositionsHistoryQuery, useGetPortfolioQuery, useGetProfilePortfoliosQuery, useGetPortfoliosQuery, useLazyGetPortfolioQuery, useClosePortfolioMutation, useUpdatePortfolioMutation, useGetCurrentProfilePortfoliosQuery, useGetCurrentProfilePortfolioSubscriptionsQuery, useStopCopyingMutation, useUpdateCopyingMutation, useLazyGetCurrentProfilePortfoliosQuery, useLazyGetCurrentProfilePortfolioSubscriptionsQuery, useGetPortfolioPnlQuery, useGetProfilePortfolioTransactionsQuery, } = portfoliosApi;
