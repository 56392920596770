import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { TitleSecondary } from '@shared/ui/display/Typography';
import Button from '@shared/ui/buttons/Button';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import { ReactComponent as ArrowIcon } from '@icons/wolfkit-light/chevron-small-right-light.svg';
import { mapPortfoliosListFromResponse, PortfolioCard } from '@entities/portfolio';
import { useGetTopPortfoliosQuery } from '@shared/api/portfolio';
const Container = styled(ContainerColumn)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const Header = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const CardsContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: `${props.theme.spacing_sizes.l}px`,
}));
const TopPortfoliosInCategory = ({ category, portfolioType, hideHeader = false }) => {
    const { t } = useTranslation();
    const { navigate, routes } = useAppNavigation();
    const goToMarketplaceList = () => {
        navigate(routes.MARKETPLACE_LIST);
    };
    const { data, isLoading } = useGetTopPortfoliosQuery();
    // TODO: once we have API, use category and portfolioType to fetch portfolios
    const portfolios = useMemo(() => (!data || isLoading) ? [undefined, undefined, undefined] : mapPortfoliosListFromResponse(data), [
        data,
        isLoading
    ]);
    return (_jsxs(Container, { children: [!hideHeader && (_jsxs(Header, { children: [_jsx(TitleSecondary, { children: category }), _jsx(Button, { variant: 'plain', endIcon: ArrowIcon, onClick: goToMarketplaceList, children: t('marketplace.main_view.filters.see_all') })] })), _jsx(CardsContainer, { children: portfolios === null || portfolios === void 0 ? void 0 : portfolios.map((portfolio, idx) => (_jsx(PortfolioCard, { viewType: 'card', portfolio: portfolio, isLoading: isLoading }, idx))) })] }));
};
export default TopPortfoliosInCategory;
