import jsonToFormData from 'json-form-data';
import ContactProvider from './contact-provider';
import { TRADER_ACTIVITY_MOCK } from './mocks';
const SOCIALS_START_URL = {
    Facebook: 'https://www.facebook.com/',
    X: 'https://x.com/',
    Instagram: 'https://www.instagram.com/',
    Discord: 'https://discord.gg/',
    Twitch: 'https://www.twitch.tv/',
    YouTube: 'https://www.youtube.com/@',
    Reddit: 'https://www.reddit.com/',
    Telegram: 'https://t.me/',
    WhatsApp: 'https://wa.me/',
    LinkedIn: 'https://www.linkedin.com/in/',
    TikTok: 'https://www.tiktok.com/@',
    WebPage: '',
};
// eslint-disable-next-line import/prefer-default-export
export const mapContacts = (profile) => {
    var _a;
    const contacts = [];
    (_a = profile.contacts) === null || _a === void 0 ? void 0 : _a.forEach((contact) => {
        var _a, _b, _c;
        const updatedContact = Object.assign({}, contact);
        if (updatedContact.type !== ContactProvider.WebPage) {
            if (((_a = updatedContact.contact) === null || _a === void 0 ? void 0 : _a.startsWith('/')) ||
                ((_b = updatedContact.contact) === null || _b === void 0 ? void 0 : _b.startsWith('@'))) {
                updatedContact.contact = `${SOCIALS_START_URL[contact.type]}${updatedContact.contact.slice(1)}`;
            }
            else if (!((_c = updatedContact.contact) === null || _c === void 0 ? void 0 : _c.startsWith(SOCIALS_START_URL[updatedContact.type]))) {
                updatedContact.contact = `${SOCIALS_START_URL[contact.type]}${updatedContact.contact}`;
            }
        }
        contacts.push(updatedContact);
    });
    return contacts;
};
export const getTraderProfileActivity = () => TRADER_ACTIVITY_MOCK;
export const prepareVerifyTraderRequestBody = (body) => {
    const formData = new FormData();
    body.documents.forEach(document => formData.append('documents', document));
    const bodyJSON = Object.assign({}, body);
    delete bodyJSON.documents;
    return jsonToFormData(bodyJSON, { initialFormData: formData });
};
