var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { useMemo, } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { submitStep, selectExchange, cancelEditStep, goToNextStep, } from '@entities/portfolio';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
import { BodyMediumSemiBold, Subtitle } from '@shared/ui/display/Typography';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import { IsDefined } from '@utils/js-ts';
import WalletSelect from '@shared/ui/inputs/WalletSelect';
import { getWalletsFromExchanges } from '@entities/wallet';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import { formatUSDT } from '@shared/lib/numbers';
import BidToQueueStep from './BidToQueueStep';
import { getSelectExchangeSchema } from './validation';
const LoaderContainer = styled(ContainerColumn)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    minHeight: '100px',
}));
const Form = styled.form();
const ExchangeName = styled(Subtitle)(() => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '145px',
}));
const CollapsedContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const ExchangeNameCollapsed = styled(ContainerRow)((props) => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs,
}));
const SelectExchangeCollapsed = ({ wallet = undefined, }) => {
    if (!IsDefined(wallet)) {
        return null;
    }
    return (_jsxs(CollapsedContainer, { children: [_jsxs(ExchangeNameCollapsed, { children: [_jsx(ExchangeIcon, { type: wallet.exchange.type, size: 24 }), _jsx(ExchangeName, { children: wallet.exchange.properties.connectionName })] }), _jsx(BodyMediumSemiBold, { children: formatUSDT(parseFloat(wallet === null || wallet === void 0 ? void 0 : wallet.totalAssetsUsdt), { maximumFractionDigits: 2 }) })] }));
};
const BidToQueueSelectExchangeStep = ({ stepName, nextStepName, }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { subscriptionData, steps, portfolio } = useAppSelector(state => state.portfolioSubscription);
    const { data: wallets, isFetching, isError, } = useGetExchangeAccountsQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: getWalletsFromExchanges(data || [], portfolio === null || portfolio === void 0 ? void 0 : portfolio.type) }));
        },
    });
    const { handleSubmit, setValue, watch } = useForm({
        defaultValues: subscriptionData,
        resolver: yupResolver(getSelectExchangeSchema({
            translateFn: t,
            wallets,
            minAmount: subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.minInvestAmount,
        }))
    });
    const walletId = watch('walletId');
    const selectedWallet = useMemo(() => wallets === null || wallets === void 0 ? void 0 : wallets.find(e => e.id === walletId), [wallets, walletId]);
    const handleWalletSelect = (_ev, itemId) => {
        setValue('walletId', itemId);
    };
    const onSubmit = (values) => {
        if (IsDefined(values.walletId) && IsDefined(selectedWallet)) {
            dispatch(selectExchange({
                walletId: values.walletId,
                totalAmountUsdt: parseFloat(selectedWallet.totalAssetsUsdt),
            }));
            dispatch(submitStep(stepName));
            dispatch(goToNextStep(nextStepName));
        }
    };
    const onAction = (actionType) => {
        if (actionType === 'update' && IsDefined(walletId) && IsDefined(selectedWallet)) {
            dispatch(selectExchange({
                walletId,
                totalAmountUsdt: parseFloat(selectedWallet.totalAssetsUsdt),
            }));
            dispatch(cancelEditStep(stepName));
        }
        else if (actionType === 'cancel' && IsDefined(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.walletId)) {
            setValue('walletId', subscriptionData.walletId);
        }
    };
    const errorMessage = useMemo(() => t('portfolio.subscription.exchange_balance_error'), [t]);
    return (_jsxs(_Fragment, { children: [isFetching && (_jsx(LoaderContainer, { children: _jsx(LoadingIndicator, { isLoading: isFetching }) })), !isFetching &&
                !isError &&
                wallets && (_jsx(Form, { onSubmit: handleSubmit(onSubmit), children: _jsx(BidToQueueStep, { stepName: stepName, title: t('portfolio.subscription.steps.select_exchange.label'), buttonsDisabled: !IsDefined(walletId), onAction: onAction, 
                    // errorMessage={errorMessage}
                    collapsedContent: _jsx(SelectExchangeCollapsed, { wallet: selectedWallet }), completelyDisabled: steps[stepName].disabled, children: _jsx(WalletSelect, { items: wallets.map(({ id, totalAssetsUsdt, type, exchange, }) => ({
                            key: exchange.id,
                            items: [{
                                    walletType: type,
                                    id,
                                    amount: parseFloat(totalAssetsUsdt),
                                    exchangeType: exchange.type,
                                    walletName: exchange.properties.connectionName,
                                }],
                        })), onChange: handleWalletSelect, value: walletId || '', renderEmptyValue: t('portfolio.subscription.steps.select_exchange.placeholder'), size: 'large', disabled: steps[stepName].disabled }) }) }))] }));
};
export default BidToQueueSelectExchangeStep;
