import { LAP_VALUE } from '../constants';
const defineDotsDistance = (d1, d2) => Math.sqrt(Math.pow((d1[0] - d2[0]), 2) + Math.pow((d1[1] - d2[1]), 2));
class PointsTree {
    constructor(original, parent) {
        this.originalNode = original;
        this.right = null;
        this.left = null;
        this.parent = parent || null;
    }
    static buildTree(
    // we suppose that points are already sorted
    points, parent) {
        if (points.length === 0) {
            return null;
        }
        if (points.length === 1) {
            return new PointsTree(points[0], parent);
        }
        const median = Math.floor(points.length / 2);
        const node = new PointsTree(points[median], parent);
        node.left = PointsTree.buildTree(points.slice(0, median), node);
        node.right = PointsTree.buildTree(points.slice(median + 1), node);
        return node;
    }
    static defineClosestElements({ node, searchPoint, distance: searchDistance = LAP_VALUE, scaleFns, matchedNodes = [], }) {
        const { scaleX, scaleY } = scaleFns;
        const closestPoints = {};
        const searchFn = ({ currentNode, }) => {
            var _a;
            if (currentNode === null)
                return;
            let mappedPoint;
            let currentDistance;
            if (node === null || node === void 0 ? void 0 : node.originalNode) {
                mappedPoint = Object.assign({}, currentNode.originalNode);
                currentDistance = defineDotsDistance([scaleX(mappedPoint.date), scaleY(mappedPoint.value)], searchPoint);
            }
            const isCurvePointUndefined = mappedPoint && currentDistance &&
                typeof closestPoints[mappedPoint.key] === 'undefined' && currentDistance <= searchDistance;
            const isCurrentPointCloser = mappedPoint && currentDistance &&
                typeof ((_a = closestPoints[mappedPoint.key]) === null || _a === void 0 ? void 0 : _a.distance) === 'number' &&
                closestPoints[mappedPoint.key].distance > currentDistance;
            if (typeof currentDistance === 'number' && (isCurvePointUndefined || isCurrentPointCloser)) {
                closestPoints[mappedPoint.key] = Object.assign(Object.assign({}, mappedPoint), { distance: currentDistance });
            }
            if (currentNode.left) {
                searchFn({
                    currentNode: currentNode.left,
                    distance: searchDistance,
                });
            }
            if (currentNode.right) {
                searchFn({
                    currentNode: currentNode.right,
                    distance: searchDistance,
                });
            }
        };
        searchFn({
            currentNode: node,
            distance: searchDistance,
        });
        Object.values(closestPoints).forEach((matchedNode) => {
            matchedNodes.push(matchedNode);
        });
    }
}
export default PointsTree;
