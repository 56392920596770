import { useState } from 'react';
import { ExchangeFilter, PeriodFiler, SortFilter } from './constants';
const INIT_FILTERS = {
    name: '',
    duration: PeriodFiler['30_DAYS'],
    exchangeType: ExchangeFilter.All,
    sortBy: SortFilter.Profit,
    maxDropdown: 0,
    activeView: 'card',
    socialLinks: [],
    lifetime: '',
    subscriptionPrice: 0,
    subscriptionPriceMax: [0, 1500],
    areAdditionalFiltersOpen: false,
    activeFilters: [],
};
const useListFilters = () => {
    const [listFilters, setListFilters] = useState(INIT_FILTERS);
    const handleSearchStringChange = (searchString) => {
        setListFilters((prev) => (Object.assign(Object.assign({}, prev), { searchString })));
    };
    const toggleVisibility = () => {
        setListFilters((prev) => (Object.assign(Object.assign({}, prev), { areAdditionalFiltersOpen: !prev.areAdditionalFiltersOpen })));
    };
    const checkFilterField = (filters, key, value) => {
        const isDefault = value === INIT_FILTERS[key];
        const isInList = filters.activeFilters.find((val) => val === key);
        if (!isDefault) {
            return isInList ? filters.activeFilters : [...filters.activeFilters, key];
        }
        return filters.activeFilters.filter((val) => val !== key);
    };
    const updateFilter = (key, value) => {
        setListFilters((prev) => {
            const activeFilters = checkFilterField(prev, key, value);
            return (Object.assign(Object.assign({}, prev), { [key]: value, activeFilters }));
        });
    };
    const handleViewTypeChange = (viewType) => setListFilters((prev) => (Object.assign(Object.assign({}, prev), { activeView: viewType })));
    const updateSocials = (activeKeys) => setListFilters((prev) => (Object.assign(Object.assign({}, prev), { socials: activeKeys })));
    const handleFiltersClear = () => {
        setListFilters((prev) => (Object.assign(Object.assign({}, INIT_FILTERS), { areAdditionalFiltersOpen: prev.areAdditionalFiltersOpen, activeFilters: [] })));
    };
    return Object.assign(Object.assign({}, listFilters), { listFilters, activeFiltersCount: listFilters.activeFilters.length, toggleVisibility,
        updateFilter,
        handleSearchStringChange,
        handleViewTypeChange,
        updateSocials,
        handleFiltersClear });
};
export default useListFilters;
