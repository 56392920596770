import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { ScrollableProvider } from '../ScrollableContent';
import useDocumentTitleSetter from '../../helpers/useDocumentTitleSetter';
const LayoutGeneral = () => {
    // TODO: change the way we set the document title
    // use new rrd6 feautures like pathMatchers, etc
    useDocumentTitleSetter();
    return (_jsx(Suspense, { fallback: null, children: _jsx(ScrollableProvider, { children: _jsx(Outlet, {}) }) }));
};
export default LayoutGeneral;
