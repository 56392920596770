import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { AuthenticationMode } from '@entities/auth';
import { ContainerColumn, alignToCenter } from '@shared/ui/display/Containers';
import useAuth from '../../lib/useAuth';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
const Container = alignToCenter(ContainerColumn);
const AuthContainer = styled(Container)(() => ({
    width: 'inherit',
}));
const Auth = () => {
    const { authMode } = useAuth();
    const dispayedElement = useMemo(() => {
        if (authMode === null)
            return null;
        switch (authMode) {
            case AuthenticationMode.SIGN_IN:
                return _jsx(SignIn, {});
            case AuthenticationMode.SIGN_UP:
                return _jsx(SignUp, {});
            case AuthenticationMode.RESET_PASSWORD:
                return _jsx(ResetPassword, {});
            default:
                return null;
        }
    }, [authMode]);
    return (_jsx(AuthContainer, { children: dispayedElement }));
};
export default Auth;
