import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { ReactComponent as EmailIcon } from '@icons/wolfkit-light/envelope-light.svg';
import { ReactComponent as KeyIcon } from '@icons/wolfkit-light/key-light.svg';
import { ReactComponent as CameraIcon } from '@icons/wolfkit-light/camera-light.svg';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { maskEmail } from '@shared/lib/input-masks';
import { IsDefined } from '@utils/js-ts';
import SettingsRow from '@shared/ui/settings/SettingsRow';
import SettingsNameCell from '@shared/ui/settings/SettingsNameCell';
import SettingsValueCell from '@shared/ui/settings/SettingsValueCell';
import { CreateTraderProfileMode, openCreateTraderForm, setCreateTraderStep } from '@entities/profile';
import { GlobalSidebarElements, openSidebar } from '@app/store/slices/application';
import { CreateClientTraderSteps } from '@widgets/sidebars/CreateTraderProfile/api/useTraderCreation';
import KYCValue from './KYCValue';
import ChangePassword from '../../sidebars/ChangePassword';
const Container = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
}));
const MainSettings = () => {
    var _a, _b;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.user);
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const [isChangePasswordSidebarOpen, setIsChangePasswordSidebarOpen] = useState(false);
    const openVerificationSidebar = () => {
        dispatch(openCreateTraderForm(CreateTraderProfileMode.Verify));
        dispatch(setCreateTraderStep(CreateClientTraderSteps.GetVerification));
        dispatch(openSidebar(GlobalSidebarElements.CreateTraderProfile));
    };
    const openChangePasswordSidebar = () => {
        setIsChangePasswordSidebarOpen(true);
    };
    const closeChangePasswordSidebar = () => {
        setIsChangePasswordSidebarOpen(false);
    };
    const isTraderProfileActive = (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER';
    return (_jsxs(_Fragment, { children: [_jsxs(Container, { children: [_jsx(SettingsRow, { name: (_jsx(SettingsNameCell, { Icon: EmailIcon, title: t('settings.tabs.personal_info.main_table.email.title') })), value: (_jsx(SettingsValueCell, { text: (user === null || user === void 0 ? void 0 : user.email) ? maskEmail(user === null || user === void 0 ? void 0 : user.email) : undefined })), hideButton: true }, 'user-email'), _jsx(SettingsRow, { name: (_jsx(SettingsNameCell, { Icon: KeyIcon, title: t('settings.tabs.personal_info.main_table.password.title') })), value: _jsx(SettingsValueCell, {}), manageBtnProps: {
                            children: t('actions.manage', { ns: 'common' }),
                            onClick: openChangePasswordSidebar,
                        } }, 'user-password'), isTraderProfileActive && (_jsx(SettingsRow, { name: (_jsx(SettingsNameCell, { Icon: CameraIcon, title: t('settings.tabs.personal_info.main_table.kyc.title') })), value: (_jsx(KYCValue, { isUserDefined: IsDefined(user), isVerifiedTrader: IsDefined((_a = user === null || user === void 0 ? void 0 : user.traderProfile) === null || _a === void 0 ? void 0 : _a.isVerifiedTrader) &&
                                ((_b = user === null || user === void 0 ? void 0 : user.traderProfile) === null || _b === void 0 ? void 0 : _b.isVerifiedTrader) === true })), manageBtnProps: {
                            children: t('actions.manage', { ns: 'common' }),
                            onClick: openVerificationSidebar,
                        } }, 'kyc'))] }), _jsx(ChangePassword, { isOpen: isChangePasswordSidebarOpen, onClose: closeChangePasswordSidebar, shouldCloseOnOutsideClick: true, destroyOnHide: true })] }));
};
export default MainSettings;
