import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useWizard } from '@shared/ui/misc/Wizard';
import useAuth from '@pages/auth/lib/useAuth';
import { ReactComponent as EmailIcon } from '@icons/wolfkit-light/envelope-light.svg';
import { alignToCenter, ContainerColumn } from '@shared/ui/display/Containers';
import Icon from '@shared/ui/icons/Icon';
import AuthModeSwitcher from '@pages/auth/ui/components/AuthModeSwitcher';
import { AuthButton, BinanceIcon, ButtonContainer as ButtonContainerComponent, ButtonDivider, } from '../../../../components/shared';
import EntryImage from '../../../../components/InitialImage';
import FormWrapper from '../../../../components/FormWrapper';
const Container = alignToCenter(ContainerColumn);
const InitialStepContainer = styled.div(() => ({
    display: 'flex',
    width: 'inherit',
    height: 'inherit',
}));
const EntryImageContainer = styled.div(() => ({
    flex: 1,
    width: 'inherit',
}));
const AuthFormContainer = styled(Container)(() => ({
    flex: 1,
    height: 'inherit',
}));
const ButtonContainer = styled(ButtonContainerComponent)(props => ({
    flexDirection: 'column',
    gap: props.theme.spacing_sizes.xm,
    maxWidth: 344,
}));
const Initial = () => {
    const { t } = useTranslation();
    const { openEmailForm, handleBinanceAuth } = useAuth();
    const { goNext } = useWizard();
    const goToEmailForm = () => {
        openEmailForm();
        goNext();
    };
    const goToBinance = () => {
        handleBinanceAuth();
    };
    return (_jsxs(InitialStepContainer, { children: [_jsx(EntryImageContainer, { children: _jsx(EntryImage, {}) }), _jsx(AuthFormContainer, { children: _jsx(FormWrapper, { titleText: t('auth.sign_in.steps.initial.title', { ns: 'common' }), renderInnerForm: (_jsxs(ButtonContainer, { children: [_jsx(AuthButton, { onClick: goToBinance, startIcon: _jsx(Icon, { IconComponent: BinanceIcon, keepOriginalColor: true }) }), _jsx(ButtonDivider, { children: t('auth.sign_in.steps.initial.divider_text', { ns: 'common' }) }), _jsx(AuthButton, { startIcon: _jsx(Icon, { IconComponent: EmailIcon }), onClick: goToEmailForm, children: t('auth.sign_in.buttons.email', { ns: 'common' }) })] })), renderLinks: _jsx(AuthModeSwitcher, {}), justifyContentToCenter: true }) })] }));
};
export default Initial;
