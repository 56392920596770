import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { BodySmall } from '@shared/ui/display/Typography';
const Container = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'default',
    zIndex: 1,
    gap: props.theme.spacing_sizes.s,
}));
const AssetBadge = styled.span(() => ({
    width: 8,
    height: 8,
    borderRadius: 2,
}));
const AssetName = styled(BodySmall)(props => ({
    color: props.theme.palette.text.primary,
    lineHeight: '16px',
}));
const AssetPercentage = styled(BodySmall)(props => ({
    color: props.theme.palette.text.secondary,
    lineHeight: '16px',
}));
const AssetBarLegendItem = ({ assetBarData, color, }) => {
    const percentagePrefix = assetBarData.displayApproximateSign ? '<' : '';
    return (_jsxs(Container, { children: [_jsx(AssetBadge, { style: {
                    backgroundColor: color,
                } }), _jsx(AssetName, { children: assetBarData.assetName }), _jsx(AssetPercentage, { children: `${percentagePrefix} ${assetBarData.percentage}%` })] }));
};
export default AssetBarLegendItem;
