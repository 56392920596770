import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Icon from '@shared/ui/icons/Icon';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { BodySemiBold } from '@shared/ui/display/Typography';
import { ReactComponent as CaretDownIcon } from '@icons/wolfkit-light/caret-down-light.svg';
export const ProfileEditContainer = styled(ContainerColumn)((props) => ({
    gap: props.theme.spacing_sizes.l,
}));
export const Form = styled.form((props) => ({
    display: 'flex',
    width: '100%',
    gap: `${props.theme.spacing_sizes.xl}px`,
}));
export const Avatar = styled.div(() => ({
    width: 128,
    height: 128,
    borderRadius: '50%',
}));
export const InputColumn = styled(ContainerColumn)(() => ({
    flex: 1,
    maxWidth: 220,
}));
export const FieldLabel = styled(BodySemiBold, { shouldForwardProp: (propName) => propName !== 'required' }) `
    margin-bottom: ${props => props.theme.spacing_sizes.xs * 1.25}px;

    ${props => props.required &&
    `
        &:after {
            content: '*';
            color: ${props.theme.palette.secondary.main};
            margin-left: 2px;
        }
    `}
`;
export const ButtonsContainer = styled(ContainerColumn)((props) => ({
    flexShrink: 0,
    width: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
export const InputFieldsRow = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'haveInputErrors' })((props) => ({
    gap: `${props.theme.spacing_sizes.m}px`,
    height: 'auto',
    marginBottom: props.haveInputErrors ?
        props.theme.spacing_sizes.xs * 1.25 : props.theme.spacing_sizes.s * 6,
}));
export const NicknameContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    marginBottom: props.theme.spacing_sizes.xs * 1.25,
}));
export const AboutContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
export const SocialsColumn = styled(ContainerColumn)(props => ({
    maxWidth: 420,
    marginLeft: `${props.theme.spacing_sizes.xl}px`,
}));
export const FormInnerContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
export const InputsContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    maxWidth: 452,
}));
export const SelectCaretDownIcon = ({ className = undefined }) => (_jsx(Icon, { className: className, size: 20, IconComponent: CaretDownIcon }));
