var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '@shared/ui/inputs/Input';
import InputPassword from '@shared/ui/inputs/InputPassword';
import { alignToCenter, ContainerColumn } from '@shared/ui/display/Containers';
import { handleResponseError } from '@pages/auth/lib/utils';
import { emailFormValidationSchema } from '@pages/auth/model/login-email';
import { ButtonContainer, SubmitButton } from '@pages/auth/ui/components/shared';
import Link from '@shared/ui/navigation/Link';
const ColumnContainer = alignToCenter(ContainerColumn);
const InputContainer = styled('div', { shouldForwardProp: propName => propName !== 'hasError' })(props => ({
    width: 'inherit',
    marginBottom: props.hasError ? 0 : props.theme.spacing_sizes.l,
}));
const LastInputContainer = styled(InputContainer)(props => ({
    marginBottom: props.hasError ? 0 : props.theme.spacing_sizes.l,
}));
const LinkContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: 'inherit',
    marginBottom: props.theme.spacing_sizes.l,
}));
const Form = styled.form(() => ({
    width: 'inherit',
    maxWidth: '458px',
}));
const INIT_VALUES = {
    email: '',
    password: '',
};
const EmailForm = ({ onSubmit: submitFn, goToResetPassword, isSigningIn }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { control, handleSubmit, setError, formState: { errors }, } = useForm({
        defaultValues: INIT_VALUES,
        resolver: yupResolver(emailFormValidationSchema(t)),
    });
    const haveEmailErrors = typeof ((_a = errors.email) === null || _a === void 0 ? void 0 : _a.message) !== 'undefined';
    const havePasswordErrors = typeof ((_b = errors.password) === null || _b === void 0 ? void 0 : _b.message) !== 'undefined';
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield submitFn(values);
        if (typeof res !== 'boolean') {
            handleResponseError({
                translateFn: t,
                error: res.error,
                setError,
            });
        }
    });
    return (_jsx(Form, { onSubmit: handleSubmit(onSubmit), 
        // noValidate disables default HTML5 validations that causes it's own warnings/errors to be shown
        // link to the issue - https://github.com/mui/material-ui/issues/31123
        noValidate: true, children: _jsxs(ColumnContainer, { children: [_jsx(InputContainer, { hasError: haveEmailErrors, children: _jsx(Controller, { name: 'email', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(Input, { "data-testid": 'email_email-form', InputLabelProps: { shrink: true }, type: 'email', ref: field.ref, value: field.value, onChange: field.onChange, label: t('auth.sign_up.steps.credentials.email.email_input_title', { ns: 'common' }), placeholder: t('auth.sign_up.steps.credentials.email.email_input_placeholder', { ns: 'common' }), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, autoComplete: 'email', size: 'medium', disabled: isSigningIn }));
                        } }) }), _jsx(LastInputContainer, { hasError: havePasswordErrors, children: _jsx(Controller, { name: 'password', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(InputPassword, { "data-testid": 'password_email-form', InputLabelProps: { shrink: true }, ref: field.ref, value: field.value, onChange: field.onChange, label: t('auth.sign_up.steps.credentials.email.password_input_title', { ns: 'common' }), placeholder: t('auth.sign_up.steps.credentials.email.password_input_placeholder', { ns: 'common' }), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, autoComplete: 'password', size: 'medium', disabled: isSigningIn }));
                        } }) }), _jsx(LinkContainer, { children: Trans({
                        i18nKey: 'auth.sign_in.steps.credentials.reset_password_text',
                        ns: 'common',
                        components: [
                            _jsx(Link, { rel: 'noopener noreferrer', onClick: goToResetPassword }, 'reset-password'),
                        ],
                    }) }), _jsx(ButtonContainer, { children: _jsx(SubmitButton, { "data-testid": 'submit_email-form', type: 'submit', size: 'large', isLoading: isSigningIn, disabled: isSigningIn, fullWidth: true, children: t('auth.sign_up.steps.credentials.email.submit_btn_text', { ns: 'common' }) }) })] }) }));
};
export default EmailForm;
