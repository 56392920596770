import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import { BodySemiBold } from '@shared/ui/display/Typography';
const Container = styled.div((props) => ({
    display: 'flex',
    width: 'auto',
    height: '40px',
    boxSizing: 'border-box',
    padding: '10px 12px',
    alignItems: 'flex-start',
    flex: '1 0 0',
    borderRadius: '6px',
    backgroundColor: props.theme.palette.primary.light,
    boxShadow: `0px 1px 3px 0px ${props.theme.customColors.surface.shadow}`,
}));
const LinkText = withTextEllipsis(styled(BodySemiBold)((props) => ({
    color: props.theme.palette.primary.main,
})));
const PortfolioReferralLinkDisplay = ({ className = undefined, link, }) => (_jsx(Container, { className: className, children: _jsx(LinkText, { tooltipText: link, children: link }) }));
export default PortfolioReferralLinkDisplay;
