const mapSubscriptionDataFromResponse = ({ portfolio, subscription, walletTotalAssets = undefined, }) => ({
    walletId: subscription.subscriberWalletId,
    minInvestAmount: parseFloat(portfolio.minAllocatedCapital),
    investAmount: parseFloat(subscription.allocatedCapital),
    subscriptionPrice: portfolio.subscriptionPrice,
    bidAmount: subscription.pricePerMonth - portfolio.subscriptionPrice,
    minBidAmount: portfolio.subscriptionPrice,
    maxInvestAmount: walletTotalAssets ? parseFloat(walletTotalAssets) : 10,
    // TODO-FIX: push this field to PortfolioSubscription after Backend improvements
    stopCopyingLimit: 15,
    // TODO-FIX: push this field to PortfolioSubscription after Backend improvements
    queuePosition: 1,
    currentSubscriptionId: subscription.id,
});
const updateSubscriptionStepsStatus = (steps, status) => {
    const newSteps = Object.assign({}, steps);
    Object.keys(newSteps).forEach((key) => {
        newSteps[key] = Object.assign(Object.assign({}, newSteps[key]), { status });
    });
    return newSteps;
};
export { updateSubscriptionStepsStatus, mapSubscriptionDataFromResponse, };
