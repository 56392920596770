import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as SwitchProfileIcon } from '@icons/wolfkit-light/users-light.svg';
import { useAppSelector } from '@app/store/Hooks';
import { ContainerRow } from '@shared/ui/display/Containers';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { ProfileSwitcher } from '@features/profile/profile-switch';
const IconTextStyled = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.m,
    '& .MuiTypography-root': {
        fontWeight: 400,
        color: props.theme.palette.text.primary,
    },
}));
const SwitcherContainer = styled(ContainerRow)(() => ({
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const MenuProfileSwitcher = () => {
    const { t } = useTranslation();
    const { currentUserProfile } = useAppSelector((state) => state.profile);
    const text = ((currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'CLIENT' &&
        t('settings.switch_to_trader', { ns: 'common' })) ||
        ((currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER' &&
            t('settings.switch_to_client', { ns: 'common' })) || '';
    return (_jsxs(SwitcherContainer, { children: [_jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: SwitchProfileIcon })), text: text }), _jsx(ProfileSwitcher, {})] }));
};
export default MenuProfileSwitcher;
