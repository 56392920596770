export const UNVERIFIED_PORTFOLIO_CLIENTS_LIMIT = 25;
export const SUBSCRIPTION_DATA_INIT = {
    investAmount: 0,
    stopCopyingLimit: 15,
    subscriptionPrice: 0,
    minInvestAmount: 0,
    maxInvestAmount: 0,
    bidAmount: 0,
    minBidAmount: 0,
    walletId: null,
    queuePosition: 1,
};
export const SUBSCRIPTION_STEPS_INIT = {
    select_exchange: {
        status: 'uninitialized',
        order: 0,
        disabled: false,
    },
    invest_amount: {
        status: 'uninitialized',
        order: 1,
        disabled: false,
    },
    queue_priority: {
        status: 'uninitialized',
        order: 2,
        disabled: false,
    },
    // payment_method: {
    //     status: 'uninitialized',
    //     order: 3,
    //     disabled: false,
    // },
};
