import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { exitSubscriptionProcess, startSubscriptionProccess, finishSubscriptionEditProcess, startEdittingSubscriptionData, stopEdittingSubscriptionData, } from '@entities/portfolio';
import { ReactComponent as EditIcon } from '@icons/wolfkit-light/pencil-light.svg';
import Button from '@shared/ui/buttons/Button';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { IsDefined } from '@utils/js-ts';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import CollapsePanel from '@shared/ui/layout/CollapsePanel';
import { useStartCopyingMutation, useStopCopyingMutation, useUpdateCopyingMutation, } from '@shared/api/portfolio';
import BidToQueueSelectExchangeStep from './SelectExchangeStep';
import BidToQueueEnterInvestmentStep from './EnterInvestmentStep';
import SelectQueuePositionStep from './select-queue-position-step/SelectQueuePositionStep';
const StepperContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const StepperControlsContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const Divider = styled.div(props => ({
    display: 'flex',
    width: '100%',
    height: 1,
    backgroundColor: props.theme.customColors.menu.border,
    marginBottom: props.theme.spacing_sizes.l,
}));
const checkIfStepOpen = (status) => status !== 'uninitialized';
const BidToQueueSteps = ({ steps, }) => (_jsxs(_Fragment, { children: [_jsxs(CollapsePanel, { isOpen: checkIfStepOpen(steps.select_exchange.status), destroyOnHide: true, children: [_jsx(Divider, {}), _jsx(BidToQueueSelectExchangeStep, { stepName: 'select_exchange', nextStepName: 'invest_amount' })] }, 'select-exchange'), _jsxs(CollapsePanel, { isOpen: checkIfStepOpen(steps.invest_amount.status), destroyOnHide: true, children: [_jsx(Divider, {}), _jsx(BidToQueueEnterInvestmentStep, { stepName: 'invest_amount', nextStepName: 'queue_priority' })] }, 'invest-amount'), _jsxs(CollapsePanel, { isOpen: checkIfStepOpen(steps.queue_priority.status), destroyOnHide: true, children: [_jsx(Divider, {}), _jsx(SelectQueuePositionStep, { stepName: 'queue_priority' })] }, 'queue_priority')] }));
// TODO: check if we can subscribe onto the portfolio if we are the trader
const BidToQueueStepper = ({ className = undefined, isLoading = false, }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.user);
    const { portfolio, isUserSubscribed, subscriptionData, steps, status, } = useAppSelector(state => state.portfolioSubscription);
    const [startCopyReq, { isLoading: isCreatingSubscription }] = useStartCopyingMutation();
    const [stopCopyingReq, { isLoading: isStopCopying }] = useStopCopyingMutation();
    const [updateCopyingReq, { isLoading: isUpdating }] = useUpdateCopyingMutation();
    // TODO: check it, since completedSteps is an array it might potentialy have the same size,
    // but with repeated steps
    const allStepsCompleted = useMemo(() => {
        const stepsKeys = Object.keys(steps);
        const { count } = stepsKeys.reduce((acc, step) => {
            if (steps[step].status === 'completed') {
                acc.count += 1;
            }
            return acc;
        }, { count: 0 });
        return count === Object.keys(steps).length;
    }, [steps]);
    const initSubscriptionProcess = () => {
        if (IsDefined(portfolio)) {
            dispatch(startSubscriptionProccess(portfolio));
        }
    };
    const handleSubscriptionFinish = () => {
        if (!IsDefined(portfolio) || !IsDefined(subscriptionData)) {
            return;
        }
        if (IsDefined(user) && IsDefined(subscriptionData.walletId)) {
            if (isUserSubscribed && IsDefined(subscriptionData.currentSubscriptionId)) {
                updateCopyingReq({
                    subscriptionId: subscriptionData.currentSubscriptionId,
                    subscriberWalletId: subscriptionData.walletId,
                    subscriberProfileId: user === null || user === void 0 ? void 0 : user.activeProfileId,
                    pricePerMonth: portfolio.subscriptionPrice + subscriptionData.bidAmount,
                    allocatedCapital: subscriptionData.investAmount,
                }).then((res) => {
                    if (res.data) {
                        dispatch(finishSubscriptionEditProcess());
                    }
                });
            }
            else {
                startCopyReq({
                    portfolioId: portfolio.id,
                    subscriberWalletId: subscriptionData.walletId,
                    subscriberProfileId: user === null || user === void 0 ? void 0 : user.activeProfileId,
                    pricePerMonth: portfolio.subscriptionPrice + subscriptionData.bidAmount,
                    allocatedCapital: subscriptionData.investAmount,
                });
            }
        }
    };
    const startEditting = () => {
        dispatch(startEdittingSubscriptionData());
    };
    const stopEditting = () => {
        dispatch(stopEdittingSubscriptionData());
    };
    const stopCopying = () => {
        if (IsDefined(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.currentSubscriptionId)) {
            stopCopyingReq({ subscriptionId: subscriptionData.currentSubscriptionId }).then(() => {
                dispatch(exitSubscriptionProcess());
            });
        }
    };
    return (_jsxs(StepperContainer, { className: className, children: [_jsx(BidToQueueSteps, { steps: steps }), _jsxs(StepperControlsContainer, { children: [!isUserSubscribed &&
                        !IsDefined(subscriptionData) && (_jsx(WithSkeleton, { isLoading: isLoading, width: 376, height: 48, children: _jsx(Button, { variant: 'filled', color: 'success', size: 'large', isLoading: isLoading, onClick: initSubscriptionProcess, fullWidth: true, children: t('portfolio.subscription.start_copy_trade') }) })), !isUserSubscribed &&
                        IsDefined(subscriptionData) &&
                        allStepsCompleted && (_jsx(Button, { variant: 'filled', color: 'success', size: 'large', isLoading: isLoading || isCreatingSubscription, disabled: isCreatingSubscription, onClick: handleSubscriptionFinish, fullWidth: true, children: t('portfolio.subscription.start_copy_trade') })), isUserSubscribed && (_jsxs(_Fragment, { children: [status === 'initialized_before' &&
                                (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', isLoading: isUpdating, disabled: isStopCopying || isUpdating, onClick: startEditting, startIcon: EditIcon, fullWidth: true, children: t('portfolio.subscription.edit_copy_trade') })), status === 'editting' && (_jsxs(_Fragment, { children: [_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', isLoading: isLoading || isUpdating, disabled: isStopCopying || isUpdating || !allStepsCompleted, onClick: handleSubscriptionFinish, fullWidth: true, children: t('portfolio.subscription.edit_save') }), _jsx(Button, { variant: 'tinted', color: 'primary', size: 'large', isLoading: isLoading, disabled: isStopCopying || isUpdating || !allStepsCompleted, onClick: stopEditting, fullWidth: true, children: t('portfolio.subscription.edit_close') })] })), _jsx(Button, { variant: 'filled', color: 'error', size: 'large', isLoading: isLoading || isStopCopying, disabled: isStopCopying || isUpdating || status === 'editting', onClick: stopCopying, fullWidth: true, children: t('portfolio.subscription.stop_copy_trade') })] }))] })] }));
};
export default BidToQueueStepper;
