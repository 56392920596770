import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { ContainerRow } from '@shared/ui/display/Containers';
import { Title } from '@shared/ui/display/Typography';
import MonthlyReturnPositions from '../MonthlyReturnPositions';
const IncomeBadge = withPercentageIncomeBadge(Title);
const Container = styled(ContainerRow)(() => ({
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'right',
    gap: 10,
}));
const PortfolioMonthlyReturn = ({ portfolio = undefined, userSubscribed }) => {
    var _a;
    const monthlyReturnDifference = () => {
        var _a;
        return (userSubscribed ?
            (_a = portfolio === null || portfolio === void 0 ? void 0 : portfolio.user_subscription_summary) === null || _a === void 0 ? void 0 : _a.past_month_difference :
            portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.average_followers_monthly_return);
    };
    const firstPosition = {
        position: 1,
        returnValue: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.average_followers_monthly_return_first) || 0,
    };
    const lastPosition = (((_a = portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers) === null || _a === void 0 ? void 0 : _a.count) || 0) > 1 ? {
        position: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.count) || 0,
        returnValue: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.average_followers_monthly_return_last) || 0,
    } : undefined;
    return (_jsxs(Container, { children: [_jsx(IncomeBadge, { badgeVariant: 'plain', percentageIncome: monthlyReturnDifference() || 0, incomeSymbol: 'approximation' }), _jsx(MonthlyReturnPositions, { first: firstPosition, last: lastPosition })] }));
};
export default PortfolioMonthlyReturn;
