var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { forwardRef, useMemo, } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as CheckmarkIcon } from '@icons/wolfkit-solid/checkmark-without-check-circle-solid.svg';
import { ReactComponent as XmarkIcon } from '@icons/wolfkit-solid/xmark-without-cross-circle-solid.svg';
import { ContainerColumn } from '@shared/ui/display/Containers';
import Icon from '@shared/ui/icons/Icon';
import InputMenuComponent from '@shared/ui/inputs/InputMenu';
const SearchInputContainer = styled(ContainerColumn)(() => ({
    width: 'unset',
    height: 'auto',
}));
const InputMenu = styled(InputMenuComponent)(() => ({
    '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
    },
    '& .MuiInputAdornment-positionStart p': {
        fontSize: 20,
        height: 22,
    },
}));
const CircularProgressStyled = styled(CircularProgress)(props => ({
    color: props.theme.palette.text.secondary,
}));
const SearchInputComponent = (_a, outerRef) => {
    var { onChange = undefined, state = 'idle', notificationStatus = undefined, notification = undefined, menuItems = [] } = _a, props = __rest(_a, ["onChange", "state", "notificationStatus", "notification", "menuItems"]);
    const theme = useTheme();
    const endAdornment = useMemo(() => {
        if (state === 'loading') {
            return (_jsx(CircularProgressStyled, { color: 'inherit', size: 20 }));
        }
        if (state === 'success' && notificationStatus !== 'error') {
            return (_jsx(Icon, { size: 20, color: theme.palette.success.dark, IconComponent: CheckmarkIcon }));
        }
        if (state === 'error' || notificationStatus === 'error') {
            return (_jsx(Icon, { size: 20, color: theme.palette.secondary.dark, IconComponent: XmarkIcon }));
        }
        return undefined;
    }, [state, notificationStatus, theme]);
    const handleChange = (ev) => {
        if (onChange) {
            onChange(ev);
        }
    };
    return (_jsx(SearchInputContainer, { children: _jsx(InputMenu, Object.assign({}, props, { onChange: handleChange, ref: outerRef, endAdornment: endAdornment, notification: notification, notificationStatus: notificationStatus || state, error: state === 'error', menuItems: menuItems })) }));
};
/**
 * wrapper above InputMenu with search status display
 */
const SearchInput = forwardRef(SearchInputComponent);
export default SearchInput;
