var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, } from 'react';
import styled from '@emotion/styled';
import MuiMenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import Select from '@shared/ui/inputs/Select';
import { Body, BodyMedium } from '@shared/ui/display/Typography';
import { getCountriesListNames } from '../../lib';
import useGetCountriesList from '../../api/useGetCountriesList';
export const GroupTitle = styled(BodyMedium)(props => ({
    fontSize: 12,
    fontWeight: 500,
    color: props.theme.palette.text.secondary,
    lineHeight: '20px',
    padding: '10px 12px',
}));
export const LocationsGroupDivider = styled.div(props => ({
    marginRight: 4,
    width: '100%',
    borderBottom: `1px solid ${props.theme.customColors.input.border}`,
}));
const SelectStyled = styled(Select)(() => ({
    width: '100%',
}));
const PlaceholderText = styled(Body)(() => ({
    letterSpacing: '-0.084px',
    color: '#ABB4BB',
}));
// eslint-disable-next-line react/function-component-definition
function LocationSelect(_a) {
    var { size = 'small', disabled = undefined, placeholder = undefined } = _a, props = __rest(_a, ["size", "disabled", "placeholder"]);
    const { t } = useTranslation();
    const { countriesList, popularCountriesList, isLoading } = useGetCountriesList();
    const { popularCountries, countries, countriesDictionary } = useMemo(() => getCountriesListNames(t, popularCountriesList, countriesList), [t, popularCountriesList, countriesList]);
    const renderValue = (value) => {
        if (value) {
            return countriesDictionary[value] || '';
        }
        if (placeholder) {
            return _jsx(PlaceholderText, { children: placeholder });
        }
        return null;
    };
    return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    _jsxs(SelectStyled, Object.assign({}, props, { size: size, disabled: isLoading || disabled, displayEmpty: typeof placeholder !== 'undefined', renderValue: renderValue, children: [popularCountries.length && (_jsx(GroupTitle, { children: t('profile.countries.popular') }, 'popular-countries-title')), popularCountries === null || popularCountries === void 0 ? void 0 : popularCountries.map((country) => (_jsx(MuiMenuItem, { value: country.value, children: country.label }, country.key))), popularCountries.length && _jsx(LocationsGroupDivider, {}, 'list-divider'), _jsx(GroupTitle, { children: t('profile.countries.alphabet_list') }, 'countries-title'), countries === null || countries === void 0 ? void 0 : countries.map((country) => (_jsx(MuiMenuItem, { value: country.value, children: country.label }, country.value)))] })));
}
export default LocationSelect;
