import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { BodyLargeBold } from '@shared/ui/display/Typography';
const Title = styled(BodyLargeBold)(props => ({
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '-0.456px',
    margin: props.margin,
}));
const AlertIconContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 70,
    height: 70,
    fontSize: 46,
    color: props.theme.palette.error.main,
    backgroundColor: props.theme.palette.error.light,
    userSelect: 'none',
    pointerEvents: 'none',
    cursor: 'none',
}));
const AlertIcon = () => _jsx(AlertIconContainer, { children: "!" });
export { Title, AlertIcon };
