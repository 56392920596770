import styled from '@emotion/styled';
import { ContainerRow } from '@shared/ui/display/Containers';
import { BodyLargeSemiBold } from '@shared/ui/display/Typography';
const ListHeader = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'marginBottom' })(props => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: props.marginBottom || props.theme.spacing_sizes.l,
}));
const ListTitle = styled(BodyLargeSemiBold, { shouldForwardProp: propName => propName !== 'disabled' })((props) => ({
    lineHeight: '24px',
    fontWeight: 600,
    opacity: props.disabled ? 0.5 : 1,
}));
export { ListHeader, ListTitle, };
