import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ContainerRow } from '@shared/ui/display/Containers';
import { ProfileSwitcher } from '@features/profile/profile-switch';
import { BodySemiBold } from '@shared/ui/display/Typography';
import Banner from '@shared/ui/display/Banner';
const SwitchContainer = styled(ContainerRow)(props => ({
    width: 'auto',
    gap: props.theme.spacing_sizes.m,
    alignItems: 'center',
}));
const SwitchText = styled(BodySemiBold)(props => ({
    color: props.theme.palette.primary.main,
    fontSize: 14,
}));
const SwitchProfileBanner = ({ description, profileType, }) => {
    const { t } = useTranslation();
    const switchButtonText = (profileType === 'CLIENT' &&
        t('settings.switch_to_trader', { ns: 'common' })) ||
        (profileType === 'TRADER' &&
            t('settings.switch_to_client', { ns: 'common' })) || '';
    return (_jsx(Banner, { variant: 'info', text: description, additionalButtons: (_jsxs(SwitchContainer, { children: [_jsx(SwitchText, { children: switchButtonText }), _jsx(ProfileSwitcher, {})] })), hideCloseButton: true }));
};
export default SwitchProfileBanner;
