var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/store/Hooks';
import { closeCreateTraderForm, setCreateTraderStep, updateCreateTraderValues, CreateTraderProfileMode, } from '@entities/profile';
import { useCreateProfileMutation, useVerifyTraderMutation, } from '@shared/api/profile';
import { closeSidebar } from '@store/slices/application';
import { useSendAvatarImageMutation } from '@store/slices/file';
import { useWizard } from '@shared/ui/misc/Wizard';
import { useToastNotification } from '@shared/ui/overlay/Toast';
import { mapTraderProfileFieldsToReq, mapFormValuesToTraderVerificationRequestBody } from '../lib';
export var CreateClientTraderSteps;
(function (CreateClientTraderSteps) {
    CreateClientTraderSteps[CreateClientTraderSteps["Initial"] = 0] = "Initial";
    CreateClientTraderSteps[CreateClientTraderSteps["Introduction"] = 1] = "Introduction";
    CreateClientTraderSteps[CreateClientTraderSteps["AddPhoto"] = 2] = "AddPhoto";
    CreateClientTraderSteps[CreateClientTraderSteps["EditPhoto"] = 3] = "EditPhoto";
    CreateClientTraderSteps[CreateClientTraderSteps["AddPhotoSuccess"] = 4] = "AddPhotoSuccess";
    CreateClientTraderSteps[CreateClientTraderSteps["GetVerification"] = 5] = "GetVerification";
    CreateClientTraderSteps[CreateClientTraderSteps["PersonalDetails"] = 6] = "PersonalDetails";
    CreateClientTraderSteps[CreateClientTraderSteps["AddressDetails"] = 7] = "AddressDetails";
    CreateClientTraderSteps[CreateClientTraderSteps["IdentityDetails"] = 8] = "IdentityDetails";
    CreateClientTraderSteps[CreateClientTraderSteps["DocumentsUpload"] = 9] = "DocumentsUpload";
    CreateClientTraderSteps[CreateClientTraderSteps["Final"] = 10] = "Final";
    CreateClientTraderSteps[CreateClientTraderSteps["Warning"] = 11] = "Warning";
})(CreateClientTraderSteps || (CreateClientTraderSteps = {}));
/**
 * could be used only inside wizard context
 */
const useTraderCreation = () => {
    const { current, goTo, goBack, goNext, } = useWizard();
    const { currentUserProfile, createTraderCurrentStep, createTraderPrevStep, createTraderProfileValues, createTraderProfileMode, areTraderValuesPrefilled, } = useAppSelector(state => state.profile);
    const [createProfileReq, { isLoading: isCreatingTraderProfile },] = useCreateProfileMutation();
    const [sendAvatarReq, { isLoading: isSendingAvatar }] = useSendAvatarImageMutation();
    const [verifyTraderReq, { isLoading: isVerifyTraderRequestLoading }] = useVerifyTraderMutation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { toast } = useToastNotification();
    const returnToEdit = () => {
        if (typeof createTraderPrevStep === 'number') {
            dispatch(setCreateTraderStep(createTraderPrevStep));
            goTo(createTraderPrevStep);
        }
    };
    const exitFromForm = () => {
        dispatch(closeCreateTraderForm());
        dispatch(closeSidebar());
    };
    const createTraderProfileRequest = () => __awaiter(void 0, void 0, void 0, function* () {
        const preparedValues = mapTraderProfileFieldsToReq(createTraderProfileValues);
        const res = yield createProfileReq(Object.assign({}, preparedValues));
        if (res === null || res === void 0 ? void 0 : res.data) {
            return { status: 'success', data: res.data };
        }
        toast({
            message: t('errors.internal_server_error', { ns: 'common' }),
            variant: 'error',
            displayStyle: 'solid',
        });
        return { data: res === null || res === void 0 ? void 0 : res.error.data, status: 'error' };
    });
    const sendProfileAvatar = (image, profileId) => __awaiter(void 0, void 0, void 0, function* () {
        return sendAvatarReq({ profileId, image })
            .then(() => ({ status: 'success' }), (error) => ({ error, status: 'error' }));
    });
    const createTraderProfile = () => __awaiter(void 0, void 0, void 0, function* () {
        let traderProfileId;
        if (createTraderProfileValues === null || createTraderProfileValues === void 0 ? void 0 : createTraderProfileValues.image) {
            const createProfileResult = yield createTraderProfileRequest()
                .then((result) => {
                if (result.status === 'success') {
                    return {
                        status: 'success',
                        profileId: result.data.id,
                    };
                }
                return { status: 'error' };
            }, () => ({ status: 'error' }));
            if ((createProfileResult === null || createProfileResult === void 0 ? void 0 : createProfileResult.status) === 'success' && createProfileResult.profileId) {
                const sendAvatarResult = yield sendProfileAvatar(createTraderProfileValues.image, createProfileResult.profileId);
                if ((sendAvatarResult === null || sendAvatarResult === void 0 ? void 0 : sendAvatarResult.status) === 'success') {
                    traderProfileId = String(createProfileResult.profileId);
                }
            }
        }
        else {
            const res = yield createTraderProfileRequest();
            if ((res === null || res === void 0 ? void 0 : res.status) === 'success' && res.data.id) {
                traderProfileId = String(res.data.id);
            }
        }
        return traderProfileId;
    });
    const finish = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!createTraderProfileValues) {
            return;
        }
        let traderProfileId;
        if (createTraderProfileMode === CreateTraderProfileMode.Create) {
            traderProfileId = yield createTraderProfile();
        }
        else if (currentUserProfile) {
            traderProfileId = currentUserProfile.id;
        }
        if (!traderProfileId) {
            return;
        }
        const traderVerificationRequestBody = mapFormValuesToTraderVerificationRequestBody(createTraderProfileValues);
        if (traderVerificationRequestBody) {
            try {
                yield verifyTraderReq({
                    profileId: traderProfileId,
                    body: traderVerificationRequestBody,
                }).unwrap();
            }
            catch (e) {
                return;
            }
        }
        dispatch(setCreateTraderStep(CreateClientTraderSteps.Final));
        goTo(CreateClientTraderSteps.Final);
    });
    const goToNextStep = (values) => {
        switch (createTraderCurrentStep) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                if (values) {
                    dispatch(updateCreateTraderValues(values));
                }
                dispatch(setCreateTraderStep(current + 1));
                goNext();
                break;
            case 9:
                finish();
                break;
            case 10:
                exitFromForm();
                break;
            default:
                break;
        }
    };
    const goToPrevStep = () => {
        switch (current) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                dispatch(setCreateTraderStep(current - 1));
                goBack();
                break;
            default:
                break;
        }
    };
    const handleSkip = () => {
        switch (current) {
            case 2:
                goTo(CreateClientTraderSteps.GetVerification);
                break;
            case 5:
                finish();
                break;
            default:
                break;
        }
    };
    const handleClose = () => {
        dispatch(setCreateTraderStep(CreateClientTraderSteps.Warning));
        goTo(CreateClientTraderSteps.Warning);
    };
    return {
        currentStep: createTraderPrevStep,
        goToNextStep,
        goToPrevStep,
        handleSkip,
        handleClose,
        returnToEdit,
        exitFromForm,
        isCreating: isCreatingTraderProfile || isSendingAvatar,
        isPrefilled: areTraderValuesPrefilled,
        isVerifyTraderRequestLoading,
    };
};
export default useTraderCreation;
