import { jsx as _jsx } from "react/jsx-runtime";
import { useTimer } from 'use-timer';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { BodyMedium } from '@shared/ui/display/Typography';
const TimerTime = 60000;
const TimerStep = 1000;
const Text = styled(BodyMedium)(() => ({
    fontSize: 12,
    color: '#ABB4BB',
}));
const ResendEmailCountdownTimer = ({ onTimeOver = () => { }, }) => {
    const { t } = useTranslation();
    const { time, status, } = useTimer({
        autostart: true,
        endTime: 0,
        initialTime: TimerTime,
        step: TimerStep,
        timerType: 'DECREMENTAL',
        onTimeOver,
    });
    const displayTimer = status === 'RUNNING' && time !== 0;
    const displayText = t('auth.reset_password.steps.confirmation.counter', { ns: 'common', counter: time / TimerStep });
    if (!displayTimer) {
        return null;
    }
    return (_jsx(Text, { children: displayText }));
};
export default ResendEmailCountdownTimer;
