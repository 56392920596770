import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { ContainerRow } from '@shared/ui/display/Containers';
import { BodyMediumSemiBold } from '@shared/ui/display/Typography';
import { AuthenticationMode } from '@entities/auth';
import Link from '../Link';
import useAuth from '../../../lib/useAuth';
const Container = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: '10px'
}));
const AuthModeSwitcher = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { authMode, openSignIn, openSignUp } = useAuth();
    const switchText = authMode === AuthenticationMode.SIGN_UP ?
        t('auth.sign_up.switch_text', { ns: 'common' }) :
        t('auth.sign_in.switch_text', { ns: 'common' });
    const linkText = authMode === AuthenticationMode.SIGN_UP ?
        t('auth.sign_up.switch_link_text', { ns: 'common' }) :
        t('auth.sign_in.switch_link_text', { ns: 'common' });
    const onLinkClick = () => {
        if (authMode === AuthenticationMode.SIGN_UP) {
            openSignIn();
        }
        else {
            openSignUp();
        }
    };
    return (_jsxs(Container, { children: [_jsx(BodyMediumSemiBold, { color: theme.palette.text.secondary, children: switchText }), _jsx(Link, { onClick: onLinkClick, underline: 'hover', children: linkText })] }));
};
export default AuthModeSwitcher;
