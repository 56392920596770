import { createSlice } from '@reduxjs/toolkit';
import { AuthenticationMode } from '@entities/auth/model';
import { authApiV1 } from '@shared/api/auth';
import { LogoutAction } from './actions';
import { logoutReducer, updateTokenReducer, } from './reducers';
import getInitialState from '../lib/getInitialState';
const SliceName = 'auth';
const authSlice = createSlice({
    name: SliceName,
    initialState: getInitialState(),
    reducers: {
        logout: logoutReducer,
        setAuthenticationMode: (state, { payload }) => {
            state.authMode = payload;
        },
        setAuthenticationMethod: (state, { payload }) => {
            state.authMethod = payload;
        },
        openResetPassword: (state) => {
            state.authMode = AuthenticationMode.RESET_PASSWORD;
            state.authStep = 0;
        },
        setConfirmationEmail: (state, { payload }) => {
            state.emailForConfirmation = payload;
        },
        setEmailForReset: (state, { payload }) => {
            state.emailForReset = payload;
        },
        closeResetForm: (state) => {
            state.emailForReset = undefined;
            state.authMode = AuthenticationMode.SIGN_IN;
            state.authStep = 0;
        },
        returnToSignIn: (state) => {
            state.emailForReset = undefined;
            state.authMode = AuthenticationMode.SIGN_IN;
            state.authStep = 1;
        },
        reset: () => getInitialState(),
    },
    extraReducers: (builder) => {
        builder.addCase(LogoutAction, logoutReducer);
        builder.addMatcher(authApiV1.endpoints.login.matchFulfilled, (state, { payload, type }) => {
            if (payload.verified) {
                updateTokenReducer(state, { payload, type });
            }
        });
        builder.addMatcher(authApiV1.endpoints.verifyEmail.matchFulfilled, updateTokenReducer);
        builder.addMatcher(authApiV1.endpoints.login.matchRejected, logoutReducer);
        builder.addMatcher(authApiV1.endpoints.refreshToken.matchFulfilled, updateTokenReducer);
        builder.addMatcher(authApiV1.endpoints.refreshToken.matchRejected, logoutReducer);
        // builder.addMatcher(
        //     authApi.endpoints.changePassword.matchFulfilled,
        //     changePasswordReducer,
        // )
    },
});
export { SliceName };
export const { logout, setAuthenticationMode, setAuthenticationMethod, setConfirmationEmail, closeResetForm, setEmailForReset, reset, returnToSignIn, openResetPassword, } = authSlice.actions;
export const authReducer = authSlice.reducer;
