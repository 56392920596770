import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/store/Hooks';
import { ContainerRow } from '@shared/ui/display/Containers';
import { BalancePieChart } from '@entities/current-user';
import { mapWalletForOverview } from '@entities/wallet';
import { ConnectExchangeButton } from '@features/exchange/exchange-connect';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import BalanceChangeDetails from './BalanceChangeDetails';
import BalanceGraph from './BalanceGraph';
const OverallBalanceContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const BalancePieContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: '34px',
}));
const OverallBalance = ({ className = 'undefined', wallets, portfolios, isLoading = false, skipExchangesQuery = false, }) => {
    const { t } = useTranslation();
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const priorityWalletType = (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'CLIENT' ? 'wallet' : 'portfolio';
    const walletsOverview = useMemo(() => wallets.map(wallet => mapWalletForOverview(wallet, portfolios)), [wallets, portfolios]);
    const [activeBalancePie, setActiveBalancePie,] = useState();
    const onBalancePieHover = (balancePie, isHovered) => {
        setActiveBalancePie(isHovered ? balancePie : undefined);
    };
    return (_jsx(SkeletonGroup, { isLoading: isLoading, children: _jsxs(OverallBalanceContainer, { className: className, children: [_jsxs(BalancePieContainer, { children: [_jsx(BalancePieChart, { width: 180, height: 180, wallets: walletsOverview, priorityWalletType: priorityWalletType, isLoading: isLoading, ConnectExchangeComponent: (_jsx(ConnectExchangeButton, { variant: 'plain', color: 'primary', size: 'medium', skipQuery: skipExchangesQuery, children: t('actions.connect', { ns: 'common' }) })), onHover: onBalancePieHover }), _jsx(BalanceChangeDetails, { wallets: walletsOverview, activeWallet: activeBalancePie, isLoading: isLoading })] }), _jsx(BalanceGraph, { isLoading: isLoading })] }) }));
};
export default OverallBalance;
