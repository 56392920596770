import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { BodyMedium } from '@shared/ui/display/Typography';
import { formatUSD } from '@shared/lib/numbers';
const TransactionAmountContainer = styled.div(props => ({
    height: '100%',
    padding: `${props.theme.spacing_sizes.xm}px 0px`,
    boxSizing: 'border-box',
}));
const TransactionAmountCell = ({ cellParams, }) => (_jsx(TransactionAmountContainer, { children: _jsx(BodyMedium, { children: formatUSD(cellParams.value) }) }));
export default TransactionAmountCell;
