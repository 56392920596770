import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { scaleLinear, scaleTime, } from '@visx/scale';
import { extent } from '@visx/vendor/d3-array';
import { Line, LinePath } from '@visx/shape';
import { curveLinear } from '@visx/curve';
import { useCallback, useMemo } from 'react';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { useTheme } from '@mui/material';
import { format } from 'date-fns';
import { AxisBottom, AxisRight } from '@visx/axis';
import { localPoint } from '@visx/event';
import { useTranslation } from 'react-i18next';
import { GridColumns, GridRows } from '@visx/grid';
import { useAppSelector } from '@app/store/Hooks';
import { getLocale } from '@shared/lib/date-time';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { Body } from '@shared/ui/display/Typography';
import { defineDotsTree, defineTooltipPlacement } from './utils/utils';
import PointsTree from './utils/PointsTree';
import { GraphWidth, GraphHeight, CurveWidth, CurveHeight, } from './constants';
import TooltipElement from './TooltipElement';
const CurveContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'graphWidth' && propName !== 'graphHeight' })(props => ({
    position: 'relative',
    gap: props.theme.spacing_sizes.l,
    width: props.graphWidth,
    height: props.graphHeight,
}));
const LegendContainer = styled(ContainerRow)(props => ({
    height: 20,
    paddingLeft: props.theme.spacing_sizes.m,
    gap: props.theme.spacing_sizes.l,
    justifyContent: 'start',
    boxSizing: 'border-box',
}));
const LegendItem = styled(ContainerRow)(() => ({
    width: 'auto',
    alignItems: 'center',
    gap: 5,
}));
const LegendItemCircle = styled('div', { shouldForwardProp: propName => propName !== 'color' })((props) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: props.color,
}));
const Svg = styled.svg(() => ({
    width: CurveWidth,
    height: CurveHeight,
    overflow: 'visible',
}));
const CurveSvgContainer = styled.svg(() => ({
    width: CurveWidth,
    height: CurveHeight,
    overflow: 'visible',
}));
const GridRowsStyled = styled(GridRows)(() => ({
    '& .visx-line': {
        stroke: '#EFF1F3',
        strokeDasharray: '4 4',
    },
}));
const GridColumnsStyled = styled(GridColumns)(() => ({
    '& .visx-line': {
        stroke: '#EFF1F3',
        strokeDasharray: '4 4',
    },
}));
const TooltipDot = styled('rect', { shouldForwardProp: propName => propName !== 'isVisible' && propName !== 'x' && propName !== 'y' })(props => ({
    fill: '#FFFFFF',
    pointerEvents: 'none',
    stroke: props.theme.palette.primary.dark,
    strokeWidth: 2,
    visibility: props.isVisible ? 'visible' : 'hidden',
    transform: `translate(${props.x - 3}px, ${props.y - 7}px)`,
    rx: 3,
    width: 6,
    height: 6,
    borderRadius: 6,
}));
const XAxisContainer = styled.g(() => ({
    transform: `translate(0, calc(${CurveHeight + 15}px))`,
    '& .ProfitCurve__XAxis text': {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '-0.084px',
        fill: '#ABB4BB',
    },
}));
const YAxisContainer = styled.g(() => ({
    transform: `translate(calc(${CurveWidth}px), 0)`,
    '& .ProfitCurve__YAxis text': {
        x: 0,
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '-0.084px',
        fill: '#ABB4BB',
        dx: '100%',
        textAnchor: 'end',
    },
    '& .ProfitCurve__YAxis svg': {
        x: 0,
        width: 56,
    },
    '& .ProfitCurve__YAxis tspan': {
        x: 0,
    },
}));
const TooltipStyled = styled(TooltipWithBounds, { shouldForwardProp: propName => propName !== 'isVisible' && propName !== 'gap' })(props => ({
    '&.visx-tooltip': {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: `${props.theme.spacing_sizes.xs * 0.5}px ${props.theme.spacing_sizes.s}px`,
        visibility: props.isVisible ? 'visible' : 'hidden',
        backgroundColor: 'transparent',
        color: '#fff',
        pointerEvents: 'none',
        fontSize: 14,
        letterSpacing: '-0.084px',
        lineHeight: '20px',
        maxHeight: 236,
    },
}));
const tooltipStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
};
const getCurveColors = (theme) => [
    theme.customColors.graphs.profit_multiple_graph.colors[0],
    theme.customColors.graphs.profit_multiple_graph.colors[1],
];
const ProfitMultipleCurve = ({ data, defaultRange, tooltipProps = {}, isLoading = false, }) => {
    var _a;
    const { t } = useTranslation();
    const theme = useTheme();
    const { language } = useAppSelector((state) => state.app);
    const colors = useMemo(() => getCurveColors(theme), [theme]);
    const getX = (pointCoords) => pointCoords === null || pointCoords === void 0 ? void 0 : pointCoords[0];
    const getY = (pointCoords) => pointCoords[1];
    const scaleYFormat = (ratio) => `${ratio * 100} %`;
    const scaleXFormat = (date) => format(date, 'dd MMM', { locale: getLocale(language || 'en') });
    const getXFromPoint = (d) => d.date;
    const getYFromPoint = (d) => d.value;
    const { tree: dotsTree, points, } = useMemo(() => defineDotsTree(data), [data]);
    const scaleX = useMemo(() => {
        if (points.length) {
            return scaleTime({
                domain: extent(points, getXFromPoint),
                range: [0, CurveWidth],
                nice: false,
            });
        }
        return scaleTime({
            domain: extent(defaultRange, getX),
            range: [0, CurveWidth],
            nice: false,
        });
    }, [points, defaultRange]);
    const scaleY = useMemo(() => {
        if (points.length) {
            return scaleLinear({
                domain: extent(points, getYFromPoint),
                range: [CurveHeight, 0],
                nice: false,
            });
        }
        return scaleLinear({
            domain: [0, 1],
            range: [CurveHeight, 0],
            nice: false,
        });
    }, [points]);
    const { hideTooltip, showTooltip, tooltipLeft, tooltipTop, tooltipOpen, tooltipData, } = useTooltip({});
    const handleTooltipPosition = useCallback((event) => {
        if (points.length) {
            const { x, y } = localPoint(event) || { x: 0, y: 0 };
            const treeResult = [];
            PointsTree.defineClosestElements({
                matchedNodes: treeResult,
                node: dotsTree,
                scaleFns: {
                    scaleX,
                    scaleY,
                },
                searchPoint: [x, y],
            });
            if (treeResult.length) {
                let gap = [];
                const { left, top, xGap, yGap, } = defineTooltipPlacement({
                    elementsNumber: treeResult.length,
                    points: treeResult,
                    scaleFns: {
                        scaleX,
                        scaleY,
                    },
                    curveWidth: CurveWidth,
                    curveHeight: CurveHeight,
                });
                gap = [xGap, yGap];
                showTooltip({
                    tooltipData: {
                        xCoord: scaleX(treeResult[0].date),
                        yCoord: scaleY(treeResult[0].value),
                        elements: treeResult.map((value) => {
                            var _a, _b;
                            return ({
                                date: format(value.date, 'MMMM dd, hh:mm'),
                                profilePublicName: ((_a = tooltipProps[value.key]) === null || _a === void 0 ? void 0 : _a.profilePublicName) || '',
                                profileImage: ((_b = tooltipProps[value.key]) === null || _b === void 0 ? void 0 : _b.profileAvatar) || '',
                                value: parseInt(`${value.value * 100}`, 10),
                                key: value.key,
                            });
                        }),
                        isMultiple: treeResult.length > 1,
                        xGap: (gap === null || gap === void 0 ? void 0 : gap[0]) || 0,
                        yGap: (gap === null || gap === void 0 ? void 0 : gap[1]) || 0,
                        ellipseLeft: scaleX(treeResult[0].date),
                        ellipseTop: scaleY(treeResult[0].value) + 3,
                    },
                    tooltipLeft: left,
                    tooltipTop: top,
                });
            }
            else {
                hideTooltip();
            }
        }
    }, [scaleX, scaleY, hideTooltip, showTooltip, dotsTree, tooltipProps, points]);
    return (_jsxs(CurveContainer, { graphWidth: GraphWidth, graphHeight: GraphHeight, children: [_jsx(LegendContainer, { children: !isLoading && points.length > 0 &&
                    _jsxs(_Fragment, { children: [_jsxs(LegendItem, { children: [_jsx(LegendItemCircle, { color: getCurveColors(theme)[0] }), _jsx(Body, { color: theme.palette.text.secondary, children: t('profile.type.trader') })] }), _jsxs(LegendItem, { children: [_jsx(LegendItemCircle, { color: getCurveColors(theme)[1] }), _jsx(Body, { color: theme.palette.text.secondary, children: t('profile.type.me') })] })] }) }), _jsxs(Svg, { children: [_jsxs(CurveSvgContainer, { onTouchStart: handleTooltipPosition, onTouchMove: handleTooltipPosition, onMouseMove: handleTooltipPosition, onMouseLeave: hideTooltip, viewBox: `0 0 ${CurveWidth} ${CurveHeight}`, children: [_jsx(GridColumnsStyled, { scale: scaleX, height: CurveHeight, numTicks: 4 }), _jsx(GridRowsStyled, { scale: scaleY, width: CurveWidth, numTicks: 6 }), ((_a = Object.keys(data)) === null || _a === void 0 ? void 0 : _a.length) && Object.keys(data)
                                .map((dataKey) => _jsx(LinePath, { x: (d) => scaleX(getX(d)), y: (d) => scaleY(getY(d)), curve: curveLinear, data: data[Number(dataKey)], stroke: colors[Number(dataKey)] }, `curve-${dataKey}`)), !isLoading && !points.length &&
                                _jsx(LinePath, { x: (d) => scaleX(getX(d)), y: (d) => scaleY(getY(d)), curve: curveLinear, data: defaultRange, stroke: '#ABB4BB', strokeWidth: 2 }), _jsx(TooltipDot, { isVisible: tooltipOpen, x: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.ellipseLeft) || 0, y: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.ellipseTop) || 0 }), _jsx(Line, { visibility: tooltipOpen ? 'visible' : 'hidden', from: { x: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, y: 0 }, to: { x: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, y: CurveHeight }, stroke: theme.palette.primary.dark, strokeDasharray: '4 4', pointerEvents: 'none' })] }), !isLoading &&
                        _jsx(YAxisContainer, { children: _jsx(AxisRight, { scale: scaleY, tickFormat: (value) => scaleYFormat(value), numTicks: 7, hideAxisLine: true, hideTicks: true }) }), !isLoading &&
                        _jsx(XAxisContainer, { children: _jsx(AxisBottom, { axisClassName: 'ProfitCurve__XAxis', scale: scaleX, tickFormat: (date) => scaleXFormat(date), numTicks: 5, hideAxisLine: true, hideTicks: true }) })] }), _jsx(TooltipStyled, { isVisible: tooltipOpen, style: Object.assign(Object.assign({}, tooltipStyles), { transform: `translate(${tooltipLeft || 0}px, ${tooltipTop || 0}px)` }), gap: `${(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.yGap) || 0}px ${(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xGap) || 0}px`, children: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.elements.map((element) => (_jsx(TooltipElement, { borderColor: colors[element.key] || 'transparent', date: element.date, amount: element.value, profileImage: element.profileImage, profileName: element.profilePublicName }, element.key))) })] }));
};
export default ProfitMultipleCurve;
