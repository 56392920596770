import restApiAuthorized from '@app/store/api/restApiAuthorized';
const subscriptionApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        getSubscriptionPlans: builder.query({
            query: () => ({
                url: '/api/v1/subscriptions_price',
                method: 'GET',
            }),
            providesTags: ['PlatformAvailableSubscriptions'],
            // by default we want to sort by price asc
            transformResponse: (response) => response
                .sort((a, b) => {
                const aPrice = Number(a.unitAmount) || 0;
                const bPrice = Number(b.unitAmount) || 0;
                return aPrice - bPrice;
            })
        }),
        getCurrentSubscriptions: builder.query({
            query: ({ userId }) => ({
                url: `/api/v1/user/${userId}/subscription`,
                method: 'GET',
            }),
            providesTags: ['PlatformCurrentSubscriptions'],
        }),
        createSubscriptionWithCheckout: builder.mutation({
            query: ({ userId, priceId }) => ({
                url: `/api/v1/user/${userId}/subscription_checkout`,
                method: 'POST',
                body: {
                    priceId,
                    type: 'PORTAL',
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['PlatformCurrentSubscriptions', 'PlatformAvailableSubscriptions'],
        }),
        modifySubscription: builder.mutation({
            query: ({ userId, subscriptionId, priceId }) => ({
                url: `/api/v1/user/${userId}/subscription/${subscriptionId}/modify`,
                method: 'PATCH',
                body: {
                    priceId,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['PlatformCurrentSubscriptions', 'PlatformAvailableSubscriptions'],
        }),
        disableSubscription: builder.mutation({
            query: ({ userId, subscriptionId }) => ({
                url: `/api/v1/user/${userId}/subscription/${subscriptionId}/disable`,
                method: 'PATCH',
            }),
            invalidatesTags: ['PlatformCurrentSubscriptions', 'PlatformAvailableSubscriptions'],
        }),
    })
});
export default subscriptionApi;
export const { useGetSubscriptionPlansQuery, useGetCurrentSubscriptionsQuery, useLazyGetSubscriptionPlansQuery, useCreateSubscriptionWithCheckoutMutation, useModifySubscriptionMutation, useDisableSubscriptionMutation, } = subscriptionApi;
