import PointsTree from './PointsTree';
import { TooltipElementHeight, TooltipElementWidth, TooltipElementsLargeXGap, TooltipElementsSmallXGap, TooltipElementsYGap, } from '../constants';
export const defineDotsTree = (data) => {
    const dataKeys = Object.keys(data);
    let curveKeyPointer = 0;
    let pointPointer = 0;
    const points = [];
    while (curveKeyPointer < dataKeys.length) {
        if (data[curveKeyPointer][pointPointer]) {
            points.push({
                date: new Date(data[curveKeyPointer][pointPointer][0]),
                key: curveKeyPointer,
                value: data[curveKeyPointer][pointPointer][1]
            });
        }
        if (pointPointer === data[curveKeyPointer].length - 1) {
            curveKeyPointer += 1;
            pointPointer = 0;
        }
        else {
            pointPointer += 1;
        }
    }
    points.sort((a, b) => a.date.getTime() - b.date.getTime());
    const tree = PointsTree.buildTree(points, null);
    return { tree, points };
};
const defineYEdgePoints = (points) => {
    let min = +Infinity;
    let max = -Infinity;
    points.forEach((point) => {
        min = Math.min(min, point.value);
        max = Math.max(max, point.value);
    });
    return { min, max };
};
export const defineTooltipPlacement = ({ elementsNumber, scaleFns, points, curveHeight, curveWidth, }) => {
    let tooltipTop;
    let tooltipLeft;
    let elementsXGap;
    const { scaleX, scaleY } = scaleFns;
    const { min, max } = defineYEdgePoints(points);
    const tooltipContainerHeight = (TooltipElementHeight * elementsNumber) + (TooltipElementsYGap * (elementsNumber - 1));
    // along x
    const shouldPlaceAfterCursor = scaleX(points[0].date) < TooltipElementWidth;
    const shouldPlaceBeforeCursor = scaleX(points[0].date) + TooltipElementWidth > curveWidth;
    // along y
    const shouldPlaceAboveCursor = ((scaleY(min) + scaleY(max)) / 2 + tooltipContainerHeight) > curveHeight;
    const shouldPlaceUnderCursor = ((scaleY(min) + scaleY(max)) / 2 - (tooltipContainerHeight / 2)) < 0;
    if (shouldPlaceAfterCursor) {
        tooltipLeft = scaleX(points[0].date) + 9;
        elementsXGap = TooltipElementsSmallXGap;
    }
    else if (shouldPlaceBeforeCursor) {
        tooltipLeft = scaleX(points[0].date) - TooltipElementWidth - 9;
        elementsXGap = TooltipElementsSmallXGap;
    }
    else if (elementsNumber > 6) {
        tooltipLeft = scaleX(points[0].date) - (TooltipElementWidth / 2);
        elementsXGap = TooltipElementsLargeXGap * 2;
    }
    else {
        tooltipLeft = scaleX(points[0].date) + 9;
        elementsXGap = TooltipElementsSmallXGap;
    }
    if (shouldPlaceAboveCursor) {
        tooltipTop = curveHeight - tooltipContainerHeight;
    }
    else if (shouldPlaceUnderCursor) {
        tooltipTop = scaleY(max);
    }
    else {
        tooltipTop = scaleY(max) - (TooltipElementsYGap / 2);
    }
    return {
        top: tooltipTop,
        left: tooltipLeft,
        xGap: elementsXGap,
        yGap: TooltipElementsYGap,
    };
};
