import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isPropValid from '@emotion/is-prop-valid';
import { TitleSecondary } from '@shared/ui/display/Typography';
import { exitSubscriptionProcess, mapPortfolioFromResponse, PortfolioHeadline, prefillSubscriptionData, setPortfolioForSubscription, } from '@entities/portfolio';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { IsDefined } from '@utils/js-ts';
import { useGetPortfolioQuery } from '@shared/api/portfolio';
import { ContainerColumn, } from '@shared/ui/display/Containers';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import { getWalletFromExchangesList } from '@entities/exchange/lib';
import BidToQueueGeneralInfo from './BidToQueueGeneralInfo';
import BidToQueueStepper from './bid-to-queue-stepper/BidToQueueStepper';
const BidToQueueContainer = styled(ContainerColumn)(props => ({
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: props.theme.spacing_sizes.l,
    width: '100%',
    height: 'auto',
    maxWidth: 423,
}));
const BidToQueueInner = styled(ContainerColumn)(props => ({
    alignItems: 'center',
    gap: props.theme.spacing_sizes.l,
}));
const TitleContainer = styled('div', { shouldForwardProp: propName => isPropValid(propName) })(props => ({
    width: props.isUserSubscribed ? '100%' : 'auto',
    display: 'inline-flex',
    alignItems: 'center',
}));
const Title = styled(TitleSecondary)(() => ({
    lineHeight: '22px',
}));
const BidToQueue = ({ portfolioId, userSubscription = undefined, }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isUserSubscribed } = useAppSelector(state => state.portfolioSubscription);
    const { currentData: portfolio, isLoading, isFetching, isError } = useGetPortfolioQuery({ portfolioId });
    const mappedPortfolio = useMemo(() => IsDefined(portfolio) ? mapPortfolioFromResponse(portfolio) : undefined, [portfolio]);
    const { data: exchangeAccounts = [], } = useGetExchangeAccountsQuery(undefined);
    const selectedWallet = useMemo(() => {
        if (exchangeAccounts.length && IsDefined(userSubscription)) {
            const predefinedWallet = getWalletFromExchangesList(exchangeAccounts, userSubscription.subscriberWalletId);
            return predefinedWallet;
        }
        return undefined;
    }, [exchangeAccounts, userSubscription]);
    useEffect(() => {
        if (!isFetching && !isError && IsDefined(portfolio) && !IsDefined(userSubscription)) {
            // TODO-FIX: update types & interactions with subscription
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(setPortfolioForSubscription(mapPortfolioFromResponse(portfolio)));
        }
    }, [portfolio, isFetching, isError, dispatch, userSubscription]);
    useEffect(() => () => {
        dispatch(exitSubscriptionProcess());
    }, [dispatch]);
    useEffect(() => {
        if (IsDefined(portfolio) && IsDefined(userSubscription) && IsDefined(selectedWallet)) {
            dispatch(prefillSubscriptionData({
                portfolio: mapPortfolioFromResponse(portfolio),
                subscriptionData: userSubscription,
                walletTotalAssetsUsdt: selectedWallet.totalAssetsUsdt,
            }));
        }
    }, [dispatch, portfolio, userSubscription, selectedWallet]);
    return ((isLoading || !isError) && (_jsx(BidToQueueContainer, { children: _jsx(SkeletonGroup, { isLoading: isLoading, children: _jsxs(BidToQueueInner, { children: [_jsx(TitleContainer, { isUserSubscribed: isUserSubscribed, children: _jsx(WithSkeleton, { isLoading: isLoading, width: 300, height: 22, children: isUserSubscribed ?
                                _jsx(PortfolioHeadline, { portfolio: mappedPortfolio, showAvatar: false }) :
                                _jsx(Title, { children: t('portfolio.subscription.title') }) }) }), _jsx(BidToQueueGeneralInfo, { isLoading: isLoading, subscription: userSubscription }), _jsx(BidToQueueStepper, { isLoading: isLoading })] }) }) })));
};
export default BidToQueue;
