import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useState } from 'react';
import CollapsePanel from '@shared/ui/layout/CollapsePanel';
import { ContainerRow } from '@shared/ui/display/Containers';
import { ReactComponent as ChevronIcon } from '@icons/wolfkit-light/chevron-small-down-light.svg';
import Button from '@shared/ui/buttons/Button';
const UnsupportedWalletsContainer = styled.div(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 9,
}));
const UnsupportedWalletsHeader = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ABB4BB',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: '16px',
    gap: 19,
    margin: `${props.theme.spacing_sizes.l}px 0`,
}));
const UnsupportedListDivider = styled.span(props => ({
    height: 1,
    backgroundColor: props.theme.customColors.input.border,
    width: '100%',
}));
const ButtonStyled = styled(Button, { shouldForwardProp: propName => propName !== 'isOpen' })((props => ({
    padding: 0,
    whiteSpace: 'nowrap',
    gap: props.theme.spacing_sizes.xs * 1.25,
    color: props.theme.customColors.input.borderHover,
    fontWeight: 500,
    minHeight: 'unset',
    lineHeight: '24px',
    svg: {
        transform: props.isOpen ? 'none' : 'rotate(180deg)',
    },
})));
const ListContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.l,
}));
const UnsupportedWalletsList = ({ children, count, }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => setIsOpen((prev) => !prev);
    return (_jsxs(UnsupportedWalletsContainer, { children: [_jsxs(UnsupportedWalletsHeader, { children: [_jsx(UnsupportedListDivider, {}), _jsx(ButtonStyled, { variant: 'plain', onClick: handleToggle, endIcon: ChevronIcon, isOpen: isOpen, children: `${t('overview.exchange_wallets.hidden_wallets')} (${count})` }), _jsx(UnsupportedListDivider, {})] }), _jsx(CollapsePanel, { isOpen: isOpen, children: _jsx(ListContainer, { children: children }) })] }));
};
export default UnsupportedWalletsList;
