import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { ContainerColumn } from '@shared/ui/display/Containers';
import Input from '@shared/ui/inputs/Input';
import UserLabel from './UserLabel';
const FormInnerColumn = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.s,
    maxWidth: 395,
}));
const UserLabelContainer = styled.div(props => ({
    paddingTop: props.theme.spacing_sizes.s * 5,
}));
const AuthorizedStateInputs = ({ nickname, publicName, imageUrl = undefined, control, }) => {
    const { t } = useTranslation();
    return (_jsxs(FormInnerColumn, { children: [_jsx(UserLabelContainer, { children: _jsx(UserLabel, { nickname: nickname, publicName: publicName, imageUrl: imageUrl }) }), _jsx(Controller, { name: 'topic', control: control, render: ({ field, fieldState }) => {
                    var _a;
                    return (_jsx(Input, Object.assign({}, field, { label: t('support.send_request.topic.title'), placeholder: t('support.send_request.topic.placeholder'), InputLabelProps: { shrink: true }, notificationStatus: ((_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message) ? 'error' : undefined, maxCount: 40 })));
                } })] }));
};
export default AuthorizedStateInputs;
