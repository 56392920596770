import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays, differenceInHours, differenceInMinutes, format, formatDuration, intervalToDuration, } from 'date-fns';
import styled from '@emotion/styled';
import { Body, BodySmall } from '@shared/ui/display/Typography';
import QuestionMarkTooltip from '@shared/ui/tooltips/QuestionMarkTooltip';
import { IsDefined } from '@utils/js-ts';
const Container = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const DateContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
const DateFormat = 'yyyy-MM-dd hh:mm:ss';
const formatDistance = (t) => (token, count) => {
    let result = '';
    switch (token) {
        case 'xDays':
            result = t('date_time.duration.days', { count, ns: 'common' });
            break;
        case 'xHours':
            result = t('date_time.duration.hours', { count, ns: 'common' });
            break;
        case 'xMinutes':
            result = t('date_time.duration.minutes', { count, ns: 'common' });
            break;
        case 'xSeconds':
            result = t('date_time.duration.seconds', { count, ns: 'common' });
            break;
        default:
            result = '';
    }
    return result;
};
const getDuration = (start, end) => {
    const duration = intervalToDuration({ start, end });
    const days = differenceInDays(end, start);
    if (days > 0) {
        return {
            days,
            hours: duration.hours,
        };
    }
    const hours = differenceInHours(end, start);
    if (hours > 0) {
        return {
            hours,
            minutes: duration.minutes,
        };
    }
    const minutes = differenceInMinutes(end, start);
    if (minutes > 0) {
        return {
            minutes,
            seconds: duration.seconds,
        };
    }
    return {};
};
const DateTimeDuration = ({ start, end, }) => {
    const { t } = useTranslation();
    const startDate = useMemo(() => {
        try {
            return new Date(start);
        }
        catch (ex) {
            return undefined;
        }
    }, [start]);
    const endDate = useMemo(() => {
        try {
            return new Date(end);
        }
        catch (ex) {
            return undefined;
        }
    }, [end]);
    const startDateFormatted = useMemo(() => (IsDefined(startDate) ? format(startDate, DateFormat) : ''), [startDate]);
    const endDateFormatted = useMemo(() => (IsDefined(endDate) ? format(endDate, DateFormat) : ''), [endDate]);
    const durationLocale = useMemo(() => ({
        formatDistance: formatDistance(t),
    }), [t]);
    const duration = useMemo(() => {
        if (!IsDefined(startDate) || !IsDefined(endDate)) {
            return '';
        }
        try {
            const d = getDuration(startDate, endDate);
            return formatDuration(d, { locale: durationLocale });
        }
        catch (ex) {
            return '';
        }
    }, [startDate, endDate, durationLocale]);
    return (_jsxs(Container, { children: [_jsx(Body, { children: duration }), _jsx(QuestionMarkTooltip, { title: (_jsxs(DateContainer, { children: [_jsx(BodySmall, { children: startDateFormatted }), _jsx(BodySmall, { children: endDateFormatted })] })), placement: 'top-start', arrow: true })] }));
};
export default DateTimeDuration;
