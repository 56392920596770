var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState, useRef, } from 'react';
import MuiInputLabel from '@mui/material/InputLabel';
import MuiSelect from '@mui/material/Select';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { BodyMediumSemiBold } from '@shared/ui/display/Typography';
import { ReactComponent as CaretDownIcon } from '@icons/wolfkit-light/caret-down-light.svg';
import Icon from '@shared/ui/icons/Icon';
import InputLabel from '@shared/ui/inputs/InputLabel';
import { getSelectMenuStyles, getSelectStyles } from './styles';
const SelectCaretDownIcon = ({ className = undefined }) => (_jsx(Icon, { className: className, IconComponent: CaretDownIcon }));
const NotificationText = styled(BodyMediumSemiBold, { shouldForwardProp: propName => propName !== 'notificationStatus' })(props => ({
    color: props.notificationStatus === 'error' ?
        props.theme.palette.error.main : props.theme.palette.text.primary,
    fontSize: 14,
    marginTop: props.theme.spacing_sizes.xs,
    lineHeight: '18px',
}));
const SelectContainer = styled.div(props => {
    var _a, _b, _c, _d, _e;
    return ({
        width: '100%',
        '& label.MuiInputLabel-root': {
            position: 'relative',
            top: 0,
            left: 0,
            transform: 'none',
            marginBottom: props.hasLabel ?
                (_a = getSelectStyles(props.theme).variants[props.selectVariant].size) === null || _a === void 0 ? void 0 : _a[props.size].labelMarginBottom :
                0,
            fontWeight: 500,
            fontSize: (_b = getSelectStyles(props.theme).variants[props.selectVariant].size) === null || _b === void 0 ? void 0 : _b[props.size].labelFontSize,
            lineHeight: (_c = getSelectStyles(props.theme).variants[props.selectVariant].size) === null || _c === void 0 ? void 0 : _c[props.size].labelLineHeight,
            color: props.theme.palette.text.primary,
            display: 'flex',
            justifyContent: 'space-between',
        },
        '& label.MuiInputLabel-root.Mui-disabled': {
            position: 'relative',
            top: 0,
            left: 0,
            transform: 'none',
            marginBottom: props.hasLabel ?
                (_d = getSelectStyles(props.theme).variants[props.selectVariant].size) === null || _d === void 0 ? void 0 : _d[props.size].labelMarginBottom :
                0,
            fontWeight: 500,
            fontSize: (_e = getSelectStyles(props.theme).variants[props.selectVariant].size) === null || _e === void 0 ? void 0 : _e[props.size].labelFontSize,
            color: props.theme.palette.text.primary,
        },
    });
});
const MuiSelectStyled = styled(MuiSelect, {
    shouldForwardProp: (propName) => propName !== 'filled' &&
        propName !== 'selectVariant' &&
        propName !== 'notificationStatus',
})(props => {
    var _a, _b, _c, _d, _e;
    return ({
        width: '100%',
        backgroundColor: getSelectStyles(props.theme).variants[props.selectVariant].backgroundColor,
        '& .MuiSelect-select': {
            color: getSelectStyles(props.theme).variants[props.selectVariant].selectInnerColor,
            padding: (_a = getSelectStyles(props.theme).variants[props.selectVariant].
                size) === null || _a === void 0 ? void 0 : _a[props.size].padding,
        },
        '& .MuiSelect-select.Mui-disabled': {
            backgroundColor: getSelectStyles(props.theme).variants[props.selectVariant]
                .disabledBackgroundColor,
            WebkitTextFillColor: 'inherit',
        },
        '& .MuiSelect-icon': {
            top: (_b = getSelectStyles(props.theme).variants[props.selectVariant].
                size) === null || _b === void 0 ? void 0 : _b[props.size].endAdornmentTop,
            right: (_c = getSelectStyles(props.theme).variants[props.selectVariant].
                size) === null || _c === void 0 ? void 0 : _c[props.size].endAdornmentRight,
        },
        '& .MuiSelect-icon svg': {
            width: (_d = getSelectStyles(props.theme).variants[props.selectVariant].
                size) === null || _d === void 0 ? void 0 : _d[props.size].iconSize,
            height: (_e = getSelectStyles(props.theme).variants[props.selectVariant].
                size) === null || _e === void 0 ? void 0 : _e[props.size].iconSize,
        },
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            border: props.notificationStatus === 'error' ?
                getSelectStyles(props.theme).variants[props.selectVariant].borderError :
                getSelectStyles(props.theme).variants[props.selectVariant].border,
        },
        '&:hover': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
                border: props.notificationStatus === 'error' ?
                    getSelectStyles(props.theme).variants[props.selectVariant].borderError :
                    getSelectStyles(props.theme).variants[props.selectVariant].borderHover,
            },
        },
        '&.Mui-focused': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
                border: props.notificationStatus === 'error' ?
                    getSelectStyles(props.theme).variants[props.selectVariant].borderError :
                    getSelectStyles(props.theme).variants[props.selectVariant].borderActive,
                borderWidth: 1,
                boxShadow: props.notificationStatus === 'error' ?
                    '0px 0px 0px 3px rgba(229, 72, 77, 0.20)' :
                    '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
            },
        },
    });
});
const MenuStyles = (theme, selectVariant, highlightSelected) => ({
    '&.MuiPaper-root': {
        marginTop: getSelectMenuStyles(theme).variants[selectVariant].menuMarginTop,
        border: getSelectMenuStyles(theme).variants[selectVariant].menuBorder,
        boxShadow: getSelectMenuStyles(theme).variants[selectVariant].menuBoxShadow,
        '&::-webkit-scrollbar': {
            borderRadius: 4,
            width: 6,
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#C1C8CD',
            width: 4,
            borderRadius: 4,
        },
        '& .MuiList-root': {
            padding: 0,
        },
        '& li.MuiMenuItem-root': {
            padding: getSelectMenuStyles(theme).variants[selectVariant].padding,
            borderLeft: getSelectMenuStyles(theme).variants[selectVariant].itemBorderLeft,
            '&:hover': {
                backgroundColor: getSelectMenuStyles(theme).variants[selectVariant]
                    .itemHoverBackground,
            },
            '&.Mui-selected:hover': {
                backgroundColor: getSelectMenuStyles(theme).variants[selectVariant]
                    .selectedItemHoverBackground,
            },
            '&:active': {
                backgroundColor: getSelectMenuStyles(theme).variants[selectVariant]
                    .itemActiveBackground,
            },
            '&.Mui-selected': {
                backgroundColor: highlightSelected ?
                    getSelectMenuStyles(theme)
                        .variants[selectVariant]
                        .selectedItemBackgroundHighlited :
                    getSelectMenuStyles(theme)
                        .variants[selectVariant]
                        .itemActiveBackground,
                borderLeft: getSelectMenuStyles(theme).variants[selectVariant]
                    .selectedItemBorderLeft,
            },
        },
    },
});
const Select = (_a) => {
    var { size = 'small', selectVariant = 'outlined', highlightSelected = false, label = undefined, onBlur = undefined, required = undefined, disabled = undefined, notification = undefined, notificationStatus = undefined } = _a, props = __rest(_a, ["size", "selectVariant", "highlightSelected", "label", "onBlur", "required", "disabled", "notification", "notificationStatus"]);
    const theme = useTheme();
    const menuRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [displayNode, setDisplayNode] = useState(null);
    const [focused, setIsFocused] = useState(false);
    const handleBlur = (ev) => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(ev);
        }
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    // this hack is equal for select in mui
    const getSelectRef = useCallback((node) => {
        if (node) {
            setDisplayNode(node);
        }
    }, []);
    const menuWidth = useMemo(() => {
        if (displayNode) {
            return displayNode.clientWidth;
        }
        return undefined;
    }, [displayNode]);
    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };
    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };
    return (_jsxs(SelectContainer, { selectVariant: selectVariant, hasLabel: Boolean(typeof label !== 'undefined'), size: size, children: [label ? (_jsx(MuiInputLabel, { disabled: disabled, shrink: true, children: _jsx(InputLabel, { focused: focused, label: label, size: size, required: required }) })) : undefined, _jsx(MuiSelectStyled, Object.assign({}, props, { ref: getSelectRef, size: size, selectVariant: selectVariant, variant: 'outlined', IconComponent: SelectCaretDownIcon, open: isMenuOpen, onOpen: handleMenuOpen, onClose: handleMenuClose, MenuProps: Object.assign(Object.assign({}, (props.MenuProps || {})), { ref: menuRef, transitionDuration: 500, slotProps: {
                        paper: {
                            sx: Object.assign(Object.assign({}, MenuStyles(theme, selectVariant, highlightSelected)), { width: '100%', maxWidth: menuWidth, 
                                // here we subtract 24px as 4 * marginTop - coudn't figure out why only 4x gives us a regular marginTop at the bottom
                                maxHeight: `calc(100% - ${((displayNode === null || displayNode === void 0 ? void 0 : displayNode.getBoundingClientRect().bottom) || 0) + 24}px)`, 
                                // settings up the origin of default Grow transition
                                transformOrigin: 'top' }),
                        },
                    } }), error: notificationStatus === 'error', onBlur: handleBlur, onFocus: handleFocus, disabled: disabled })), (typeof notification !== 'undefined') && (_jsx(NotificationText, { notificationStatus: notificationStatus, children: notification }))] }));
};
export default Select;
