var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '@shared/ui/inputs/Input';
import { BodyMediumSemiBold } from '@shared/ui/display/Typography';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { resetPasswordValidationSchema } from '@pages/auth/model/reset-password';
import useAuth from '@pages/auth/lib/useAuth';
import { useWizard } from '@shared/ui/misc/Wizard';
import { ResetPasswordVariants } from '@pages/auth/lib/constants';
import { SubmitButton } from '../../../../../components/shared';
const FindAccountContainer = styled(ContainerColumn)((props) => ({
    height: 'auto',
    maxWidth: '458px',
    gap: props.theme.spacing_sizes.m,
}));
const Message = styled(BodyMediumSemiBold)(() => ({
    lineHeight: '24px',
    letterSpacing: '-0.176px'
}));
const ResetPasswordForm = () => {
    const { t } = useTranslation();
    const { goNext } = useWizard();
    const { emailForReset, obtainResetLink, } = useAuth();
    const { control, setError, handleSubmit, } = useForm({
        defaultValues: { email: emailForReset || '' },
        resolver: yupResolver(resetPasswordValidationSchema(t)),
    });
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield obtainResetLink(ResetPasswordVariants.EMAIL, values.email);
        if (res) {
            goNext();
        }
        else {
            setError('email', {
                message: t('auth.reset_password.steps.reset_password.account_not_found_error', { ns: 'common' })
            });
        }
    });
    return (_jsx("form", { onSubmit: handleSubmit(onSubmit), noValidate: true, children: _jsxs(FindAccountContainer, { children: [_jsx(Message, { children: t('auth.reset_password.steps.reset_password.input_label', { ns: 'common' }) }), _jsx(Controller, { name: 'email', control: control, render: ({ field, fieldState }) => {
                        var _a, _b;
                        return (_jsx(Input, { type: 'email', ref: field.ref, value: field.value, onChange: field.onChange, InputLabelProps: { shrink: true }, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, autoComplete: 'email', placeholder: t('auth.reset_password.steps.reset_password.input_placeholder', { ns: 'common' }) }));
                    } }), _jsx(SubmitButton, { type: 'submit', size: 'large', fullWidth: true, children: t('auth.sign_up.steps.credentials.email.submit_btn_text', { ns: 'common' }) })] }) }));
};
export default ResetPasswordForm;
