import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import LazyImgLoader from '@shared/ui/misc/LazyImgLoader';
import { ContainerColumn, NarrowSection } from '@shared/ui/display/Containers';
import CurrencyTrendsPanel from '../CurrencyTrendsPanel';
import TopPortfolios from '../TopPortfolios';
const Container = styled(ContainerColumn)(() => ({
    position: 'relative',
    alignItems: 'center',
}));
const ContentContainer = styled(NarrowSection.withComponent(ContainerColumn))((props) => ({
    height: 'auto',
    // 140px
    gap: `${props.theme.spacing_sizes.xs * 17.5}px 0`,
    paddingTop: '38px',
    zIndex: 1,
}));
const BackgroundImageContainer = styled.div((props) => ({
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    maxHeight: `${props.theme.spacing_sizes.m * 100}px`,
    backgroundImage: `linear-gradient(to bottom, transparent 75%, #F8F9FA), url(${props.url || ''})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
}));
const backgroundImageUrl = '/marketplace-image.png';
const BackgroundImage = () => (_jsx(LazyImgLoader, { url: backgroundImageUrl, children: _jsx(BackgroundImageContainer, { url: backgroundImageUrl }) }));
const Marketplace = () => (_jsxs(Container, { children: [_jsx(BackgroundImage, {}), _jsxs(ContentContainer, { children: [_jsx(CurrencyTrendsPanel, {}), _jsx(TopPortfolios, {})] })] }));
export default Marketplace;
