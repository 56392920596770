import styled from '@emotion/styled';
import { ContainerColumn } from '@shared/ui/display/Containers';
const SidebarContainer = styled(ContainerColumn)((props) => ({
    height: '100%',
    width: 420,
    maxWidth: 420,
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
}));
export default SidebarContainer;
