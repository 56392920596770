import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
const LogoIcon = ({ variant = 'primary', }) => {
    const theme = useTheme();
    const backgroundColor = useMemo(() => {
        if (variant === 'inverted') {
            return '#6E8AE6'; // TODO: move color in theme
        }
        return theme.customColors.portfolio.hover;
    }, [variant, theme]);
    return (_jsxs("svg", { width: '40', height: '40', viewBox: '0 0 40 40', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: [_jsx("rect", { width: '40', height: '40', rx: '20', fill: backgroundColor }), _jsx("rect", { x: '16.1471', y: '8.81569', width: '9.01236', height: '9.01236', transform: 'rotate(45 16.1471 8.81569)', stroke: 'white', strokeWidth: '1.36158' }), _jsx("rect", { x: '24.3998', y: '8.81569', width: '9.01236', height: '9.01236', transform: 'rotate(45 24.3998 8.81569)', stroke: 'white', strokeWidth: '1.36158' }), _jsx("rect", { x: '24.8585', y: '17.5243', width: '9.01236', height: '9.01236', transform: 'rotate(45 24.8585 17.5243)', stroke: 'white', strokeWidth: '1.36158' }), _jsx("rect", { x: '15.6884', y: '17.5243', width: '9.01236', height: '9.01236', transform: 'rotate(45 15.6884 17.5243)', stroke: 'white', strokeWidth: '1.36158' }), _jsx("rect", { x: '20.1934', y: '11.8965', width: '2.17681', height: '1.36341', transform: 'rotate(45 20.1934 11.8965)', fill: 'white' }), _jsx("rect", { x: '20.2161', y: '24.6675', width: '1.53199', height: '1.36001', transform: 'rotate(45 20.2161 24.6675)', fill: 'white' }), _jsx("rect", { x: '17.9382', y: '18.8127', width: '1.36309', height: '1.8417', transform: 'rotate(45 17.9382 18.8127)', fill: 'white' }), _jsx("rect", { x: '26.647', y: '18.3513', width: '1.36086', height: '1.38146', transform: 'rotate(45 26.647 18.3513)', fill: 'white' })] }));
};
export default LogoIcon;
