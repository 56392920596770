import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useState } from 'react';
import { useAppSelector } from '@app/store/Hooks';
import { useLazyGetProfileByIdQuery } from '@shared/api/profile';
import { ContainerColumn } from '@shared/ui/display/Containers';
import Tabs from '@shared/ui/navigation/Tabs';
import PORTFOLIO_INFO_TABS from '../constants';
import PortfolioOwnerDescription from './PortfolioOwnerDescription';
const PortfolioInfoContainer = styled(ContainerColumn)();
const ProfilePreviewContainer = styled.div(props => ({
    padding: `${props.theme.spacing_sizes.l}px ${props.theme.spacing_sizes.l}px 0`,
}));
const PortfolioInfo = ({ handleClose }) => {
    const [getProfileReq, { data: profile }] = useLazyGetProfileByIdQuery();
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const [activeTab, setActiveTab] = useState('overview');
    return (_jsxs(PortfolioInfoContainer, { children: [currentUserProfile && (_jsx(PortfolioOwnerDescription, { profile: currentUserProfile, handleClose: handleClose })), _jsx(Tabs, { tabs: PORTFOLIO_INFO_TABS, activeTab: activeTab, onChange: setActiveTab })] }));
};
export default PortfolioInfo;
