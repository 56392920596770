import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TraderIcon } from '@icons/wolfkit-light/crown-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { Body } from '@shared/ui/display/Typography';
import Badge from '@shared/ui/badges/Badge';
const TraderBadge = styled(Badge)(() => ({
    padding: '2px 5px',
}));
const InnerText = styled(Body)(() => ({
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 500,
}));
const RenderProfileTraderBadge = ({ cellParams, }) => {
    const { t } = useTranslation();
    return cellParams.rowData.type === 'TRADER' && (_jsx(TraderBadge, { variant: 'tinted', color: 'warning', startAdornment: (_jsx(Icon, { size: 14, IconComponent: TraderIcon })), children: _jsx(InnerText, { children: t('profile.type.trader') }) }));
};
export default RenderProfileTraderBadge;
