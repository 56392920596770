import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { alignToCenter, ContainerColumn } from '@shared/ui/display/Containers';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import ResetButton from '@shared/ui/buttons/ResetButton';
import Logo from '@shared/ui/display/Logo';
import { LinkContainer as LinkContainerComponent, Title as TitleComponent, } from '../shared';
const Container = styled(alignToCenter(ContainerColumn))(() => ({
    height: 'auto',
}));
const FormContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    height: 'inherit',
    flex: 1,
    paddingTop: 62,
}));
const NavContainer = styled(Container)(props => ({
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '0 100px',
    justifyContent: props.showNavigation ? 'space-between' : 'center',
    height: 40,
}));
const ContentContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'justifyContentToCenter' })((props) => (Object.assign({}, props.justifyContentToCenter ? ({
    justifyContent: 'center',
}) : ({
    marginTop: 110,
}))));
const InnerContainer = styled(Container)(() => ({
    justifyContent: 'start',
}));
const LinkContainer = styled(LinkContainerComponent)(() => ({
    display: 'inline-flex',
    marginTop: 124,
    marginBottom: 24
}));
const Title = styled(TitleComponent, { shouldForwardProp: propName => propName !== 'titleSpacing' })(props => ({
    textAlign: 'center',
    marginTop: 0,
    margin: props.titleSpacing ? props.titleSpacing : `0 0 ${props.theme.spacing_sizes.xs * 5}px 0`,
}));
const ButtonContainer = styled.div(() => ({
    display: 'flex',
    justifyContent: 'end',
    // added to make buttons equal in width so the logo would be displayed at center
    minWidth: 84,
}));
const FormWrapper = ({ titleText = undefined, renderInnerForm = undefined, renderLinks = undefined, showNavigation = false, justifyContentToCenter = false, backLinkProps = undefined, handleReset = undefined, titleSpacing = undefined, }) => {
    const theme = useTheme();
    return (_jsxs(FormContainer, { children: [_jsxs(NavContainer, { showNavigation: showNavigation, children: [showNavigation ? (_jsx(ButtonContainer, { children: _jsx(BackLinkButton, { onClick: backLinkProps === null || backLinkProps === void 0 ? void 0 : backLinkProps.onClick, children: (backLinkProps === null || backLinkProps === void 0 ? void 0 : backLinkProps.text) || '' }) })) : null, _jsx(Logo, {}), showNavigation ? (_jsx(ButtonContainer, { children: _jsx(ResetButton, { iconPadding: theme.spacing_sizes.s, iconHeight: 24, iconWidth: 24, onClick: handleReset }) })) :
                        null] }), _jsxs(ContentContainer, { justifyContentToCenter: justifyContentToCenter, children: [_jsxs(InnerContainer, { children: [_jsx(Title, { titleSpacing: titleSpacing, children: titleText }), renderInnerForm || null] }), _jsx(LinkContainer, { children: renderLinks || null })] })] }));
};
export default FormWrapper;
