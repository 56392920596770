import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material';
import { useAppSelector } from '@app/store/Hooks';
import { ContainerColumn, NarrowSection } from '@shared/ui/display/Containers';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import Tabs from '@shared/ui/navigation/Tabs';
import { matchPathToHomeTab } from '../../lib/utils';
import getHomeTabs from '../../lib/constants';
const Container = styled(ContainerColumn)(props => ({
    height: 'auto',
    alignItems: 'center',
    backgroundColor: props.theme.customColors.surface.surface,
    gap: props.theme.spacing_sizes.l,
    paddingTop: props.theme.spacing_sizes.s * 5,
}));
const NarrowContainer = NarrowSection.withComponent(ContainerColumn);
const HomePage = () => {
    const location = useLocation();
    const { currentUserProfile } = useAppSelector(store => store.profile);
    const { routes, navigate } = useAppNavigation();
    const theme = useTheme();
    const currentTab = useMemo(() => matchPathToHomeTab(location.pathname), [location]);
    const onTabChange = (selectedTab) => {
        switch (selectedTab) {
            case 'my_profile':
                navigate(routes.MY_PROFILE);
                break;
            case 'overview':
                navigate(routes.HOME_OVERVIEW);
                break;
            case 'platform_balance':
                navigate(routes.HOME_BALANCE);
                break;
            default:
        }
    };
    const homeTabs = useMemo(() => getHomeTabs(currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type), [currentUserProfile]);
    useEffect(() => {
        if (!currentTab) {
            navigate(routes.MY_PROFILE, { replace: true });
        }
    }, [
        currentTab,
        routes,
        navigate,
    ]);
    if (!currentTab) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Container, { children: _jsx(NarrowContainer, { children: _jsx(Tabs, { tabs: homeTabs, activeTab: currentTab, onChange: onTabChange, tabSize: 'large', tabsGap: theme.spacing_sizes.m, fullWidth: true }) }) }), _jsx(Outlet, {})] }));
};
export default HomePage;
