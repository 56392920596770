var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useMemo, } from 'react';
import throttle from 'lodash.throttle';
import SearchInput from '@shared/ui/inputs/SearchInput';
const ThrottleInputComponent = (_a, ref) => {
    var { onChange, throttleDelay = 300, searchFn, menuItems = [], hideMenu = true } = _a, props = __rest(_a, ["onChange", "throttleDelay", "searchFn", "menuItems", "hideMenu"]);
    const onChangeThrottle = useMemo(() => throttle(searchFn, throttleDelay), [throttleDelay, searchFn]);
    const onChangeHandler = (event) => {
        if (onChange) {
            onChange(event);
        }
        onChangeThrottle(event);
    };
    return (_jsx(SearchInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { ref: ref, hideMenu: hideMenu, onChange: onChangeHandler, menuItems: menuItems })));
};
const ThrottleInput = forwardRef(ThrottleInputComponent);
export default ThrottleInput;
