import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { formatUSD } from '@utils/numbers';
import RenderText from '@shared/ui/display/table-renderers/RenderText';
import { RenderIncomeBadgePlain, RenderIncomeBadgeTinted } from '@shared/ui/display/table-renderers/RenderIncomeBadge';
import RenderExchange from '@shared/ui/display/table-renderers/RenderExchange';
import RenderProfileThumbnail from '@shared/ui/display/table-renderers/RenderProfileThumbnail';
import { useAppSelector } from '@store/Hooks';
import { Title } from '@shared/ui/display/Typography';
import { tableRendererWrapper } from '@shared/ui/display/Table';
import { useProfileOutletContext } from '@features/profile/profile-info';
import { ContainerColumn, SurfaceColumn } from '@shared/ui/display/Containers';
const MinHeight = 486;
const Container = styled(ContainerColumn)(props => ({
    flex: 1,
    margin: `${props.theme.spacing_sizes.l}px 0`,
}));
const TableContainer = styled(SurfaceColumn)(props => ({
    height: 'auto',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.l,
    padding: props.theme.spacing_sizes.l,
    // TODO: move shadow to theme
    boxShadow: '0px 1px 3px 0px #E6E8EB',
}));
const LoaderContainer = styled(ContainerColumn)(() => ({
    flex: 1,
    minHeight: MinHeight,
    alignItems: 'center',
    justifyContent: 'center',
}));
const getColumnsDefinition = (t) => ([
    {
        header: t('table.copiers.exchange'),
        accessorKey: 'id',
        enableSorting: false,
        meta: {
            flex: 0.20,
        },
        // TODO: check type inference
        cell: tableRendererWrapper(RenderExchange),
    },
    {
        header: t('table.copiers.copier'),
        enableSorting: false,
        meta: {
            flex: 0.20,
        },
        accessorFn: model => model.profile,
        cell: tableRendererWrapper(RenderProfileThumbnail),
    },
    {
        header: t('table.copiers.duration'),
        accessorFn: () => '12 D',
        enableSorting: false,
        meta: {
            flex: 0.08,
        },
        cell: tableRendererWrapper(RenderText),
    },
    {
        header: t('table.common.days_range.one_day'),
        accessorFn: model => model.past_month_difference,
        enableSorting: false,
        meta: {
            flex: 0.13,
        },
        cell: tableRendererWrapper(RenderIncomeBadgeTinted),
    },
    {
        header: t('table.common.days_range.one_week'),
        accessorFn: model => model.past_month_difference,
        enableSorting: false,
        meta: {
            flex: 0.13,
        },
        cell: tableRendererWrapper(RenderIncomeBadgeTinted),
    },
    {
        header: t('table.copiers.month_total'),
        accessorKey: 'past_month_difference',
        enableSorting: false,
        meta: {
            flex: 0.13,
        },
        cell: tableRendererWrapper(RenderIncomeBadgePlain),
    },
    {
        header: t('table.copiers.subscription_fee'),
        accessorFn: model => formatUSD(model.bid, { compactNotation: true }),
        enableSorting: false,
        meta: {
            flex: 0.13,
        },
        cell: tableRendererWrapper(RenderText),
    },
]);
const GetCopiersQueryTake = 10;
const ProfileCopiers = () => {
    const { profileId } = useProfileOutletContext();
    const { t } = useTranslation();
    const { areDevComponentsEnabled } = useAppSelector(state => state.app);
    const columnsDefinition = useMemo(() => getColumnsDefinition(t), [t]);
    const [paginationState, setPaginationState] = useState({
        pageIndex: 0,
        pageSize: GetCopiersQueryTake,
    });
    const skip = useMemo(() => paginationState.pageIndex * paginationState.pageSize, [paginationState]);
    // const {
    //     data: copiersChunk,
    //     isLoading: copiersChunkIsLoading,
    //     isFetching: copiersChunkIsFetching,
    //     isError: copiersChunkIsError,
    // } = useGetProfileCopiersQuery({
    //     profileId,
    //     skip,
    //     take: GetCopiersQueryTake,
    // })
    // const isLoadingInitial = useMemo(
    //     () => copiersChunkIsLoading,
    //     [copiersChunkIsLoading],
    // )
    // const isError = useMemo(
    //     () => copiersChunkIsError,
    //     [copiersChunkIsError],
    // )
    // const isChunkEmpty = useMemo(
    //     () => !IsDefined(copiersChunk) || !copiersChunk.data.length,
    //     [copiersChunk],
    // )
    return (_jsx(Container, { children: _jsx(TableContainer, { style: {
            // ...(
            //     isChunkEmpty && ({
            //         minHeight: MinHeight,
            //     })
            // ),
            }, children: _jsx(Title, { children: t('profile.tabs.copiers') }) }) }));
};
export default ProfileCopiers;
