import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { BodyMediumBold, BodyMediumSemiBold } from '@shared/ui/display/Typography';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import { formatUSD } from '@shared/lib/numbers';
const IncomeInfoContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    backgroundColor: '#ECFBF1',
    padding: `${props.theme.spacing_sizes.s * 5}px ${props.theme.spacing_sizes.l}px`,
    borderRadius: 6,
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.m,
    justifyContent: 'center',
}));
const PayoutTitle = styled(BodyMediumSemiBold)(() => ({
    lineHeight: '23px',
}));
const PayoutAmount = styled(BodyMediumBold)(props => ({
    fontSize: 28,
    fontWeight: 700,
    color: props.theme.palette.success.main,
    lineHeight: '36px',
}));
const IncomeInfo = ({ isLoading = false, amount }) => {
    const { t } = useTranslation();
    return (_jsx(WithSkeleton, { isLoading: isLoading, width: 312, height: 131, children: _jsxs(IncomeInfoContainer, { children: [_jsx(PayoutTitle, { children: t('balance.trader_balance.management_income.payout') }), _jsx(PayoutAmount, { children: formatUSD(amount) })] }) }));
};
export default IncomeInfo;
