import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { ReactComponent as CheckedIcon } from '@icons/wolfkit-solid/checkmark-without-check-circle-solid.svg';
import { ReactComponent as XMarkCircleIcon } from '@icons/wolfkit-solid/xmark-without-cross-circle-solid.svg';
import { BodySmall } from '@shared/ui/display/Typography';
import Button from '@shared/ui/buttons/Button';
const SuccessIcon = styled(CheckedIcon)(props => ({
    width: `${props.theme.spacing_sizes.xm}px`,
    height: `${props.theme.spacing_sizes.xm}px`,
    path: {
        fill: `${props.theme.palette.success.main}`,
    },
}));
const NeutralIcon = styled(XMarkCircleIcon)(props => ({
    width: `${props.theme.spacing_sizes.xm}px`,
    height: `${props.theme.spacing_sizes.xm}px`,
    path: {
        fill: '#D6DCE0',
    },
}));
const Text = styled(BodySmall)(props => ({
    fontSize: `${props.theme.spacing_sizes.xs * 2}px`,
    fontWeight: 500,
}));
const ValueCellContainer = styled.span(props => ({
    display: 'flex',
    gap: `${props.theme.spacing_sizes.m}px`,
    alignItems: 'center',
}));
const StatusIcon = ({ status }) => {
    const Icon = useMemo(() => {
        switch (status) {
            case 'success':
                return _jsx(SuccessIcon, {});
            case 'neutral':
                return _jsx(NeutralIcon, {});
            default:
                return null;
        }
    }, [status]);
    return Icon;
};
const SettingsValueCell = ({ status = undefined, text = undefined, actionBtnProps = undefined, }) => (_jsxs(ValueCellContainer, { children: [status ? _jsx(StatusIcon, { status: status }) : null, text ? _jsx(Text, { children: text }) : null, actionBtnProps ? _jsx(Button, Object.assign({ variant: 'plain' }, actionBtnProps)) : null] }));
export default SettingsValueCell;
