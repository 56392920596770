import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ContainerRow } from '@shared/ui/display/Containers';
import { BodyMedium } from '@shared/ui/display/Typography';
const UnavailableListHeader = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ABB4BB',
    fontSize: 14,
    padding: `${props.theme.spacing_sizes.m}px 0`,
    textAlign: 'center',
    lineHeight: '16px',
    gap: 19,
}));
const UnavailableListText = styled(BodyMedium)(() => ({
    textWrap: 'nowrap',
}));
const UnavailableListDivider = styled.span(props => ({
    height: 1,
    backgroundColor: props.theme.customColors.input.border,
    width: '100%',
}));
const UnavailableList = ({ children }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs(UnavailableListHeader, { children: [_jsx(UnavailableListDivider, {}), _jsx(UnavailableListText, { children: t('portfolio.connection_failed') }), _jsx(UnavailableListDivider, {})] }), children] }));
};
export default UnavailableList;
