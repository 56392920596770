import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Button from '@shared/ui/buttons/Button';
import { BodySmall } from '@shared/ui/display/Typography';
const INPUT_SIZES = {
    medium: {
        innerPadding: '70px 63px',
        descriptionMarginBottom: '30px',
        letterSpacing: '-0.108px',
    },
    small: {
        innerPadding: '36px 63px',
        descriptionMarginBottom: '26px',
        letterSpacing: '-0.108px',
    },
};
const FileInputContainer = styled.div(props => {
    var _a, _b;
    return ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: 323,
        height: 'auto',
        padding: (props === null || props === void 0 ? void 0 : props.hasError) ? '60px' : (_a = INPUT_SIZES[props.size]) === null || _a === void 0 ? void 0 : _a.innerPadding,
        // trick for custom border
        backgroundImage: 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'9\' ry=\'9\' stroke=\'rgb(100, 136, 240)\' stroke-width=\'4\' stroke-dasharray=\'6%2c 12 \' stroke-dashoffset=\'4\' stroke-linecap=\'square\'/%3e%3c/svg%3e")',
        letterSpacing: (_b = INPUT_SIZES[props.size]) === null || _b === void 0 ? void 0 : _b.letterSpacing,
        borderRadius: props.theme.spacing_sizes.xs,
        backgroundColor: props.theme.customColors.fileUpload.background,
        width: '100%',
        boxSizing: 'border-box',
    });
});
const LabelText = styled(BodySmall, { shouldForwardProp: (propName) => propName !== 'hasError' })(props => ({
    color: props.hasError ?
        props.theme.palette.text.primary : props.theme.customColors.fileUpload.text,
    fontSize: props.theme.spacing_sizes.xm,
    fontWeight: 500,
    lineHeight: `${props.theme.spacing_sizes.xs * 2.5}px`,
    marginBottom: props.theme.spacing_sizes.xs * 1.75,
    textAlign: 'center',
}));
const FileTypeText = styled(BodySmall)(props => {
    var _a;
    return ({
        color: props.theme.customColors.fileUpload.fileTypeText,
        fontSize: props.theme.spacing_sizes.xs * 1.75,
        fontWeight: 400,
        lineHeight: `${props.theme.spacing_sizes.xs * 2.5}px`,
        textAlign: 'center',
        marginBottom: (_a = INPUT_SIZES[props.size]) === null || _a === void 0 ? void 0 : _a.descriptionMarginBottom,
    });
});
const UploadContainer = styled.span(() => ({
    display: 'inline-flex',
    justifyContent: 'center',
}));
const InputComponent = styled.input(() => ({
    width: 0,
    height: 0,
    visibility: 'hidden',
}));
const IconContainer = styled.div(props => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: props.theme.spacing_sizes.l,
}));
const AlertIconContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 70,
    height: 70,
    fontSize: 46,
    color: props.theme.palette.error.main,
    backgroundColor: props.theme.palette.error.light,
    userSelect: 'none',
    pointerEvents: 'none',
    cursor: 'none',
}));
const AlertIcon = () => _jsx(AlertIconContainer, { children: "!" });
export var FileInputErrorKeys;
(function (FileInputErrorKeys) {
    FileInputErrorKeys["ExceededFileSize"] = "EXCEEDED_FILE_SIZE";
    FileInputErrorKeys["IncorrectMimeType"] = "INCORRECT_MIME_TYPE";
})(FileInputErrorKeys || (FileInputErrorKeys = {}));
const DEFAULT_ERROR_STATE = {
    hasError: false,
    errorMessage: undefined,
};
const FileInput = ({ multiple = undefined, innerLabelText = '', description = '', buttonText = 'Browse photo', mimeType = undefined, icon = undefined, onChange = undefined, maxSize = 800000, size = 'medium', errors = undefined, }) => {
    const inputRef = useRef(null);
    const [filesList, setFilesList] = useState({ length: 0 });
    const [errorState, setErrorState] = useState(DEFAULT_ERROR_STATE);
    const addFileToList = (file) => {
        setFilesList((prevList) => {
            let updatedList;
            if (multiple) {
                updatedList = Object.assign(Object.assign({}, prevList), { [prevList.length]: file, length: prevList.length + 1 });
            }
            else {
                updatedList = { 0: file, length: 1 };
            }
            const filesSize = Object.values(updatedList).reduce((acc, element) => {
                if (typeof element !== 'number') {
                    return acc + element.size;
                }
                return acc;
            }, 0);
            if (filesSize > maxSize) {
                setErrorState({ hasError: true, errorMessage: errors === null || errors === void 0 ? void 0 : errors.EXCEEDED_FILE_SIZE });
            }
            else {
                setErrorState(DEFAULT_ERROR_STATE);
                if (onChange) {
                    onChange(updatedList);
                }
                return updatedList;
            }
            return prevList;
        });
    };
    const handleFileDrag = (ev) => {
        ev.preventDefault();
    };
    const checkFileType = (file) => {
        if (file) {
            const isAcceptable = mimeType === null || mimeType === void 0 ? void 0 : mimeType.find((type) => file.type === type);
            if (isAcceptable) {
                addFileToList(file);
            }
            else {
                setErrorState({ hasError: true, errorMessage: errors === null || errors === void 0 ? void 0 : errors.INCORRECT_MIME_TYPE });
            }
        }
    };
    const handleUploadBtnClick = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleFileDrop = (ev) => {
        ev.preventDefault();
        checkFileType(ev.dataTransfer.files[0]);
    };
    const handleInputChange = (ev) => {
        var _a, _b;
        const appliedFile = (_b = (_a = ev === null || ev === void 0 ? void 0 : ev.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        checkFileType(appliedFile);
    };
    const Icon = useMemo(() => {
        if ((errorState === null || errorState === void 0 ? void 0 : errorState.hasError) && errorState.errorMessage) {
            return _jsx(IconContainer, { children: _jsx(AlertIcon, {}) });
        }
        if (icon) {
            return _jsx(IconContainer, { children: icon });
        }
        return null;
    }, [icon, errorState]);
    return (_jsxs(FileInputContainer, { hasError: errorState === null || errorState === void 0 ? void 0 : errorState.hasError, onDragOver: handleFileDrag, onDrop: handleFileDrop, size: size, children: [Icon, _jsx(LabelText, { hasError: errorState === null || errorState === void 0 ? void 0 : errorState.hasError, children: (errorState === null || errorState === void 0 ? void 0 : errorState.errorMessage) ? errorState.errorMessage : innerLabelText }), _jsx(FileTypeText, { size: size, children: description }), _jsxs(UploadContainer, { children: [_jsx(InputComponent, { type: 'file', onChange: handleInputChange, multiple: multiple, ref: inputRef, accept: (mimeType === null || mimeType === void 0 ? void 0 : mimeType.length) ? mimeType.join(', ') : undefined }), _jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', onClick: handleUploadBtnClick, children: buttonText })] })] }));
};
export default FileInput;
