import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useRef } from 'react';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExchangeWallet } from '@features/wallet/exchange-wallet';
import { ReactComponent as SquareIconComponent } from '@icons/wolfkit-light/square-on-square-light.svg';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { BodyMediumSemiBold } from '@shared/ui/display/Typography';
import { PlusIcon } from '@shared/ui/misc/PaymentSelect/icons';
import IconText from '@shared/ui/icons/IconText';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import { checkIfWalletSupported, } from '@entities/wallet';
import { PortfolioList } from '@features/portfolio/portfolio-list';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import UnsupportedWalletsList from '../UnsupportedWalletsList';
import { ListHeader, ListTitle } from '../shared';
import SwitchProfileBanner from '../components/SwitchProfileBanner';
import SupportedWalletsList from '../components/SupportedWalletsList';
const ExchangeWalletsList = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.l,
    flexWrap: 'wrap',
}));
const PortfoliosListContainer = styled.div(props => ({
    marginBottom: props.theme.spacing_sizes.l,
}));
const IconTextStyled = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.xs * 1.25,
}));
const IconTextError = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.xs * 1.25,
    '& .MuiTypography-root': {
        color: props.theme.palette.error.main,
    },
}));
const SquareIcon = styled(SquareIconComponent)(props => ({
    padding: 4,
    width: 16,
    height: 16,
    background: props.theme.palette.primary.light,
    borderRadius: 3,
}));
const ApiAddress = styled(BodyMediumSemiBold)(props => ({
    color: props.theme.palette.text.primary,
}));
const CopyButton = styled(Button)(props => ({
    minHeight: 'unset',
    maxHeight: 'unset',
    padding: `${props.theme.spacing_sizes.xs}px ${props.theme.spacing_sizes.xs * 2}px 
              ${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.xs * 2}px`,
    gap: props.theme.spacing_sizes.m,
    border: 'unset',
    justifyContent: 'space-between',
}));
const SwitchProfileContainer = styled.div(props => ({
    marginBottom: props.theme.spacing_sizes.l,
}));
const TraderOverview = ({ wallets = [], exchanges = [], portfolios = [], canSwitchProfile = false, profileType, supportedWalletsDict, isLoadingWallets = false, isLoadingPortfolios = false, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { routes } = useAppNavigation();
    const copyLinkRef = useRef(null);
    const { supportedWallets, unsupportedWallets, } = useMemo(() => wallets
        .reduce((acc, wallet) => {
        const isSupported = checkIfWalletSupported(wallet, supportedWalletsDict);
        if (isSupported) {
            acc.supportedWallets.push(wallet);
        }
        else {
            acc.unsupportedWallets.push(wallet);
        }
        return acc;
    }, {
        supportedWallets: [],
        unsupportedWallets: [],
    }), [wallets, supportedWalletsDict]);
    // const handleSuccessCopy = () => {}
    // const btnOptions = useMemo(() => (portfolioId: string) => ([
    //     {
    //         itemKey: 'api-address',
    //         children: (
    //             <CopyLink
    //                 ref={copyLinkRef}
    //                 link={getAbsolutePath(routes.HOME_OVERVIEW) as string}
    //                 onCopySuccess={handleSuccessCopy}
    //             >
    //                 <CopyButton
    //                     endIcon={SquareIcon}
    //                     size='small'
    //                     variant='plain'
    //                     color='primary'
    //                     fullWidth
    //                 >
    //                     {/* TODO: add util for masking api address */}
    //                     <ApiAddress>API: 1234.....01324</ApiAddress>
    //                 </CopyButton>
    //             </CopyLink>
    //         ),
    //         addDivider: true,
    //         removePadding: true,
    //     },
    //     {
    //         itemKey: 'edit-portfolio',
    //         children: (
    //             <IconTextStyled
    //                 IconComponent={
    //                     <Icon IconComponent={PencilIcon} />
    //                 }
    //                 text={t('overview.portfolios_list.portfolio_actions.edit_portfolio')}
    //             />
    //         ),
    //         size: 'small',
    //     },
    //     {
    //         itemKey: 'view-data',
    //         children: (
    //             <IconTextStyled
    //                 IconComponent={
    //                     <Icon IconComponent={UsersIcon} />
    //                 }
    //                 text={t('overview.portfolios_list.portfolio_actions.view_data')}
    //             />
    //         ),
    //         size: 'small',
    //     },
    //     {
    //         itemKey: 'copy-link',
    //         children: (
    //             <IconTextStyled
    //                 IconComponent={
    //                     <Icon IconComponent={LinkIcon} />
    //                 }
    //                 text={t('overview.portfolios_list.portfolio_actions.copy_link')}
    //             />
    //         ),
    //         addDivider: true,
    //         size: 'small',
    //     },
    //     {
    //         itemKey: 'close-portfolio',
    //         children: (
    //             <IconTextError
    //                 IconComponent={
    //                     <Icon IconComponent={XMarkIcon} color={theme.palette.error.main} />
    //                 }
    //                 text={t('overview.portfolios_list.portfolio_actions.close_portfolio')}
    //             />
    //         ),
    //         // onClick: () => handleClosePortfolio(portfolioId),
    //         size: 'small',
    //     },
    // ] as DropdownMenuItem[]), [])
    return (_jsxs(ContainerColumn, { children: [_jsx(ListHeader, { marginBottom: theme.spacing_sizes.s * 10, children: _jsx(ListTitle, { children: `${t('overview.portfolios_list.title')} (${portfolios.length || 0})` }) }), _jsx(PortfoliosListContainer, { children: _jsx(PortfolioList, { portfolios: portfolios, onSelectPortfolioId: () => { }, displayAddPortfolioBtn: !isLoadingPortfolios, isLoading: isLoadingPortfolios }) }), canSwitchProfile && (_jsx(SwitchProfileContainer, { children: _jsx(WithSkeleton, { isLoading: isLoadingWallets, width: '100%', height: 69, children: _jsx(SwitchProfileBanner, { description: t('portfolio.switch_profile_banner.switch_to_client'), profileType: profileType }) }) })), _jsxs(ListHeader, { children: [_jsxs(ListTitle, { disabled: true, children: [t('overview.exchange_wallets.title'), (wallets === null || wallets === void 0 ? void 0 : wallets.length) && !isLoadingWallets ? ` (${wallets.length})` : ''] }), _jsx(WithSkeleton, { isLoading: isLoadingWallets, width: 210, height: 40, children: _jsx(Button, { variant: 'tinted', startIcon: PlusIcon, disabled: true, children: t('overview.exchange_wallets.connect_exchange') }) })] }), _jsx(SupportedWalletsList, { portfolios: portfolios, wallets: wallets, disabled: true, isLoading: isLoadingWallets }), (unsupportedWallets === null || unsupportedWallets === void 0 ? void 0 : unsupportedWallets.length) > 0 && (_jsx(UnsupportedWalletsList, { count: unsupportedWallets === null || unsupportedWallets === void 0 ? void 0 : unsupportedWallets.length, children: unsupportedWallets.map((wallet) => (_jsx(ExchangeWallet, { wallet: wallet, portfolios: portfolios, isSupported: false, disabled: true }, wallet.id))) }))] }));
};
export default TraderOverview;
