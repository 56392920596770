import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ClipIcon } from '@icons/wolfkit-light/paper-clip-light.svg';
import { ReactComponent as XMarkIconComponent } from '@icons/wolfkit-light/xmark-light.svg';
import FileInputWrapper from '@shared/ui/inputs/FileInputWrapper';
import { ContainerRow } from '@shared/ui/display/Containers';
import ButtonCustom from '@shared/ui/buttons/ButtonCustom';
import Icon from '@shared/ui/icons/Icon';
import { BodySmall } from '@shared/ui/display/Typography';
const AttachButtonContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.m,
}));
const HintText = styled(BodySmall, { shouldForwardProp: propName => propName !== 'hasError' })(props => ({
    color: props.hasError ? props.theme.palette.error.main : '#ABB4BB',
    lineHeight: '20px',
}));
const ButtonCustomStyled = styled(ButtonCustom, { shouldForwardProp: propName => propName !== 'isFileUploaded' })(props => ({
    backgroundColor: 'transparent',
    color: props.theme.palette.primary.main,
    padding: props.theme.spacing_sizes.xs * 1.25,
    lineHeight: '20px',
    cursor: props.isFileUploaded ? 'default' : 'pointer',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: props.isFileUploaded ? 'transparent' : props.theme.palette.primary.light,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        outline: props.isFileUploaded ? 'none' : `2px solid ${props.theme.customColors.shades.primaryMidLight}`,
    },
    '& .MuiButton-endIcon': {
        marginLeft: props.theme.spacing_sizes.s,
        marginRight: 0,
    },
}));
const XMarkIcon = styled(XMarkIconComponent)(props => ({
    cursor: 'pointer',
    padding: '1px',
    borderRadius: 3,
    '&:hover': {
        backgroundColor: props.theme.palette.primary.light,
    },
    '&:active': {
        backgroundColor: props.theme.customColors.shades.primaryMidLight,
    },
}));
export var FileInputErrorKeys;
(function (FileInputErrorKeys) {
    FileInputErrorKeys["ExceededFileSize"] = "EXCEEDED_FILE_SIZE";
    FileInputErrorKeys["IncorrectMimeType"] = "INCORRECT_MIME_TYPE";
})(FileInputErrorKeys || (FileInputErrorKeys = {}));
const DEFAULT_ERROR_STATE = {
    hasError: false,
    errorMessage: undefined,
};
const AttachButton = ({ text = undefined, onUpload = undefined, hint = undefined, mimeType = [], errors = undefined, maxSize = undefined, onError = undefined, }) => {
    const fileInputRef = useRef(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [errorState, setErrorState] = useState(DEFAULT_ERROR_STATE);
    const handleFileUpload = (file) => {
        const isAcceptable = (mimeType === null || mimeType === void 0 ? void 0 : mimeType.length) ? mimeType === null || mimeType === void 0 ? void 0 : mimeType.find((type) => file.type === type) : true;
        const isSizeExceeded = maxSize ? maxSize < file.size : false;
        if (isSizeExceeded) {
            setErrorState({ hasError: true, errorMessage: errors === null || errors === void 0 ? void 0 : errors.EXCEEDED_FILE_SIZE });
            if (onError) {
                onError((errors === null || errors === void 0 ? void 0 : errors.EXCEEDED_FILE_SIZE) || '');
            }
            return;
        }
        if (!isAcceptable) {
            setErrorState({ hasError: true, errorMessage: errors === null || errors === void 0 ? void 0 : errors.INCORRECT_MIME_TYPE });
            if (onError) {
                onError((errors === null || errors === void 0 ? void 0 : errors.INCORRECT_MIME_TYPE) || '');
            }
            return;
        }
        setErrorState({ hasError: false });
        setUploadedFile(file);
        if (onUpload) {
            onUpload(file);
        }
    };
    const handleFileDelete = () => setUploadedFile(null);
    const handleButtonClick = () => {
        if ((fileInputRef === null || fileInputRef === void 0 ? void 0 : fileInputRef.current) && uploadedFile === null) {
            fileInputRef.current.click();
        }
    };
    const displayedText = useMemo(() => {
        if (errorState.errorMessage) {
            return errorState.errorMessage;
        }
        // if we successfully applied file we hide text
        if (uploadedFile) {
            return null;
        }
        if (hint) {
            return hint;
        }
        return null;
    }, [errorState, uploadedFile, hint]);
    return (_jsxs(AttachButtonContainer, { children: [_jsx(FileInputWrapper, { ref: fileInputRef, onUpload: handleFileUpload, children: _jsx(ButtonCustomStyled, { onClick: handleButtonClick, startIcon: uploadedFile ? undefined : _jsx(Icon, { IconComponent: ClipIcon }), endIcon: uploadedFile ?
                        _jsx(Icon, { IconComponent: XMarkIcon, onClick: handleFileDelete }) :
                        undefined, isFileUploaded: Boolean(uploadedFile !== null), variant: 'contained', children: uploadedFile ? uploadedFile.name : text }) }), ((errorState === null || errorState === void 0 ? void 0 : errorState.hasError) || hint) && (_jsx(HintText, { hasError: Boolean(errorState.errorMessage && errorState.errorMessage.length > 0), children: displayedText }))] }));
};
export default AttachButton;
