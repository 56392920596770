import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PortfolioIcon } from '@icons/custom/portfolio-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { useGetProfilePortfolios, useProfileInfo } from '@entities/profile';
import { filterPortfolioSubscriptionsByStatus } from '@entities/portfolio';
import { SubscriptionStatus } from '@shared/api/subscription';
import ProfileTabLabel from './ProfileTabLabel';
import definePortfoliosListAmount from './utils';
const PortfolioIconSized = () => (_jsx(Icon, { size: 16, IconComponent: PortfolioIcon }));
const PortfoliosTabLabel = ({ profileNickname = undefined, isSelected = false, isClientProfile = undefined, isMyProfile, }) => {
    const { t } = useTranslation();
    const { profile } = useProfileInfo(profileNickname);
    const { isFetchingPortfolios, isFetchingPortfolioSubscriptions, isError, portfoliosList, subscribedPortfoliosList, fetchingPortfoliosType } = useGetProfilePortfolios({
        profileId: profile === null || profile === void 0 ? void 0 : profile.id,
        profileType: profile === null || profile === void 0 ? void 0 : profile.type,
        isVerifiedTrader: profile === null || profile === void 0 ? void 0 : profile.isVerifiedTrader,
        isMyProfile,
    });
    const activeSubscriptions = useMemo(() => filterPortfolioSubscriptionsByStatus(subscribedPortfoliosList, SubscriptionStatus.Active), [subscribedPortfoliosList]);
    const amount = definePortfoliosListAmount({
        fetchingPortfoliosType,
        isError,
        isFetchingPortfolios,
        isFetchingPortfolioSubscriptions,
        portfoliosList,
        subscribedPortfolios: activeSubscriptions,
    });
    return (_jsx(ProfileTabLabel, { IconComponent: PortfolioIconSized, text: t(isClientProfile ? 'profile.tabs.active_portfolios' : 'profile.tabs.portfolios', { ns: 'translation' }), amount: amount, selected: isSelected }));
};
export default PortfoliosTabLabel;
