import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SurfaceColumn } from '@shared/ui/display/Containers';
import { TitleSecondary } from '@shared/ui/display/Typography';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as NotFoundIcon } from '@icons/empty-states/search-not-found.svg';
const Container = styled(SurfaceColumn)((props) => ({
    height: 'auto',
    alignItems: 'center',
    padding: '60px 24px',
    gap: props.theme.spacing_sizes.l,
    boxShadow: '0px 4px 10px -1px rgba(0, 0, 0, 0.04)',
}));
const Text = styled(TitleSecondary)(() => ({
    fontWeight: 400,
}));
const EmptyView = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsxs(Container, { children: [_jsx(Icon, { size: 200, IconComponent: NotFoundIcon, keepOriginalColor: true }), _jsx(Text, { color: theme.palette.text.primary, children: t('errors.not_found', { ns: 'common' }) })] }));
};
export default EmptyView;
