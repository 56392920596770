var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useLocation, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { TitleSecondary } from '@shared/ui/display/Typography';
import { useAppSelector } from '@app/store/Hooks';
import { useGetSupportedWalletTypesQuery, } from '@shared/api/exchange';
import { ContainerColumn, SurfaceColumn, alignToCenter } from '@shared/ui/display/Containers';
import { useProfileOutletContext } from '@features/profile/profile-info';
import useProfileNavigation from '@features/profile/profile-navigation';
import { mapSupportedWalletsDict } from '@entities/wallet';
import { PortfoliosEmptyView } from '@features/portfolio/portfolio-empty-view';
import { useGetProfilePortfolios } from '@entities/profile';
import TraderPortfoliosList from './components/TraderPortfoliosList';
import ClientPortfoliosList from './components/ClientPortfoliosList';
import { getPortfolioTabs, matchPathToPortfolioTab } from '../lib';
const ProgressErrorContainer = alignToCenter(ContainerColumn);
const Content = styled(ContainerColumn)((props) => ({
    gap: props.theme.spacing_sizes.l,
}));
const EmptyViewContainer = styled(SurfaceColumn)(props => ({
    boxSizing: 'border-box',
    height: 'auto',
    boxShadow: `0px 1px 3px 0px ${props.theme.customColors.surface.outline}`,
    border: 'none',
    borderRadius: 12,
    padding: `${props.theme.spacing_sizes.m * 3}px ${props.theme.spacing_sizes.l}px 
              ${props.theme.spacing_sizes.m * 3}px ${props.theme.spacing_sizes.l}px`,
}));
const PortfoliosTab = () => {
    const { portfolioId: portfolioIdParam } = useParams();
    const { profileId, nickname, profileType, isMyProfile = false, isVerifiedTrader, } = useProfileOutletContext();
    const { language } = useAppSelector(state => state.app);
    const { pathname: locationPathname } = useLocation();
    const { navigateToProfileTab, navigateToPortfolioTab, } = useProfileNavigation();
    const { areExchangesEmpty, arePortfoliosEmpty, areSubscribedPortfoliosEmpty, displayPortfoliosList, displaySubscribedPortfoliosList, isFetching, isError, isFetchingPortfolios, portfoliosList, subscribedPortfoliosList, wallets, exchangesList, } = useGetProfilePortfolios({
        profileId,
        isMyProfile,
        isVerifiedTrader,
        profileType,
    });
    const { data: { data: supportedWalletsDict } } = useGetSupportedWalletTypesQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: mapSupportedWalletsDict(data) }));
        },
    });
    const portfolioId = portfolioIdParam || undefined;
    const onPortfolioSelect = (selectedPortfolioId) => {
        if (selectedPortfolioId === portfolioId) {
            navigateToProfileTab('portfolios', { params: { nickname } });
        }
        else {
            navigateToPortfolioTab('information', {
                params: {
                    nickname,
                    portfolioId: String(selectedPortfolioId),
                },
            });
        }
    };
    const onPortfolioCopy = (selectedPortfolioId) => {
        navigateToPortfolioTab('copiers', {
            params: {
                nickname,
                portfolioId: String(selectedPortfolioId)
            }
        });
    };
    const portfolioTabs = getPortfolioTabs(language);
    const currentTab = useMemo(() => matchPathToPortfolioTab(locationPathname), [locationPathname]);
    const onTabChange = (selectedTab) => {
        if (!portfolioId) {
            return;
        }
        navigateToPortfolioTab(selectedTab, {
            params: {
                nickname,
                portfolioId: String(portfolioId),
            },
        });
    };
    return (_jsx(ContainerColumn, { children: _jsxs(_Fragment, { children: [_jsx(Content, { children: (areExchangesEmpty || arePortfoliosEmpty || areSubscribedPortfoliosEmpty) && (_jsx(EmptyViewContainer, { children: _jsx(PortfoliosEmptyView, { profileType: profileType, isMyProfile: isMyProfile, haveExchanges: Boolean(exchangesList.length > 0) }) })) }), displayPortfoliosList &&
                    profileType === 'TRADER' &&
                    (_jsx(TraderPortfoliosList, { nickname: nickname, portfolioId: portfolioId, portfoliosList: portfoliosList, portfolioTabs: portfolioTabs, onPortfolioSelect: onPortfolioSelect, onPortfolioCopy: onPortfolioCopy, profileId: profileId, currentTab: currentTab, onTabChange: onTabChange, selectedPortfolio: portfolioId, isMyProfile: isMyProfile, isLoading: isFetchingPortfolios })), displaySubscribedPortfoliosList &&
                    profileType === 'CLIENT' &&
                    (_jsx(ClientPortfoliosList, { walletsList: wallets, portfoliosList: subscribedPortfoliosList, supportedWalletsDict: supportedWalletsDict })), !isFetching &&
                    isError &&
                    (_jsx(ProgressErrorContainer, { children: _jsx(TitleSecondary, { children: "error" }) }))] }) }));
};
export default PortfoliosTab;
