import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';
import SettingsBlockContainer from '@shared/ui/settings/SettingsBlockContainer';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { SubscriptionStatus, useGetCurrentSubscriptionsQuery, useGetSubscriptionPlansQuery } from '@shared/api/subscription';
import { useAppSelector } from '@store/Hooks';
import { openPaymentMethodForm, PaymentMethodFormType } from '@entities/current-user';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import SubscriptionExpirationBanner from './SubscriptionExpirationBanner';
const Container = styled(ContainerColumn)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const PlanListContainer = styled(ContainerRow)((props) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const PlanListSkeletonGroup = styled(SkeletonGroup)((props) => ({
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const SubscriptionPlans = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useAppSelector(state => state.user);
    const { data: currentSubscriptions, isLoading: currentSubscriptionIsLoading, } = useGetCurrentSubscriptionsQuery({
        userId: (user === null || user === void 0 ? void 0 : user.id) || '',
    }, {
        skip: !user,
    });
    const { data: subscriptionPlans, isLoading: subscriptionPlansIsLoading, } = useGetSubscriptionPlansQuery();
    const isLoading = currentSubscriptionIsLoading || subscriptionPlansIsLoading;
    const plansToDisplay = useMemo(
    // [,,] is used to display skeleton while loading the data
    () => subscriptionPlans || [undefined, undefined, undefined], [subscriptionPlans]);
    const currentSubscription = useMemo(() => {
        const subscriptions = currentSubscriptions || [];
        for (let i = 0; i < subscriptions.length; i += 1) {
            if (subscriptions[i].status === SubscriptionStatus.Active) {
                return subscriptions[i];
            }
        }
        return undefined;
    }, [currentSubscriptions]);
    const currentSubscriptionPlan = useMemo(() => {
        if (!subscriptionPlans) {
            return undefined;
        }
        if (currentSubscription) {
            return subscriptionPlans
                .find(p => p.name === currentSubscription.platformType);
        }
        return subscriptionPlans
            .find(p => p.name === 'STARTER');
    }, [currentSubscription, subscriptionPlans]);
    const handlePlanChange = (planId) => {
        const selectedPlan = (subscriptionPlans || []).find(p => p.id === planId);
        dispatch(openPaymentMethodForm({
            type: PaymentMethodFormType.ChangeSubscriptionPlan,
            plan: selectedPlan,
            currentPlan: currentSubscriptionPlan,
            currentSubscription,
        }));
    };
    const handlePlanCancel = () => {
        const starterPlan = (subscriptionPlans || []).find(p => p.name === 'STARTER');
        dispatch(openPaymentMethodForm({
            type: PaymentMethodFormType.ChangeSubscriptionPlan,
            plan: starterPlan,
            currentPlan: currentSubscriptionPlan,
            currentSubscription,
        }));
    };
    return (_jsxs(Container, { children: [currentSubscription &&
                currentSubscriptionPlan &&
                !currentSubscription.isRecurring && (_jsx(SubscriptionExpirationBanner, { subscription: currentSubscription, subscriptionPlan: currentSubscriptionPlan })), _jsx(SettingsBlockContainer, { title: t('settings.tabs.subscription.subscription_plan.title'), gap: theme.spacing_sizes.m, padding: theme.spacing_sizes.s * 5, children: _jsxs(PlanListContainer, { children: [_jsx(SubscriptionPlanCard, { onlyDisplayFieldDescription: true }), _jsx(PlanListSkeletonGroup, { isLoading: isLoading, children: plansToDisplay.map((plan, idx) => (_jsx(SubscriptionPlanCard, { plan: plan, currentSubscription: currentSubscription, isLoading: isLoading, isSelected: (!!currentSubscriptionPlan &&
                                    (plan === null || plan === void 0 ? void 0 : plan.id) === (currentSubscriptionPlan === null || currentSubscriptionPlan === void 0 ? void 0 : currentSubscriptionPlan.id)), onSelect: handlePlanChange, onCancel: handlePlanCancel }, (plan === null || plan === void 0 ? void 0 : plan.id) || idx))) })] }) })] }));
};
export default SubscriptionPlans;
