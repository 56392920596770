import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { forwardRef } from 'react';
const defineOpacity = (isOpen = false, maxHeight) => (maxHeight !== 0 ? ({
    opacity: 1,
}) : ({ opacity: isOpen ? 1 : 0 }));
const CollapsePanelStyled = styled('div', {
    shouldForwardProp: propName => propName !== 'fullWidth' &&
        propName !== 'isOpen' &&
        propName !== 'maxHeightCollapsed' && propName !== 'maxHeightOpened'
})((props) => (Object.assign({ width: props.fullWidth ? '100%' : undefined, maxHeight: props.isOpen ? props.maxHeightOpened : props.maxHeightCollapsed, transition: 'max-height 250ms, opacity 250ms', overflow: props.isOpen ? 'visible' : 'hidden' }, defineOpacity(props.isOpen, props.maxHeightCollapsed))));
const CollapsePanelComponent = ({ destroyOnHide = false, maxHeightCollapsed = 0, maxHeightOpened = '100vh', children, isOpen, fullWidth = false, }, ref) => (_jsx(CollapsePanelStyled, { maxHeightCollapsed: maxHeightCollapsed, maxHeightOpened: maxHeightOpened, ref: ref, isOpen: isOpen, fullWidth: fullWidth, children: destroyOnHide === true ? isOpen && children : children }));
const CollapsePanel = forwardRef(CollapsePanelComponent);
export default CollapsePanel;
