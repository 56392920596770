import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { formatNumber, formatUSD } from '@shared/lib/numbers';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { BodyLargeSemiBold, BodyMedium, BodyMediumSemiBold } from '@shared/ui/display/Typography';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
const ListInfoContainer = styled(ContainerRow)(props => ({
    padding: `${props.theme.spacing_sizes.xs * 2.75}px ${props.theme.spacing_sizes.s * 5}px`,
    borderRadius: 12,
    border: '1px solid #EFF1F3',
    background: '#FFF',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.l,
}));
const InfoBadge = styled(withPercentageIncomeBadge(BodyMediumSemiBold))(() => ({
    fontWeight: 400,
}));
const ListInfoItemContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.s,
}));
const InfoItemTitle = styled.div();
const InfoItemValue = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.xs,
    alignItems: 'center',
}));
const Title = styled(BodyMedium)(() => ({
    fontSize: 14,
}));
const Value = styled(BodyLargeSemiBold)((props) => ({
    fontSize: 24,
    color: props.theme.palette.success.main,
}));
const TotalValue = styled(BodyLargeSemiBold)(() => ({
    fontSize: 24,
    lineHeight: '30px',
}));
const ListInfoItem = ({ title, value, isLoading = false, }) => (_jsxs(ListInfoItemContainer, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 170, height: 20, children: _jsx(InfoItemTitle, { children: title }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 180, height: 30, children: _jsx(InfoItemValue, { children: value }) })] }));
const ListInfo = ({ profitAmount = 0, profitPercentage = 0, subsriptionPaidAmount = 0, totalAmount = 0, totalPnl = 0, pnlPercentage = 0, isLoading = false, }) => {
    const { t } = useTranslation();
    return _jsxs(ListInfoContainer, { children: [_jsx(ListInfoItem, { title: _jsx(Title, { children: t('overview.wallet_details.info.total_amount') }), value: _jsx(TotalValue, { children: formatUSD(totalAmount, { maximumFractionDigits: 2, disableCurrencyDisplay: true }) }), isLoading: isLoading }, 'total-amount'), _jsx(ListInfoItem, { title: _jsx(Title, { children: t('overview.wallet_details.info.total_pnl') }), value: _jsxs(_Fragment, { children: [_jsx(Value, { children: formatNumber(totalPnl, { enablePlusSign: true }) }), _jsx(InfoBadge, { fontWeight: 400, percentageIncome: pnlPercentage })] }), isLoading: isLoading }, 'pnl'), _jsx(ListInfoItem, { title: _jsx(Title, { children: t('overview.wallet_details.info.profit') }), value: _jsxs(_Fragment, { children: [_jsx(Value, { children: formatNumber(profitAmount, { enablePlusSign: true }) }), _jsx(InfoBadge, { fontWeight: 400, percentageIncome: profitPercentage })] }), isLoading: isLoading }, 'net-profit'), _jsx(ListInfoItem, { title: _jsx(Title, { children: t('overview.wallet_details.info.total_subscription') }), value: _jsx(TotalValue, { children: formatUSD(subsriptionPaidAmount, { maximumFractionDigits: 2, disableCurrencyDisplay: true }) }), isLoading: isLoading }, 'subscription')] });
};
export default ListInfo;
