var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWizard } from '@shared/ui/misc/Wizard';
import { useToastNotification } from '@shared/ui/overlay/Toast';
import { AuthenticationMethod } from '@entities/auth';
import AuthModeSwitcher from '@pages/auth/ui/components/AuthModeSwitcher';
import useAuth from '../../../../../lib/useAuth';
import FormWrapper from '../../../../components/FormWrapper';
import EmailRegistrationForm from './EmailRegistrationForm';
const Credentials = () => {
    const { authMethod, handleSignUp, setConfirmationEmail, isSigningUp, } = useAuth();
    const { t } = useTranslation();
    const { toast } = useToastNotification();
    const { reset, goBack, goNext } = useWizard();
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield handleSignUp(values);
        if (typeof res === 'boolean') {
            goNext();
        }
        else {
            toast({
                message: t('errors.internal_server_error', { ns: 'common' }),
                variant: 'error',
                displayStyle: 'solid',
            });
        }
        return true;
    }), [
        handleSignUp,
        goNext,
        toast,
        t,
    ]);
    const renderInner = useCallback(() => {
        if (authMethod === null)
            return null;
        switch (authMethod) {
            case AuthenticationMethod.EMAIL:
                return (_jsx(EmailRegistrationForm, { onSubmit: handleSubmit, setEmail: setConfirmationEmail, isSigningUp: isSigningUp }));
            case AuthenticationMethod.PHONE_NUMBER:
                return null;
            default:
                return null;
        }
    }, [
        authMethod,
        isSigningUp,
        handleSubmit,
        setConfirmationEmail,
    ]);
    return (_jsx(FormWrapper, { titleText: t('auth.sign_up.steps.credentials.email.title', { ns: 'common' }), renderInnerForm: renderInner(), renderLinks: _jsx(AuthModeSwitcher, {}), backLinkProps: {
            text: t('auth.sign_up.steps.backlink_text', { ns: 'common' }),
            onClick: goBack,
        }, handleReset: reset, showNavigation: true }));
};
export default Credentials;
