import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatUSD } from '@shared/lib/numbers';
import { BodyMediumBold, BodyMediumSemiBold, } from '@shared/ui/display/Typography';
import { ContainerRow } from '@shared/ui/display/Containers';
import IconText from '@shared/ui/icons/IconText';
import { IsDefined } from '@shared/lib/js-ts';
const StepContainerComponent = styled('div', { shouldForwardProp: prop => isPropValid(prop) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: props.theme.baseSize * 70,
    height: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    paddingTop: IsDefined(props.paddingTop) ? props.paddingTop : `${props.theme.spacing_sizes.xs * 5}px`,
    gap: props.containerGap,
}));
const StepForm = styled('form', { shouldForwardProp: prop => isPropValid(prop) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: props.theme.baseSize * 70,
    height: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    paddingTop: IsDefined(props.paddingTop) ? props.paddingTop : `${props.theme.spacing_sizes.xs * 5}px`,
    gap: props.containerGap,
}));
const TitleContainer = styled.div(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
}));
const InnerElements = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    alignItems: props.contentAlign,
    justifyContent: props.contentJustify,
    flex: props.contentFlex,
}));
const ButtonsGroup = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: props.buttonsGap,
    width: '100%',
    justifyContent: 'end',
    flex: props.buttonsFlex,
}));
const StepContainer = ({ content, title = undefined, buttons = undefined, buttonsFlex = undefined, contentAlign = 'center', contentJustify = 'center', contentFlex = undefined, buttonsGap = 12, containerGap = undefined, containerPaddingTop = undefined, }) => (_jsxs(StepContainerComponent, { containerGap: containerGap, paddingTop: containerPaddingTop, children: [_jsx(TitleContainer, { children: title || null }), _jsx(InnerElements, { contentAlign: contentAlign, contentJustify: contentJustify, contentFlex: contentFlex, children: content }), _jsx(ButtonsGroup, { buttonsGap: buttonsGap, buttonsFlex: buttonsFlex, children: buttons || null })] }));
const StepFormContainer = ({ content, title = undefined, buttons = undefined, buttonsFlex = undefined, onSubmit = undefined, contentAlign = 'center', contentJustify = 'center', contentFlex = undefined, buttonsGap = 12, containerGap = undefined, containerPaddingTop = undefined, }) => (_jsxs(StepForm, { onSubmit: onSubmit, containerGap: containerGap, paddingTop: containerPaddingTop, children: [_jsx(TitleContainer, { children: title || null }), _jsx(InnerElements, { contentAlign: contentAlign, contentJustify: contentJustify, contentFlex: contentFlex, children: content }), _jsx(ButtonsGroup, { buttonsGap: buttonsGap, buttonsFlex: buttonsFlex, children: buttons || null })] }));
const StepNumber = styled.span(props => ({
    color: props.theme.customColors.input.borderHover,
    lineHeight: '24px',
}));
const StepTextComponent = styled.span(() => ({
    lineHeight: '24px',
}));
const StepTextContainer = styled(BodyMediumSemiBold, { shouldForwardProp: propName => propName !== 'margin' })(props => ({
    alignSelf: 'start',
    margin: props.margin,
    letterSpacing: '-0.176px',
}));
const StepText = ({ stepNumber, text, margin = '0 0 24px 0', }) => (_jsxs(StepTextContainer, { margin: margin, children: [_jsx(StepNumber, { children: stepNumber }), ' ', _jsx(StepTextComponent, { children: text })] }));
const StepTitle = styled(BodyMediumBold, { shouldForwardProp: propName => propName !== 'margin' && propName !== 'alignSelf' })(props => ({
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '-0.456px',
    margin: props.margin,
    alignSelf: props.alignSelf,
}));
const DividerContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    alignItems: 'center',
    margin: `${props.theme.spacing_sizes.xm}px 0`,
}));
const DividerText = styled(BodyMediumSemiBold)(() => ({
    lineHeight: '24px',
    color: '#ABB4BB',
    textWrap: 'nowrap',
}));
const DividerBorder = styled.span(() => ({
    display: 'block',
    width: '100%',
    backgroundColor: '#ABB4BB',
    height: 1,
}));
const ContentDivider = ({ text }) => (_jsxs(DividerContainer, { children: [_jsx(DividerBorder, {}), _jsx(DividerText, { children: text }), _jsx(DividerBorder, {})] }));
const BannerContainer = styled(ContainerRow)(props => ({
    height: '100%',
    padding: props.theme.spacing_sizes.l,
    backgroundColor: props.backgroundColor || '#F8FAFF',
    gap: props.theme.spacing_sizes.m,
    borderRadius: 6,
    boxSizing: 'border-box',
}));
const BannerText = styled(BodyMediumSemiBold)(props => ({
    fontSize: 14,
    color: props.theme.palette.text.primary,
}));
const Banner = ({ icon, text, }) => (_jsxs(BannerContainer, { children: [icon, _jsx(BannerText, { children: text })] }));
const WalletOptionContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    justifyContent: 'space-between',
}));
const WalletAmount = styled(BodyMediumSemiBold)();
const WalletName = styled.span(props => ({
    fontSize: 16,
    fontWeight: 500,
    color: props.theme.palette.text.primary,
}));
const WalletTypeComponent = styled.span(props => ({
    fontSize: 16,
    fontWeight: 400,
    color: props.theme.palette.text.secondary,
    textTransform: 'uppercase',
    marginLeft: props.theme.spacing_sizes.xs * 1.25,
}));
const WalletOption = ({ icon, walletName, walletType, amount, }) => {
    const { t } = useTranslation();
    const walletTypeNames = t('wallet_type', { ns: 'common', returnObjects: true });
    return (_jsxs(WalletOptionContainer, { children: [_jsx(IconText, { IconComponent: icon, text: (_jsxs(_Fragment, { children: [_jsx(WalletName, { children: walletName }), _jsx(WalletTypeComponent, { children: walletTypeNames[walletType] })] })) }), _jsx(WalletAmount, { children: formatUSD(amount) })] }));
};
export { StepContainer, StepFormContainer, StepText, StepTitle, ContentDivider, Banner, WalletOption, };
