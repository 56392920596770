import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import entryLogoImage from '@app/assets/images/endrex-title-page.png';
import { Title as TitleComponent } from '../shared';
const ImageContainer = styled.div(props => ({
    height: '100vh',
    width: 'inherit',
    position: 'relative',
    userSelect: 'none',
    boxSizing: 'border-box',
    padding: props.theme.spacing_sizes.l,
    '& .MuiBox-root': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        pointerEvents: 'none',
        borderRadius: 24,
    },
}));
const Title = styled(TitleComponent)(props => ({
    fontSize: 42,
    marginTop: 0,
    marginBottom: props.theme.spacing_sizes.xs * 1.25,
    color: props.theme.palette.text.disabled,
}));
const Text = styled(Typography)(props => ({
    color: props.theme.palette.text.disabled,
    fontSize: 18,
    lineHeight: '26px',
}));
const TextContainer = styled.div(() => ({
    position: 'absolute',
    top: '62px',
    left: '62px',
    maxWidth: 380,
    pointerEvents: 'none',
}));
const InitialImage = () => (_jsxs(ImageContainer, { children: [_jsx(Box, { component: 'img', src: entryLogoImage, loading: 'lazy', alt: 'Entry step image' }), _jsxs(TextContainer, { children: [_jsx(Title, { children: "Few click away from Endrex" }), _jsxs(Text, { children: ["Start your Copy-trade in minutes.", _jsx("br", {}), "Save time and money"] })] })] }));
export default InitialImage;
