import { createApi } from '@reduxjs/toolkit/query/react';
import restBaseQueryAuth from '@app/store/api/queries/rest/baseQueryAuth';
const apiUrl = process.env.REACT_APP_REST_BACKEND_URL;
const restApiAuthorized = createApi({
    reducerPath: 'authorizedRestApi',
    baseQuery: restBaseQueryAuth({ baseUrl: apiUrl }),
    tagTypes: [
        'Users',
        'Profiles',
        'ProfileCopiers',
        'ProfileFollowers',
        'ProfileFollowings',
        'Portfolios',
        'MarketplacePortfolios',
        'PortfolioSubscriptions',
        'CurrentUserPortfolios',
        'ProfilePortfolioSubscriptions',
        'PortfolioCopiers',
        'PopularPortfolios',
        'PortfolioPositions',
        'Countries',
        'SocialNetworks',
        'PlatformCurrentSubscriptions',
        'PlatformAvailableSubscriptions',
        'Exchanges',
        'Wallets',
        'SupportedWallets',
        'Me',
    ],
    endpoints: () => ({}),
});
export default restApiAuthorized;
