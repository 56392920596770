import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useState } from 'react';
import { useAppSelector } from '@app/store/Hooks';
import { ContainerColumn, NarrowSection, } from '../../shared/ui/display/Containers';
import Tabs from '../../shared/ui/navigation/Tabs';
import Portfolios from './tabs/Portfolios';
import Users from './tabs/Users';
import Buttons from './tabs/Buttons';
import { Title } from '../../shared/ui/display/Typography';
import Overview from './tabs/Overview';
const DEV_TABS = [
    {
        label: 'Users',
        value: 'users',
    },
    {
        label: 'Buttons',
        value: 'buttons',
    },
    {
        label: 'Portfolios',
        value: 'portfolios',
    },
    {
        label: 'Overview',
        value: 'overview',
    },
];
const Container = styled(ContainerColumn)((props) => ({
    alignItems: 'center',
    height: '100%',
    gap: props.theme.spacing_sizes.l,
    paddingTop: props.theme.spacing_sizes.s * 5,
}));
const NarrowContainer = styled(NarrowSection.withComponent(ContainerColumn))((props) => ({
    height: '100%',
    padding: 0,
    gap: props.theme.spacing_sizes.l,
}));
const TabsStyled = styled((Tabs))((props) => ({
    minHeight: 'unset',
    '& .MuiTabs-flexContainer': {
        width: '100%',
        gap: props.theme.spacing_sizes.m,
    },
}));
const TabsContent = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const DevComponents = () => {
    const { user } = useAppSelector((state) => state.user);
    const [currentTab, setCurrentTab] = useState('users');
    const onTabChange = (selectedTab) => setCurrentTab(selectedTab);
    const getCurrentTabContent = () => {
        switch (currentTab) {
            case 'users':
                return _jsx(Users, {});
            case 'buttons':
                return _jsx(Buttons, {});
            case 'portfolios':
                return _jsx(Portfolios, {});
            case 'overview':
                return _jsx(Overview, {});
            default:
                return null;
        }
    };
    if (!(user === null || user === void 0 ? void 0 : user.isSuperuser)) {
        return null;
    }
    return (_jsx(Container, { children: _jsxs(NarrowContainer, { children: [_jsx(Title, { children: "Dev Components" }), _jsx(TabsStyled, { tabs: DEV_TABS, activeTab: currentTab, onChange: onTabChange }), _jsx(TabsContent, { children: getCurrentTabContent() })] }) }));
};
export default DevComponents;
