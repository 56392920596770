import styled from '@emotion/styled';
import LinkComponent from '@mui/material/Link';
import { ReactComponent as CheckmarkIconComponent, } from '@icons/wolfkit-solid/checkmark-with-check-circle-solid.svg';
import { BodySemiBold, BodyMedium } from '@shared/ui/display/Typography';
const StepTitleCounter = styled.span(props => ({
    marginRight: `${props.theme.spacing_sizes.s}px`,
    color: props.theme.customColors.input.borderHover,
}));
const StepBodyText = styled(BodyMedium)();
const RequiredFieldLabel = styled(BodySemiBold) `
        &:after {
            content: '*';
            color: ${props => props.theme.palette.secondary.main};
            margin-left: 2px;
        }
   
`;
const Link = styled(LinkComponent)(props => ({
    cursor: 'pointer',
    color: props.theme.palette.primary.main,
}));
const FieldLabel = styled(BodySemiBold)(props => ({
    fontWeight: 600,
    width: '100%',
    marginBottom: `${props.theme.spacing_sizes.m}px`,
}));
const InputContainer = styled('div', { shouldForwardProp: propName => propName !== 'haveNotifications' })(props => ({
    width: '100%',
    marginBottom: props.haveNotifications ?
        props.theme.spacing_sizes.xs * 0.5 : props.theme.spacing_sizes.s * 5,
}));
const CheckmarkIcon = styled(CheckmarkIconComponent)(props => ({
    width: `${props.theme.spacing_sizes.xs * 8.75}px`,
    height: `${props.theme.spacing_sizes.xs * 8.75}px`,
}));
export { StepTitleCounter, RequiredFieldLabel, StepBodyText, Link, FieldLabel, InputContainer, CheckmarkIcon, };
