var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useCallback, useState, } from 'react';
import { ReactComponent as EyeSlashIcon } from '@icons/wolfkit-light/eye-slash-light.svg';
import { ReactComponent as EyeIcon } from '@icons/wolfkit-light/eye-light.svg';
import Input from '@shared/ui/inputs/Input';
import Icon from '@shared/ui/icons/Icon';
const InputPasswordComponent = (_a, ref) => {
    var { disabled = false, 'data-testid': dataTestId } = _a, props = __rest(_a, ["disabled", 'data-testid']);
    const [isHidden, toggleHidden] = useState(true);
    const toggle = useCallback(() => {
        if (!disabled) {
            toggleHidden((prev) => !prev);
        }
    }, [toggleHidden, disabled]);
    return (_jsx(Input, Object.assign({}, props, { "data-testid": dataTestId, ref: ref, type: isHidden ? 'password' : 'text', endAdornment: (_jsx(Icon, { role: 'button', "aria-label": isHidden ? 'EyeSlash' : 'Eye', IconComponent: isHidden ? EyeSlashIcon : EyeIcon, size: 20, onClick: toggle })), disabled: disabled })));
};
const InputPassword = forwardRef(InputPasswordComponent);
export default InputPassword;
