import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { Body, Subtitle } from '@shared/ui/display/Typography';
import { ReactComponent as ConnectionErrorIcon } from '@icons/custom/connection-error.svg';
import { ReactComponent as ConnectionErrorIconSelected } from '@icons/custom/connection-error-hover.svg';
import Icon from '@shared/ui/icons/Icon';
import ReconnectButton from '../ReconnectButton';
const MessageContainer = styled(ContainerRow, { shouldForwardProp: (propName) => isPropValid(propName) })((props) => ({
    height: 'auto',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: `${props.theme.spacing_sizes.xm}px ${props.theme.spacing_sizes.l}px`,
    borderRadius: props.theme.shape.borderRadius,
    background: props.isSelected ? '#FFEAEA' : '#FFF6F6',
    gap: props.theme.spacing_sizes.l,
}));
const TextContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const ApiConnectionFailureBlock = ({ portfolio, isSelected, onAction, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsxs(MessageContainer, { isSelected: isSelected, children: [_jsx(Icon, { size: [92, 92], IconComponent: (isSelected ?
                            ConnectionErrorIconSelected :
                            ConnectionErrorIcon), keepOriginalColor: true }), _jsxs(TextContainer, { children: [_jsx(Subtitle, { children: t('portfolio.api_connection_failure') }), _jsx(Body, { color: theme.palette.text.secondary, children: t('portfolio.update_api_key') })] })] }), _jsx(ReconnectButton, { portfolio: portfolio, onAction: onAction, fullWidth: true })] }));
};
export default ApiConnectionFailureBlock;
