import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Wizard } from '@shared/ui/misc/Wizard';
import { useAppSelector } from '@app/store/Hooks';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import Initial from './steps/Initial';
import Credentials from './steps/Credentials';
import EmailConfirmationStep from '../../components/EmailConfirmationStep';
const SignIn = () => {
    const { auth, authStep } = useAppSelector(state => state.auth);
    const { navigate, routes } = useAppNavigation();
    useEffect(() => {
        if (auth) {
            navigate(routes.HOME);
        }
    }, [
        auth,
        navigate,
        routes
    ]);
    return (_jsxs(Wizard, { initialStep: typeof authStep === 'number' ? authStep : undefined, children: [_jsx(Initial, {}), _jsx(Credentials, {}), _jsx(EmailConfirmationStep, {})] }));
};
export default SignIn;
