import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SocialsUtil from '@shared/lib/socials';
import IconButton from '@shared/ui/buttons/Button/IconButton';
import { ContainerRow } from '@shared/ui/display/Containers';
const SocialsContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    width: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const ProfileSocials = ({ contacts = [] }) => {
    const { t } = useTranslation();
    const socials = useMemo(() => SocialsUtil.getSocials(t), [t]);
    const getSocialIcon = (type) => {
        if (socials[type]) {
            return socials[type].smallIcon;
        }
        return () => undefined;
    };
    return (_jsx(SocialsContainer, { children: contacts.map((social, idx) => (_jsx("a", { target: '_blank', rel: 'noopener noreferrer', href: social.contact, children: _jsx(IconButton, { variant: 'tinted', color: 'primary-light', size: 'small', IconComponent: getSocialIcon(social.type) }) }, idx))) }));
};
export default ProfileSocials;
