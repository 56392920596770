import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { WalletPortfolios } from '@features/wallet/wallet-portfolios';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { checkIfWalletSupported } from '@entities/wallet';
import { SubscriptionStatus } from '@shared/api/subscription';
import UnavailableList from './UnavailableList';
const ClientPortfoliosContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.l,
}));
const ClientPortfoliosList = ({ walletsList, portfoliosList, supportedWalletsDict, }) => {
    const { availableWallets, unavailableWallets, } = useMemo(() => walletsList.reduce((acc, wallet) => {
        const isSupported = checkIfWalletSupported(wallet, supportedWalletsDict);
        if (isSupported) {
            acc.availableWallets.push(wallet);
        }
        else {
            acc.unavailableWallets.push(wallet);
        }
        return acc;
    }, {
        availableWallets: [],
        unavailableWallets: [],
    }), [walletsList, supportedWalletsDict]);
    return (_jsxs(ClientPortfoliosContainer, { children: [availableWallets.map((wallet) => {
                let portfolioSubscriptions = [];
                if (portfoliosList.length) {
                    portfolioSubscriptions = portfoliosList.filter((item) => item.subscriberWalletId === wallet.id && item.status === SubscriptionStatus.Active);
                }
                return (_jsx(WalletPortfolios, { wallet: wallet, portfolioSubscriptions: portfolioSubscriptions }, wallet.id));
            }), (unavailableWallets === null || unavailableWallets === void 0 ? void 0 : unavailableWallets.length) > 0 && (_jsx(UnavailableList, { children: unavailableWallets.map((wallet) => (_jsx(WalletPortfolios, { wallet: wallet, portfolioSubscriptions: [], hasError: true }, wallet.id))) }))] }));
};
export default ClientPortfoliosList;
