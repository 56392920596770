import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as ChevronIcon } from '@icons/wolfkit-light/chevron-small-down-light.svg';
import Button from '@shared/ui/buttons/Button';
import { ContainerRow } from '@shared/ui/display/Containers';
const ExpandCellContainer = styled(ContainerRow)(props => ({
    alignItems: 'start',
    justifyContent: 'center',
    padding: `${props.theme.spacing_sizes.xm}px 0px`,
    boxSizing: 'border-box',
}));
const ExpandRowCell = ({ cellParams, }) => {
    const handleExpand = () => {
        if (cellParams.handleExpand) {
            cellParams.handleExpand(!cellParams.isExpanded);
        }
    };
    return (_jsx(ExpandCellContainer, { children: _jsx(Button, { variant: 'outline', color: 'secondary', startIcon: ChevronIcon, startIconProps: {
                size: 16,
                style: {
                    transform: `rotate(${cellParams.isExpanded ? 180 : 0}deg)`,
                },
            }, onClick: handleExpand, style: {
                borderColor: 'transparent',
            } }) }));
};
export default ExpandRowCell;
