var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { lazy, Suspense, useMemo, useState } from 'react';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { IsDefined } from '@shared/lib/js-ts';
import { useAppSelector } from '@app/store/Hooks';
import { useGetCurrentProfilePortfolioSubscriptionsQuery } from '@shared/api/portfolio';
import { filterPortfolioSubscriptionListFromResponse } from '@entities/portfolio';
import { SubscriptionStatus } from '@shared/api/subscription';
import Tabs from '@shared/ui/navigation/Tabs';
import ListInfo from './ListInfo';
const PortfoliosListContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const FilterContainer = styled.div(() => ({
    maxWidth: 330,
}));
const CopyingPortfolioInfoLazy = lazy(() => import('@features/portfolio/portfolio-copying-info')
    .then(module => ({ default: module.CopyingPortfolioInfo })));
const getListOptions = ({ translateFn, copyingPortfoliosCount, closedPortfoliosCount, }) => [
    {
        key: SubscriptionStatus.Active,
        value: SubscriptionStatus.Active,
        label: translateFn('overview.wallet_details.tabs.active', {
            number: copyingPortfoliosCount,
        })
    },
    {
        key: SubscriptionStatus.Canceled,
        value: SubscriptionStatus.Canceled,
        // TODO-FIX: discuss with backend what status would be for closed
        label: translateFn('overview.wallet_details.tabs.closed', {
            number: closedPortfoliosCount,
        })
    }
];
const ConnectedPortfoliosList = () => {
    var _a;
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState(SubscriptionStatus.Active);
    const { user } = useAppSelector(state => state.user);
    const handleFilterSelect = (option) => {
        if (IsDefined(option) && !Array.isArray(option)) {
            setActiveKey(option);
        }
    };
    const { currentData: subscriptionsList, } = useGetCurrentProfilePortfolioSubscriptionsQuery({ profileId: user === null || user === void 0 ? void 0 : user.activeProfileId }, {
        skip: !IsDefined(user === null || user === void 0 ? void 0 : user.activeProfileId),
        selectFromResult: (_a) => {
            var { currentData } = _a, rest = __rest(_a, ["currentData"]);
            return (Object.assign(Object.assign({}, rest), { currentData: {
                    [SubscriptionStatus.Active]: filterPortfolioSubscriptionListFromResponse(currentData || [], SubscriptionStatus.Active) || [],
                    [SubscriptionStatus.Canceled]: filterPortfolioSubscriptionListFromResponse(currentData || [], SubscriptionStatus.Canceled) || [],
                } }));
        }
    });
    const options = useMemo(() => getListOptions({
        translateFn: t,
        copyingPortfoliosCount: subscriptionsList[SubscriptionStatus.Active].length,
        closedPortfoliosCount: subscriptionsList[SubscriptionStatus.Canceled].length,
    }), [t, subscriptionsList]);
    return (_jsxs(PortfoliosListContainer, { children: [_jsx(FilterContainer, { children: _jsx(Tabs, { variant: 'chart-tabs', tabs: options, activeTab: activeKey, onChange: handleFilterSelect }) }), _jsx(ListInfo, { totalAmount: 785.68, totalPnl: 54.23, pnlPercentage: 153.89, profitAmount: 2.1129, profitPercentage: 1.37, subsriptionPaidAmount: 75.00 }), _jsx(Suspense, { children: ((_a = subscriptionsList[activeKey]) === null || _a === void 0 ? void 0 : _a.map((portfolioSubscription) => (_jsx(CopyingPortfolioInfoLazy, { portfolioSubscription: portfolioSubscription }, portfolioSubscription.id)))) || null })] }));
};
export default ConnectedPortfoliosList;
