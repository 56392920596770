import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { BodySmall } from '@shared/ui/display/Typography';
import { ContainerRow } from '@shared/ui/display/Containers';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import AssetBarLegendItem from '../AssetBarLegendItem';
import { AREAS_COLORS } from '../../../lib';
const AssetsTooltipsContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.s * 5,
}));
const AssetsTooltipsErrorText = styled(BodySmall)(props => ({
    color: props.theme.palette.error.main,
    lineHeight: '16px',
}));
const AssetsTooltips = ({ assets, isLoading = false, }) => {
    const { t } = useTranslation();
    if (isLoading) {
        return _jsxs(AssetsTooltipsContainer, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 69, height: 16 }, 'tooltip-skeleton-1'), _jsx(WithSkeleton, { isLoading: isLoading, width: 69, height: 16 }, 'tooltip-skeleton-2'), _jsx(WithSkeleton, { isLoading: isLoading, width: 69, height: 16 }, 'tooltip-skeleton-3')] });
    }
    return (_jsxs(AssetsTooltipsContainer, { children: [!assets.length && (_jsx(AssetsTooltipsErrorText, { children: t('overview.exchange_wallets.card.balance_lack') })), assets.map((asset, index) => (_jsx(AssetBarLegendItem, { assetBarData: asset, color: AREAS_COLORS[index] }, asset.assetName)))] }));
};
export default AssetsTooltips;
