import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import RenderProfileTraderBadge from '@shared/ui/display/table-renderers/RenderProfileTraderBadge';
import RenderProfileActionButton from '@shared/ui/display/table-renderers/RenderProfileActionButton';
import RenderProfileThumbnail from '@shared/ui/display/table-renderers/RenderProfileThumbnail';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import { useGetProfileFollowersQuery } from '@shared/api/profile';
import { ContainerColumn, SurfaceColumn } from '@shared/ui/display/Containers';
import { Title as TitleComponent } from '@shared/ui/display/Typography';
import Table, { tableRendererWrapper, withLazyLoading } from '@shared/ui/display/Table';
import { useProfileOutletContext } from '@features/profile/profile-info';
const LoaderContainerMinHeight = '486px';
const Container = styled(ContainerColumn)(props => ({
    flex: 1,
    margin: `${props.theme.spacing_sizes.m}px 0`,
}));
const TableContainer = styled(SurfaceColumn)(props => ({
    height: 'auto',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.m,
    padding: props.theme.spacing_sizes.l,
    // TODO: move shadow to theme
    boxShadow: '0px 1px 3px 0px #E6E8EB',
}));
const Title = styled(TitleComponent)(() => ({
    fontSize: 18,
    lineHeight: '38px',
    letterSpacing: 'unset',
}));
const ColumnsDefinition = [
    {
        id: 'id',
        meta: {
            flex: 0.2,
            padding: '0 16px',
        },
        accessorFn: (model) => model,
        cell: tableRendererWrapper(RenderProfileThumbnail),
    },
    {
        accessorKey: 'type',
        meta: {
            flex: 0.8,
        },
        cell: tableRendererWrapper(RenderProfileTraderBadge),
    },
    {
        accessorKey: 'action',
        meta: {
            align: 'right',
            flex: 0.2,
            overflow: 'visible',
        },
        cell: tableRendererWrapper(RenderProfileActionButton),
    },
];
const TableWithLazyLoading = withLazyLoading(Table);
const GetFollowersQueryTake = 10;
const ProfileFollowers = () => {
    const { profileId } = useProfileOutletContext();
    const { t } = useTranslation();
    const [queryArgs, setQueryArgs] = useState({
        id: profileId,
        page: 1,
        pageSize: GetFollowersQueryTake,
    });
    const loadMore = () => {
        setQueryArgs((previous) => (Object.assign(Object.assign({}, previous), { skip: previous.page + GetFollowersQueryTake })));
    };
    const { data, isLoading, isFetching, isError, } = useGetProfileFollowersQuery(queryArgs, {
        skip: typeof profileId === 'undefined',
    });
    // const hasMore = useMemo(
    //     () => IsDefined(data) && (data.length % GetFollowersQueryTake === 0),
    //     [data],
    // )
    useEffect(() => {
        setQueryArgs((previous) => (Object.assign(Object.assign({}, previous), { id: profileId, skip: 0 })));
    }, [profileId, setQueryArgs]);
    return (_jsx(Container, { children: _jsxs(TableContainer, { children: [_jsx(Title, {}), _jsx(LoadingIndicator
                // isLoading={isLoading}
                , { 
                    // isLoading={isLoading}
                    isLoading: false, takeFullContainerSpace: true, minHeight: LoaderContainerMinHeight })] }) }));
};
export default ProfileFollowers;
