import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import { BodySemiBold } from '@shared/ui/display/Typography';
import Button from '@shared/ui/buttons/Button';
import { useSiteContentSidebar } from '@shared/ui/sidebars/SiteContentSidebar';
import { useGetPortfoliosQuery } from '@shared/api/portfolio';
import { ContainerColumn, NarrowSection } from '@shared/ui/display/Containers';
import { mapPortfoliosListFromResponse, ProfilePortfolioCard } from '@entities/portfolio';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import ScrollUpButton from '@shared/ui/navigation/ScrollUpButton';
import Filters from '../ListFilters';
import PortfolioInfoSidebar from '../PortfolioInfoSidebar';
import EmptyView from './EmptyView';
const Container = styled(ContainerColumn)(() => ({
    position: 'relative',
    alignItems: 'center',
}));
const ContentContainer = NarrowSection.withComponent(styled(ContainerColumn)((props) => ({
    alignItems: 'center',
    paddingTop: props.theme.spacing_sizes.l,
    paddingBottom: props.theme.spacing_sizes.l,
    gap: props.theme.spacing_sizes.l
})));
const PortfoliosContainer = styled.div((props) => ({
    display: 'grid',
    width: '100%',
    gridTemplateColumns: props.viewType === 'card' ? 'repeat(3, minmax(0, 1fr))' : 'auto',
    justifyContent: props.viewType === 'card' ? 'space-between' : 'unset',
    gridRowGap: props.theme.spacing_sizes.l,
    gridColumnGap: props.theme.spacing_sizes.l,
}));
const PortfoliosPerPage = 12;
const MarketplaceList = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { isSidebarOpen, openSidebar, closeSidebar } = useSiteContentSidebar();
    const [viewType, setViewType] = useState('card');
    const [page, setPage] = useState(1);
    const { data, isLoading, isFetching } = useGetPortfoliosQuery({
        page,
        page_size: PortfoliosPerPage,
        status: 'ACTIVE',
    });
    const portfolios = useMemo(() => {
        if (isLoading) {
            return [undefined, undefined, undefined]; // undefined is used to display skeleton while loading
        }
        const portfoliosList = data ? mapPortfoliosListFromResponse(data.items) : [];
        return portfoliosList.filter(p => p.assessmentPassed);
    }, [
        data,
        isLoading
    ]);
    const hasMore = useMemo(() => {
        if (!data) {
            return false;
        }
        return data.total <= portfolios.length;
    }, [data, portfolios]);
    const loadMore = () => {
        setPage(page + 1);
    };
    const toggleSidebar = () => {
        if (isSidebarOpen) {
            closeSidebar();
        }
        else {
            openSidebar();
        }
    };
    const onFiltersChange = (filters) => {
        setViewType(filters.activeView);
    };
    return (_jsxs(Container, { children: [_jsx(Filters, { onChange: onFiltersChange }), _jsxs(ContentContainer, { children: [portfolios.length > 0 && (_jsx(PortfoliosContainer, { viewType: viewType, children: portfolios.map((portfolio, idx) => (_jsx(ProfilePortfolioCard, { viewType: viewType, portfolio: portfolio, isLoading: isLoading, onClick: toggleSidebar }, (portfolio === null || portfolio === void 0 ? void 0 : portfolio.id) || idx))) })), 
                    // on initial load display portfolios with skeleton
                    // on subsequent loads (loadMore) display loader
                    isFetching &&
                        !isLoading && (_jsx(LoadingIndicator, { isLoading: true })), !isFetching && (_jsxs(_Fragment, { children: [!portfolios.length && (_jsx(EmptyView, {})), portfolios.length > 0 && (_jsxs(_Fragment, { children: [hasMore &&
                                        (_jsx(Button, { onClick: loadMore, children: t('actions.show_more', { ns: 'common' }) })), !hasMore && (_jsx(BodySemiBold, { color: theme.palette.text.secondary, children: t('marketplace.all_portfolio_view.all_portfolios_loaded') }))] }))] }))] }), _jsx(ScrollUpButton, {}), _jsx(PortfolioInfoSidebar, { handleClose: closeSidebar })] }));
};
export default MarketplaceList;
