import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ReactComponent as ImageIcon } from '@icons/wolfkit-light/image-light.svg';
import FileInput from '@shared/ui/inputs/FileInput';
import Button from '@shared/ui/buttons/Button';
import { StepContainer, Title } from '@widgets/sidebars/shared';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { getFileInputErrors } from '@features/profile/profile-edit';
import { updateCreateTraderValues } from '@entities/profile';
import useTraderCreation from '@widgets/sidebars/CreateTraderProfile/api/useTraderCreation';
import { FieldLabel, StepTitleCounter, } from '../shared';
const FileInputContainer = styled.div(props => ({
    marginBottom: `${props.theme.spacing_sizes.xs * 5.25}px`,
}));
const InputsContainer = styled.div();
const UploadDocuments = ({ isCreating, goToNextStep }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { createTraderProfileValues } = useAppSelector(state => state.profile);
    const { isVerifyTraderRequestLoading } = useTraderCreation();
    const documentType = createTraderProfileValues === null || createTraderProfileValues === void 0 ? void 0 : createTraderProfileValues.docType;
    const fileInputErrors = useMemo(() => getFileInputErrors(t), [t]);
    const isBackSideImageRequired = documentType === 'ID_CARD' || documentType === 'DRIVE_LICENCE';
    const onMainSideImageChange = (filesList) => {
        dispatch(updateCreateTraderValues({ docMainSideImage: filesList[0] }));
    };
    const onBackSideImageChange = (filesList) => {
        dispatch(updateCreateTraderValues({ docBackSideImage: filesList[0] }));
    };
    const handleBtnClick = () => {
        if (goToNextStep) {
            goToNextStep();
        }
    };
    return (_jsx(StepContainer, { title: (_jsxs(Title, { children: [_jsx(StepTitleCounter, { children: "4/4" }), t('profile.create_trader.steps.documents_upload.title', { ns: 'common' })] })), content: (_jsxs(InputsContainer, { children: [_jsxs(FileInputContainer, { children: [_jsx(FieldLabel, { children: t('profile.create_trader.steps.documents_upload.main_side_title', { ns: 'common' }) }), _jsx(FileInput, { innerLabelText: t('profile.create_trader.steps.documents_upload.instructions', { ns: 'common' }), description: t('profile.create_trader.steps.documents_upload.image_requirements', { ns: 'common' }), maxSize: 10000000, icon: _jsx(ImageIcon, {}), mimeType: ['image/gif', 'image/jpeg', 'image/png'], errors: fileInputErrors, size: 'small', onChange: onMainSideImageChange })] }), isBackSideImageRequired && (_jsxs(_Fragment, { children: [_jsx(FieldLabel, { children: t('profile.create_trader.steps.documents_upload.back_side_title', { ns: 'common' }) }), _jsx(FileInput, { innerLabelText: t('profile.create_trader.steps.documents_upload.instructions', { ns: 'common' }), description: t('profile.create_trader.steps.documents_upload.image_requirements', { ns: 'common' }), maxSize: 10000000, icon: _jsx(ImageIcon, {}), mimeType: ['image/gif', 'image/jpeg', 'image/png'], errors: fileInputErrors, size: 'small', onChange: onBackSideImageChange })] }))] })), buttons: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: handleBtnClick, isLoading: isCreating || isVerifyTraderRequestLoading, disabled: isCreating ||
                isVerifyTraderRequestLoading ||
                !(createTraderProfileValues === null || createTraderProfileValues === void 0 ? void 0 : createTraderProfileValues.docMainSideImage) ||
                (isBackSideImageRequired && !(createTraderProfileValues === null || createTraderProfileValues === void 0 ? void 0 : createTraderProfileValues.docBackSideImage)), children: t('profile.create_trader.steps.documents_upload.continue_btn_text', { ns: 'common' }) })) }));
};
export default UploadDocuments;
