var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWizard } from '@shared/ui/misc/Wizard';
import { AuthenticationMethod } from '@entities/auth';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import useAuth from '@pages/auth/lib/useAuth';
import FormWrapper from '@pages/auth/ui/components/FormWrapper';
import AuthModeSwitcher from '@pages/auth/ui/components/AuthModeSwitcher';
import EmailForm from './EmailForm';
const Credentials = () => {
    const { authMethod, loginReq, openResetPassword, setConfirmationEmail, isSigningIn, } = useAuth();
    const { t } = useTranslation();
    const { goBack, goNext, reset } = useWizard();
    const { navigate, routes } = useAppNavigation();
    const handleSignIn = useCallback((_a) => __awaiter(void 0, [_a], void 0, function* ({ email, password }) {
        setConfirmationEmail(email);
        const res = yield loginReq({
            email,
            password,
        });
        const isLoginResponse = (r) => (!!r.data);
        if (isLoginResponse(res)) {
            if (res.data.verified) {
                navigate(routes.MARKETPLACE);
            }
            else {
                goNext();
            }
            return true;
        }
        return { error: res === null || res === void 0 ? void 0 : res.error };
    }), [
        loginReq,
        navigate,
        routes,
        goNext,
        setConfirmationEmail,
    ]);
    const renderInner = useCallback(() => {
        if (authMethod === null)
            return null;
        switch (authMethod) {
            case AuthenticationMethod.EMAIL:
                return (_jsx(EmailForm, { onSubmit: handleSignIn, goToResetPassword: openResetPassword, isSigningIn: isSigningIn }));
            case AuthenticationMethod.PHONE_NUMBER:
                return null;
            default:
                return null;
        }
    }, [
        authMethod,
        isSigningIn,
        handleSignIn,
        openResetPassword,
    ]);
    return (_jsx(FormWrapper, { renderInnerForm: renderInner(), titleText: t('auth.sign_in.steps.credentials.email.title', { ns: 'common' }), renderLinks: _jsx(AuthModeSwitcher, {}), backLinkProps: {
            text: t('auth.sign_in.steps.backlink_text', { ns: 'common' }),
            onClick: goBack,
        }, handleReset: reset, showNavigation: true }));
};
export default Credentials;
