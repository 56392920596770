import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { Body } from '@shared/ui/display/Typography';
import { BalancePieChart } from '@entities/current-user';
import { getDataAllPortfolios, getDataAllWallets, getDataMixed, } from './mocks';
const Container = styled.div((props) => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    gap: props.theme.spacing_sizes.l,
}));
const PreviewContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.m,
}));
const Overview = () => {
    const width = 200;
    const height = 200;
    return (_jsxs(Container, { children: [_jsxs(PreviewContainer, { children: [_jsx(Body, { children: "All Portfolios (Trader)" }), _jsx(BalancePieChart, { width: width, height: height, wallets: getDataAllPortfolios(), priorityWalletType: 'portfolio' })] }), _jsxs(PreviewContainer, { children: [_jsx(Body, { children: "2 Portfolio (Trader)" }), _jsx(BalancePieChart, { width: width, height: height, wallets: getDataMixed(), priorityWalletType: 'portfolio' })] }), _jsxs(PreviewContainer, { children: [_jsx(Body, { children: "All Wallets (Client)" }), _jsx(BalancePieChart, { width: width, height: height, wallets: getDataAllWallets(), priorityWalletType: 'wallet' })] }), _jsxs(PreviewContainer, { children: [_jsx(Body, { children: "2 Wallets (Client)" }), _jsx(BalancePieChart, { width: width, height: height, wallets: getDataMixed(), priorityWalletType: 'wallet' })] }), _jsxs(PreviewContainer, { children: [_jsx(Body, { children: "API error" }), _jsx(BalancePieChart, { width: width, height: height, wallets: [], priorityWalletType: 'portfolio', hasErrors: true })] }), _jsxs(PreviewContainer, { children: [_jsx(Body, { children: "Empty" }), _jsx(BalancePieChart, { width: width, height: height, wallets: [], priorityWalletType: 'portfolio' })] })] }));
};
export default Overview;
