import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { ContainerRow } from '@shared/ui/display/Containers';
import Button from '@shared/ui/buttons/Button';
import { StepContainer, StepText, Title } from '@widgets/sidebars/shared';
const ChooseNetworkContainer = styled(ContainerRow)();
const ChooseNetwork = () => {
    const { t } = useTranslation();
    return (_jsx(SidebarInner, { padding: '50px 30px', children: _jsx(ChooseNetworkContainer, { children: _jsx(StepContainer, { title: _jsx(Title, { children: t('overview.balance_management.choose_network.title') }), content: (_jsx(StepText, { stepNumber: t('overview.balance_management.choose_network.step'), text: t('overview.balance_management.choose_network.text') })), buttons: (_jsx(Button, { children: t('overview.balance_management.choose_network.continue_btn') })) }) }) }));
};
export default ChooseNetwork;
