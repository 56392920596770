import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import { useEffect } from 'react';
const AssetTooltipBackground = animated(styled('span', { shouldForwardProp: propName => isPropValid(propName) })((props) => ({
    display: 'block',
    position: 'absolute',
    left: 0,
    height: '100%',
    backgroundColor: props.color,
    zIndex: -1,
})));
const MinWidth = 3;
const MaxWidth = 60;
const BorderRadiusDefault = 0;
const BorderRadiusHovered = 4;
const AssetTooltipContainer = animated(styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 9px',
    height: 24,
    cursor: 'default',
    // color: props.isHovered ? '#fff' : props.theme.palette.text.secondary,
    zIndex: 1,
    // [`${AssetTooltipBackground}`]: {
    //     width: props.isHovered ? '100%' : 3,
    //     backgroundColor: props.color,
    //     borderRadius: 4,
    //     zIndex: props.isHovered ? -1 : 0,
    // },
    // '&:hover': {
    //     color: '#fff',
    //     [`${AssetTooltipBackground}`]: {
    //         width: '100%',
    //         zIndex: -1,
    //     },
    // },
})));
const PortfolioAssetTooltip = ({ id, color: tooltipColor, value, onMouseEnter, onMouseLeave, isHovered, }) => {
    const theme = useTheme();
    const [{ width, color, borderRadius }, api] = useSpring(() => ({
        width: MinWidth,
        color: theme.palette.text.secondary,
        borderRadius: BorderRadiusDefault,
        config: {
            mass: 1.5,
            tension: 300,
            friction: 40,
        },
    }), []);
    useEffect(() => {
        if (isHovered) {
            api.start({
                from: {
                    width: MinWidth,
                    color: theme.palette.text.secondary,
                    borderRadius: BorderRadiusDefault,
                },
                to: {
                    width: MaxWidth,
                    color: '#fff',
                    borderRadius: BorderRadiusHovered,
                },
            });
        }
        else {
            api.start({
                from: {
                    width: MaxWidth,
                    color: '#fff',
                    borderRadius: BorderRadiusHovered,
                },
                to: {
                    width: MinWidth,
                    color: theme.palette.text.secondary,
                    borderRadius: BorderRadiusDefault,
                },
            });
        }
    }, [isHovered, theme, api]);
    const handleMouseEnter = () => {
        api.start({
            from: {
                width: MinWidth,
                color: theme.palette.text.secondary,
                borderRadius: BorderRadiusDefault,
            },
            to: {
                width: MaxWidth,
                color: '#fff',
                borderRadius: BorderRadiusHovered,
            },
        });
        onMouseEnter(id);
    };
    const handleMouseLeave = () => {
        api.start({
            from: {
                width: MaxWidth,
                color: '#fff',
                borderRadius: BorderRadiusHovered,
            },
            to: {
                width: MinWidth,
                color: theme.palette.text.secondary,
                borderRadius: BorderRadiusDefault,
            },
        });
        onMouseLeave(id);
    };
    return (_jsxs(AssetTooltipContainer, { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, style: {
            color: color.to((colorValue) => colorValue)
        }, children: [_jsx(AssetTooltipBackground, { style: {
                    width: width.to(widthValue => widthValue),
                    borderRadius: borderRadius.to((radiusValue) => radiusValue)
                }, color: tooltipColor }), `${value}%`] }, id));
};
export default PortfolioAssetTooltip;
