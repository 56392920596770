import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { formatUSD } from '@shared/lib/numbers';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { ReactComponent as BitcoinIcon } from '@icons/custom/bitcoin.svg';
import { ReactComponent as CreditCardIcon } from '@icons/wolfkit-light/credit-card-light.svg';
import { ReactComponent as ArrowIcon } from '@icons/wolfkit-light/arrow-2-cw-light.svg';
import { ReactComponent as ShieldIcon } from '@icons/wolfkit-light/shield-with-circle-light.svg';
import Button from '@shared/ui/buttons/Button';
import { ContainerRow } from '@shared/ui/display/Containers';
import Icon from '@shared/ui/icons/Icon';
import IconText from '@shared/ui/icons/IconText';
import { BodyMedium } from '@shared/ui/display/Typography';
import SelectButton from '@shared/ui/buttons/SelectButton';
import { Divider, StepFormContainer, StepText, Title, } from '@widgets/sidebars/shared';
import { useWizard } from '@shared/ui/misc/Wizard';
import { AddBalanceMethod, BalanceManagementSteps, } from '../../../types';
const IconTextStyled = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.m,
    '& .MuiTypography-root': {
        color: props.theme.palette.text.primary,
    },
}));
const PaymentBannerContainer = styled.div(props => ({
    backgroundColor: '#F8FAFF',
    padding: `${props.theme.spacing_sizes.l}px ${props.theme.spacing_sizes.l}px 
              ${props.theme.spacing_sizes.l}px ${props.theme.spacing_sizes.xm}px`,
    borderRadius: 6,
}));
const BannerIconText = styled(IconTextStyled)(() => ({
    '& .MuiTypography-root': {
        whiteSpace: 'normal',
    },
}));
const AssetsAmount = styled(BodyMedium)(props => ({
    '&.MuiTypography-root': {
        color: props.theme.palette.text.secondary,
    },
}));
const ConvertAssetsInner = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
}));
const PaymentMethodBanner = () => {
    const { t } = useTranslation();
    return (_jsx(PaymentBannerContainer, { children: _jsx(BannerIconText, { IconComponent: (_jsx(Icon, { IconComponent: ShieldIcon, size: 32, keepOriginalColor: true })), text: t('overview.balance_management.payment_method.banner_text') }) }));
};
const PaymentMethod = ({ exchangeWallet, onPaymentMethodFormSubmit, onSidebarClose, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { goTo } = useWizard();
    const { setValue, watch, handleSubmit, } = useForm({
        defaultValues: {
            paymentMethod: AddBalanceMethod.None,
        },
    });
    const totalAssetsInUSD = Object.keys(exchangeWallet.assets).reduce((sum, asset) => sum + parseFloat(exchangeWallet.assets[asset].amount_usd), 0);
    const paymentMethod = watch('paymentMethod');
    const onSubmit = (formData) => {
        onPaymentMethodFormSubmit(formData);
        if (formData.paymentMethod === AddBalanceMethod.AddByCard) {
            goTo(BalanceManagementSteps.EnterAmount);
        }
        else if (formData.paymentMethod === AddBalanceMethod.ConvertAssets) {
            goTo(BalanceManagementSteps.ConvertAssets);
        }
    };
    const onMethodSelect = (optionValue) => {
        if (typeof optionValue === 'string') {
            setValue('paymentMethod', optionValue);
        }
    };
    return (_jsx(SidebarInner, { padding: '50px 30px', displayCancelBtn: true, cancelBtnProps: {
            onClick: onSidebarClose,
        }, children: _jsx(StepFormContainer, { title: (_jsx(Title, { children: t('overview.balance_management.payment_method.title') })), onSubmit: handleSubmit(onSubmit), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { stepNumber: t('overview.balance_management.payment_method.step'), text: t('overview.balance_management.payment_method.text') }), _jsx(SelectButton, { onChange: onMethodSelect, options: [
                            {
                                value: AddBalanceMethod.AddByCrypto,
                                element: _jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { IconComponent: BitcoinIcon, color: theme.palette.primary.main })), text: t('overview.balance_management.payment_method.options.add_by_crypto') }),
                            },
                            {
                                value: AddBalanceMethod.AddByCard,
                                element: _jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { IconComponent: CreditCardIcon, color: theme.palette.primary.main })), text: t('overview.balance_management.payment_method.options.add_by_card') }),
                            },
                        ], value: paymentMethod || undefined, fullWidth: true }), _jsx(Divider, {}), _jsx(SelectButton, { onChange: onMethodSelect, options: [
                            {
                                value: AddBalanceMethod.ConvertAssets,
                                element: (_jsxs(ConvertAssetsInner, { children: [_jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { IconComponent: ArrowIcon, color: theme.palette.primary.main })), text: t('overview.balance_management.payment_method.options.convert_assets') }), _jsx(AssetsAmount, { children: formatUSD(totalAssetsInUSD) })] })),
                            },
                        ], value: paymentMethod || undefined, fullWidth: true })] })), contentJustify: 'start', contentFlex: 1, containerGap: 60, buttons: (_jsxs(_Fragment, { children: [_jsx(PaymentMethodBanner, {}), _jsx(Button, { type: 'submit', size: 'large', disabled: Boolean(paymentMethod === null || typeof paymentMethod === 'undefined'), fullWidth: true, children: t('overview.balance_management.payment_method.continue_btn') })] })), buttonsGap: 24 }) }));
};
export default PaymentMethod;
