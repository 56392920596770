import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import isPropValid from '@emotion/is-prop-valid';
import { formatNumber } from '@shared/lib/numbers';
import AvatarImage from '@shared/ui/display/AvatarImage';
import Tooltip from '@shared/ui/tooltips/Tooltip';
import Badge from '@shared/ui/badges/Badge';
import { ReactComponent as TraderIcon } from '@icons/wolfkit-light/crown-light.svg';
import { ReactComponent as ErrorIconComponent } from '@icons/wolfkit-light/alert-circle-light.svg';
import { BodyMedium, BodyMediumBold, BodyMediumSemiBold } from '@shared/ui/display/Typography';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import Icon from '@shared/ui/icons/Icon';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import TraderBadge from '@shared/ui/badges/TraderBadge';
import { ContainerColumn } from '@shared/ui/display/Containers';
import VerificationIcon from '@shared/ui/icons/VerificationIcon';
const IncomeBadge = withPercentageIncomeBadge(BodyMediumBold);
const ProfitReviewContainer = styled('div', { shouldForwardProp: propName => isPropValid(propName) })(props => ({
    borderRadius: '6px',
    border: '1px solid #EFF1F3',
    backgroundColor: '#FBFBFB',
    padding: `${props.theme.spacing_sizes.m}px ${props.theme.spacing_sizes.xm}px`,
    width: '100%',
    boxSizing: 'border-box',
    maxWidth: props.fullWidth ? 'unset' : 182,
}));
const AVATAR_IMAGE_SIZES = {
    small: 64,
    medium: 128,
    thumbnail: 36,
    large: 160,
    activityImage: 18,
    avatarSwitcher: 20,
};
const AvatarImageContainer = styled('div', { shouldForwardProp: propName => isPropValid(propName) })((props) => ({
    width: AVATAR_IMAGE_SIZES[props.size],
    minWidth: AVATAR_IMAGE_SIZES[props.size],
    maxWidth: AVATAR_IMAGE_SIZES[props.size],
    height: AVATAR_IMAGE_SIZES[props.size],
    borderRadius: '50%',
}));
const UserInfo = styled.div((props) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    paddingBottom: props.theme.spacing_sizes.m,
    borderBottom: `1px solid ${props.theme.customColors.button.pill.border}`,
    marginBottom: props.theme.spacing_sizes.m,
    fontWeight: 500,
    cursor: 'default',
    userSelect: 'none',
    color: props.theme.palette.text.secondary,
}));
const ProfitInfo = styled(ContainerColumn)((props) => ({
    gap: props.theme.spacing_sizes.s,
    fontSize: '12px',
    lineHeight: '20px',
    cursor: 'default',
    userSelect: 'none',
    flex: 1,
}));
const AssetsValue = styled.div(() => ({
    display: 'flex',
    alignItems: 'baseline',
    gap: '0 10px',
    lineHeight: '20px',
}));
const ProfitValue = styled.div(() => ({
    display: 'flex',
    alignItems: 'baseline',
    gap: '0 10px',
}));
const ErrorIcon = styled(ErrorIconComponent)(props => ({
    path: {
        fill: props.theme.palette.text.secondary,
    },
}));
const BodyWithEllipsis = styled(withTextEllipsis(BodyMediumSemiBold))(props => ({
    color: props.theme.palette.text.primary,
}));
const UserText = styled.span();
const ReviewDetailsContainer = styled('div', { shouldForwardProp: propName => isPropValid(propName) })(props => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: props.detailsFlexDirection,
    gap: props.theme.spacing_sizes.m,
}));
const MiniTraderBadge = ({ single = undefined }) => {
    const { t } = useTranslation();
    return (_jsx(Badge, { variant: 'tinted', color: 'warning', startAdornment: (_jsx(Icon, { size: 14, IconComponent: TraderIcon })) }));
};
const ReviewBadge = ({ profileType = undefined, single = false, }) => {
    const { t } = useTranslation();
    const isTrader = profileType && profileType === 'TRADER';
    if (single && isTrader) {
        return _jsx(TraderBadge, { size: 'small', textSize: 12, textHeight: '18px' });
    }
    if (isTrader) {
        return _jsx(MiniTraderBadge, {});
    }
    return _jsx(UserText, { children: t('portfolio_overview.user', { ns: 'common' }) });
};
const ProfitReview = ({ profile = undefined, info = undefined, single = false, fullWidth = true, showVerificationIcon = true, avatarSize = 'thumbnail', detailsFlexDirection = 'row', }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    return (_jsxs(ProfitReviewContainer, { fullWidth: fullWidth, children: [_jsxs(UserInfo, { children: [_jsx(AvatarImageContainer, { size: avatarSize, children: _jsx(AvatarImage, { publicName: (profile === null || profile === void 0 ? void 0 : profile.displayName) || '', imageUrl: (_a = profile === null || profile === void 0 ? void 0 : profile.profilePicture) === null || _a === void 0 ? void 0 : _a.thumbnail, size: avatarSize }) }), _jsx(BodyWithEllipsis, { tooltipText: `${(profile === null || profile === void 0 ? void 0 : profile.displayName) || ''}`, children: `${(profile === null || profile === void 0 ? void 0 : profile.displayName) || ''}` }), (profile === null || profile === void 0 ? void 0 : profile.type) === 'TRADER' && showVerificationIcon &&
                        _jsx(VerificationIcon, { verified: profile.isVerifiedTrader }), _jsx(ReviewBadge, { profileType: profile === null || profile === void 0 ? void 0 : profile.type, single: single })] }), _jsxs(ReviewDetailsContainer, { detailsFlexDirection: detailsFlexDirection, children: [_jsxs(ProfitInfo, { children: [Trans({
                                ns: 'common',
                                i18nKey: 'portfolio_overview.sections.profit_review.profit_period',
                                values: { period: '27 days' },
                            }), _jsxs(ProfitValue, { children: [_jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: ((_b = info === null || info === void 0 ? void 0 : info.profit) === null || _b === void 0 ? void 0 : _b.percentage) || 0, displayInt: !((_c = info === null || info === void 0 ? void 0 : info.profit) === null || _c === void 0 ? void 0 : _c.percentage), positiveColor: (typeof ((_d = info === null || info === void 0 ? void 0 : info.profit) === null || _d === void 0 ? void 0 : _d.percentage) === 'number' && ((_e = info === null || info === void 0 ? void 0 : info.profit) === null || _e === void 0 ? void 0 : _e.percentage) !== 0) ?
                                            'primary' : 'neutral' }), ((_f = info === null || info === void 0 ? void 0 : info.profit) === null || _f === void 0 ? void 0 : _f.percentage) &&
                                        _jsx(Tooltip, { title: `${formatNumber((info === null || info === void 0 ? void 0 : info.profit.value) || 0)} ${(info === null || info === void 0 ? void 0 : info.profit.currency) || ''}`, placement: 'top', children: _jsx(Icon, { IconComponent: ErrorIcon, size: 14 }) })] })] }), _jsxs(ProfitInfo, { children: [t('portfolio_overview.sections.profit_review.assets', { ns: 'common' }), _jsx(AssetsValue, { children: _jsxs(Badge, { variant: 'tinted', color: 'primary', children: [_jsx(BodyMediumBold
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        , { 
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            component: 'span', children: formatNumber((info === null || info === void 0 ? void 0 : info.assets.value) || 0, {
                                                maximumFractionDigits: (typeof (info === null || info === void 0 ? void 0 : info.assets.value) === 'undefined' ||
                                                    (info === null || info === void 0 ? void 0 : info.assets.value) === 0) ? 0 : 2
                                            }) }), _jsx(BodyMedium
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        , { 
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            component: 'span', children: "USDT" })] }) })] })] })] }));
};
export default ProfitReview;
