import * as yup from 'yup';
const checkWallet = ({ translateFn, wallets, minAmount, }) => (walletId) => {
    if (walletId.length) {
        const selectedWalletData = wallets.find(({ id }) => id === walletId);
        if (selectedWalletData) {
            const isEnoughAmount = parseFloat(selectedWalletData.totalAssetsUsd) > minAmount;
            // its necessary to have context here
            // eslint-disable-next-line func-names, consistent-return
            return yup.string().test('', 'check-amount', function () {
                if (!isEnoughAmount) {
                    return this.createError({
                        message: translateFn('portfolio.create_portfolio.wallet_errors.insufficient_amount', { ns: 'common' }),
                    });
                }
                return true;
            });
        }
        return yup.string().length(1, '');
    }
    return yup.string().length(1, '');
};
export const getSelectExchangeSchema = ({ translateFn, wallets, minAmount, }) => yup.object().shape({
    walletId: yup.lazy(checkWallet({
        translateFn,
        wallets,
        minAmount,
    })),
});
export const enterInvestmentValidationSchema = yup.object().shape({
    investAmount: yup.number(),
    stopCopyingLimit: yup.number()
});
export const selectQueueValidationSchema = yup.object().shape({
    subscriptionPrice: yup.number(),
});
