import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { calculatePercentage, clamp } from '@utils/math';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import AREAS_COLORS from '../../lib/constants';
import AssetBarItem from './AssetBarItem';
import AssetsTooltips from './AssetsTooltips';
const getAssetBarBorderRadiusPosition = (index, assetsCount) => {
    if (assetsCount === 1) {
        return 'left-and-right';
    }
    if (index === 0) {
        return 'left';
    }
    if (index === assetsCount - 1) {
        return 'right';
    }
    return 'none';
};
const Container = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 1.25,
}));
const AssetsBarContainer = styled.div(() => ({
    display: 'flex',
    width: '100%',
    height: 6,
}));
const ProgressBarError = styled.div(props => ({
    width: '100%',
    height: 8,
    backgroundColor: props.theme.palette.error.main,
    borderRadius: 6,
}));
const IndividualAssetsToDisplayCount = 5;
const ExchangeWalletAssetsBar = ({ wallet = undefined, disabled = undefined, isLoading = false, }) => {
    const { t } = useTranslation();
    const assetsToDisplay = useMemo(() => {
        // NOTE: without shallow-copy array.sort mutating the original array
        // which causes an error:
        // TypeError: Cannot assign to read only property '0' of object '[object Array]'
        const assets = Object.assign({}, ((wallet === null || wallet === void 0 ? void 0 : wallet.assets) || {}));
        const assetsSortedByUSDTDesc = Object.keys(assets).sort((a, b) => parseFloat(assets[b].amount_usdt) - parseFloat(assets[a].amount_usdt));
        const totalBalanceUSDT = assetsSortedByUSDTDesc
            .reduce((totalBalance, asset) => totalBalance + parseFloat(assets[asset].amount_usdt), 0);
        const topAssets = [];
        assetsSortedByUSDTDesc
            .slice(0, IndividualAssetsToDisplayCount)
            .forEach((asset) => {
            var _a;
            // NOTE: should only display assets that have some balance on it
            if (parseFloat((_a = assets[asset]) === null || _a === void 0 ? void 0 : _a.amount_usdt) > 0) {
                topAssets.push({
                    assetName: assets[asset].type,
                    balanceUSDT: parseFloat(assets[asset].amount_usdt),
                    percentage: calculatePercentage(parseFloat(assets[asset].amount_usdt), totalBalanceUSDT),
                });
            }
        });
        const topAssetsBalanceUSDT = topAssets
            .reduce((totalBalance, assetData) => totalBalance + assetData.balanceUSDT, 0);
        const otherAssetsBalanceUSDT = totalBalanceUSDT - topAssetsBalanceUSDT;
        if (otherAssetsBalanceUSDT > 0) {
            topAssets.push({
                assetName: t('overview.exchange_wallets.card.others'),
                balanceUSDT: otherAssetsBalanceUSDT,
                percentage: calculatePercentage(otherAssetsBalanceUSDT, totalBalanceUSDT),
                displayApproximateSign: true,
            });
        }
        return topAssets;
    }, [wallet, t]);
    return (_jsxs(Container, { style: {
            opacity: disabled ? 0.5 : 1,
        }, children: [_jsx(WithSkeleton, { isLoading: isLoading, width: '100%', height: 8, style: {
                    minHeight: 8,
                    maxHeight: 8,
                }, children: _jsxs(AssetsBarContainer, { children: [!assetsToDisplay.length && (_jsx(ProgressBarError, {})), assetsToDisplay.map((asset, index) => (_jsx(AssetBarItem, { color: AREAS_COLORS[index], width: clamp(asset.percentage, 1, 100), borderRadiusPosition: getAssetBarBorderRadiusPosition(index, assetsToDisplay.length) }, asset.assetName)))] }) }), _jsx(AssetsTooltips, { assets: assetsToDisplay, isLoading: isLoading })] }));
};
export default ExchangeWalletAssetsBar;
