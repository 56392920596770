import { matchPathPartial } from '@app/routing/helpers';
import { RouteNames } from '@app/routing/types';
// eslint-disable-next-line import/prefer-default-export
export const matchPathToHomeTab = (pathname) => {
    let tab;
    if (matchPathPartial(RouteNames.MY_PROFILE, pathname)) {
        tab = 'my_profile';
    }
    else if (matchPathPartial(RouteNames.HOME_OVERVIEW, pathname)) {
        tab = 'overview';
    }
    else if (matchPathPartial(RouteNames.HOME_BALANCE, pathname)) {
        tab = 'platform_balance';
    }
    return tab;
};
