import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { cancelEditStep, editStep } from '@entities/portfolio';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { ReactComponent as UpdateIcon } from '@icons/wolfkit-light/arrow-2-cw-light.svg';
import { ReactComponent as EditIcon } from '@icons/wolfkit-light/pencil-light.svg';
import { IsDefined } from '@utils/js-ts';
import { BodySemiBold } from '@shared/ui/display/Typography';
import QuestionMarkTooltip from '@shared/ui/tooltips/QuestionMarkTooltip';
import Button from '@shared/ui/buttons/Button';
import Icon from '@shared/ui/icons/Icon';
import ErrorMessage from '@shared/ui/errors/ErrorMessage';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
const getDisabledProps = (disabled) => disabled ? ({
    opacity: 0.5,
    userSelect: 'none',
    '& *': {
        cursor: 'default!important',
    }
}) : ({});
const StepContainer = styled(ContainerColumn, {
    shouldForwardProp: propName => propName !== 'completelyDisabled' &&
        propName !== 'padding' &&
        propName !== 'stepStatus'
})(props => (Object.assign({ height: 'auto', gap: props.theme.spacing_sizes.m, paddingBottom: props.stepStatus === 'completed' || props.stepStatus === 'edit' ||
        props.stepStatus === 'initialized_before' ? props.theme.spacing_sizes.l :
        undefined }, getDisabledProps(props.completelyDisabled))));
const TitleContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const ButtonsContainer = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'stepStatus' })(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    marginBottom: props.stepStatus === 'first_edit' ? props.theme.spacing_sizes.m : undefined,
}));
const CollapsedContainer = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'error' })(props => ({
    height: 'auto',
    minHeight: 48,
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.xs,
    padding: props.theme.spacing_sizes.m,
    background: props.error ? props.theme.customColors.container.errorLight :
        props.theme.customColors.menu.item.select,
    borderRadius: props.theme.shape.borderRadius,
    boxSizing: 'border-box',
}));
const IconStyled = styled(Icon)(() => ({
    cursor: 'pointer',
    flexGrow: 0,
    flexShrink: 0,
}));
const BidToQueueStep = ({ stepName, title, tooltipText = undefined, loading = false, buttonsDisabled = false, completelyDisabled = false, collapsedContent = undefined, children = undefined, errorMessage = '', onAction = () => { }, containerPadding = undefined, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { steps, } = useAppSelector(state => state.portfolioSubscription);
    const { isCollapsed, isInEdit, isInitiallyEditting, isReadyForEditting, } = useMemo(() => ({
        isCollapsed: steps[stepName].status === 'completed' || steps[stepName].status === 'initialized_before',
        isInEdit: steps[stepName].status === 'edit',
        isReadyForEditting: steps[stepName].status === 'completed',
        isInitiallyEditting: steps[stepName].status === 'first_edit',
    }), [stepName, steps]);
    const onEdit = () => {
        dispatch(editStep(stepName));
        onAction('edit');
    };
    const onUpdate = () => {
        onAction('update');
    };
    const onCancel = () => {
        dispatch(cancelEditStep(stepName));
        onAction('cancel');
    };
    const onContainerClick = (event) => {
        if (completelyDisabled) {
            event.stopPropagation();
        }
    };
    const onContainerMouseOver = (event) => {
        if (completelyDisabled) {
            event.stopPropagation();
        }
    };
    return (_jsxs(StepContainer, { padding: containerPadding, completelyDisabled: completelyDisabled, onClickCapture: onContainerClick, onMouseOverCapture: onContainerMouseOver, stepStatus: steps[stepName].status, children: [_jsxs(TitleContainer, { children: [_jsx(BodySemiBold, { color: theme.palette.text.secondary, children: `${t('portfolio.subscription.step', { ns: 'translation' })} ${steps[stepName].order + 1}.` }), _jsx(BodySemiBold, { children: title }), IsDefined(tooltipText) && (_jsx(QuestionMarkTooltip, { title: tooltipText, placement: 'top-start', arrow: true }))] }), isCollapsed && (_jsxs(_Fragment, { children: [collapsedContent &&
                        _jsxs(CollapsedContainer, { error: !!errorMessage, children: [collapsedContent, isReadyForEditting && _jsx(IconStyled, { size: 24, color: theme.palette.primary.main, onClick: onEdit, IconComponent: EditIcon })] }), IsDefined(errorMessage) &&
                        errorMessage !== '' && (_jsx(ErrorMessage, { errorMessage: errorMessage }))] })), !isCollapsed && (_jsxs(_Fragment, { children: [children, (isInitiallyEditting || isInEdit) &&
                        _jsxs(ButtonsContainer, { stepStatus: steps[stepName].status, children: [isInEdit && (_jsxs(_Fragment, { children: [_jsx(Button, { type: 'submit', variant: 'filled', color: 'primary', size: 'large', startIcon: UpdateIcon, disabled: buttonsDisabled || loading, isLoading: loading, onClick: onUpdate, fullWidth: true, children: t('actions.update', { ns: 'common' }) }), _jsx(Button, { type: 'button', variant: 'tinted', color: 'primary', size: 'large', disabled: loading, onClick: onCancel, fullWidth: true, children: t('actions.cancel', { ns: 'common' }) })] })), isInitiallyEditting && (_jsx(Button, { type: 'submit', variant: 'filled', color: 'success', size: 'large', disabled: buttonsDisabled || loading, isLoading: loading, fullWidth: true, children: t('actions.continue', { ns: 'common' }) }))] })] }))] }));
};
export default BidToQueueStep;
