var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { formatNumber } from '@shared/lib/numbers';
import { ReactComponent as FollowersIcon } from '@icons/wolfkit-light/users-light.svg';
import { Body } from '@shared/ui/display/Typography';
import { ContainerRow } from '@shared/ui/display/Containers';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { IsDefined } from '@utils/js-ts';
const IconTextStyled = styled(IconText)(() => ({
    gap: 4,
}));
const Container = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
}));
const PortfolioSubscribers = (_a) => {
    var { followersCount, userQueuePosition = undefined } = _a, rest = __rest(_a, ["followersCount", "userQueuePosition"]);
    const theme = useTheme();
    const followersCountFormatted = useMemo(() => formatNumber(followersCount, { compactNotation: true, maximumFractionDigits: 0 }), [followersCount]);
    return (_jsxs(IconTextStyled
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, rest, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: FollowersIcon })), children: [!IsDefined(userQueuePosition) && (_jsx(Body, { children: followersCountFormatted })), IsDefined(userQueuePosition) && (_jsxs(Container, { children: [_jsx(Body, { color: theme.palette.text.primary, children: userQueuePosition }), followersCount > 1 && (_jsx(Body, { whiteSpace: 'pre', children: ` /${followersCountFormatted}` }))] }))] })));
};
export default PortfolioSubscribers;
