import * as yup from 'yup';
import { checkStringEdges, publicNameSchema } from '@utils/validation';
import { REGEXP_USERNAME } from '@app/constants/validation';
const introductionValidationSchema = (translateFn) => yup.object().shape({
    displayName: yup.lazy(publicNameSchema(translateFn, true)),
    location: yup.string().min(1, translateFn('profile.messages.location_empty')),
    username: yup.string().required(translateFn('profile.messages.nickname_empty')).matches(REGEXP_USERNAME, translateFn('profile.messages.nickname_only_latin_and_numbers')).max(30, translateFn('profile.messages.nickname_too_long'))
        .test('special-chars-in-edge', translateFn('profile.messages.nickname_special_chars_edges'), checkStringEdges),
    initialUsername: yup.string(),
});
const personalDetailsValidationSchema = (t) => yup.object().shape({
    firstName: yup.string().required(t('profile.messages.required')),
    lastName: yup.string().required(t('profile.messages.required')),
});
const addressDetailsValidationSchema = (t) => yup.object().shape({
    addressLine: yup.string().required(t('profile.messages.required')),
    additionalAddressLine: yup.string().required(t('profile.messages.required')),
    city: yup.string().required(t('profile.messages.required')),
    country: yup.string().required(t('profile.messages.required')),
    region: yup.string().optional(),
    postalCode: yup.string().optional(),
});
const identityDetailsValidationSchema = (t) => yup.object().shape({
    countryIdentityName: yup.string().required(t('profile.messages.required')),
    docType: yup.mixed().required(t('profile.messages.required')),
    docNumber: yup.string().required(t('profile.messages.required')),
    taxIdentityNumber: yup.string().optional(),
});
export { introductionValidationSchema, personalDetailsValidationSchema, addressDetailsValidationSchema, identityDetailsValidationSchema };
