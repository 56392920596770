import { createSlice } from '@reduxjs/toolkit';
import { IsDefined } from '@utils/js-ts';
import { subscribeToPortfolio } from './thunks';
import { SUBSCRIPTION_DATA_INIT, SUBSCRIPTION_STEPS_INIT } from './constants';
import { mapSubscriptionDataFromResponse, updateSubscriptionStepsStatus } from './utils';
export const SliceName = 'portfolioSubscription';
const PortfolioSubscriptionSlice = createSlice({
    name: SliceName,
    initialState: {
        status: 'uninitialized',
        portfolio: undefined,
        isUserSubscribed: false,
        subscriptionData: undefined,
        steps: SUBSCRIPTION_STEPS_INIT,
        errors: [],
        isLoading: false,
    },
    reducers: {
        setPortfolioForSubscription: (state, action) => {
            state.portfolio = action.payload;
            state.isUserSubscribed = !!state.portfolio.user_subscription_summary;
            state.subscriptionData = undefined;
        },
        prefillSubscriptionData: (state, { payload }) => {
            state.portfolio = payload.portfolio;
            state.isUserSubscribed = true;
            state.subscriptionData = mapSubscriptionDataFromResponse({
                portfolio: payload.portfolio,
                subscription: payload.subscriptionData,
                walletTotalAssets: payload.walletTotalAssetsUsdt,
            });
            state.steps = updateSubscriptionStepsStatus(SUBSCRIPTION_STEPS_INIT, 'initialized_before');
            state.status = 'initialized_before';
        },
        startEdittingSubscriptionData: (state) => {
            state.status = 'editting';
            state.steps = updateSubscriptionStepsStatus(state.steps, 'completed');
        },
        stopEdittingSubscriptionData: (state) => {
            state.status = 'initialized_before';
            state.steps = updateSubscriptionStepsStatus(state.steps, 'initialized_before');
        },
        startSubscriptionProccess: (state, action) => {
            // TODO: remove this reducer?
            state.subscriptionData = Object.assign(Object.assign({}, SUBSCRIPTION_DATA_INIT), { minInvestAmount: parseFloat(action.payload.minAllocatedCapital), investAmount: parseFloat(action.payload.minAllocatedCapital), subscriptionPrice: action.payload.subscriptionPrice, bidAmount: action.payload.subscriptionPrice, minBidAmount: action.payload.subscriptionPrice });
            state.status = 'editting';
            state.steps.select_exchange.status = 'first_edit';
        },
        submitStep: (state, action) => {
            state.steps[action.payload].status = 'completed';
        },
        goToNextStep: (state, action) => {
            state.steps[action.payload].status = 'first_edit';
        },
        editStep: (state, action) => {
            const steps = Object.assign({}, state.steps);
            const keys = Object.keys(state.steps);
            keys.forEach((key) => {
                steps[key] = Object.assign(Object.assign({}, state.steps[key]), { status: key === action.payload ? 'edit' : steps[key].status, disabled: key !== action.payload });
            });
            state.steps = steps;
        },
        cancelEditStep: (state, action) => {
            const steps = Object.assign({}, state.steps);
            const keys = Object.keys(state.steps);
            keys.forEach((key) => {
                steps[key] = Object.assign(Object.assign({}, state.steps[key]), { status: key === action.payload ? 'completed' : steps[key].status, disabled: false });
            });
            state.steps = steps;
        },
        selectExchange: (state, { payload }) => {
            if (state.subscriptionData && state.portfolio) {
                state.subscriptionData.walletId = payload.walletId;
                state.subscriptionData.maxInvestAmount = payload.totalAmountUsdt;
            }
        },
        selectInvestAmount: (state, { payload }) => {
            if (state.subscriptionData && state.portfolio) {
                state.subscriptionData.investAmount = payload.investAmount;
            }
        },
        selectStopCopyingLimit: (state, action) => {
            if (state.subscriptionData) {
                state.subscriptionData.stopCopyingLimit = action.payload;
            }
        },
        setBidAmount: (state, { payload }) => {
            if (state.subscriptionData && state.portfolio) {
                state.subscriptionData.bidAmount = payload.bidAmount;
            }
        },
        setQueuePosition: (state, action) => {
            if (state.subscriptionData) {
                state.subscriptionData.queuePosition = action.payload;
            }
        },
        setPaymentMethod: (state, action) => {
            if (state.subscriptionData) {
                state.subscriptionData.paymentMethod = {
                    number: action.payload.number,
                    type: action.payload.type,
                };
            }
        },
        exitSubscriptionProcess: (state) => {
            state.subscriptionData = undefined;
            state.steps = Object.assign({}, SUBSCRIPTION_STEPS_INIT);
            state.status = 'uninitialized';
        },
        finishSubscriptionEditProcess: (state) => {
            state.status = 'initialized_before';
            state.steps = updateSubscriptionStepsStatus(state.steps, 'initialized_before');
        },
    },
    extraReducers: (builder) => {
        builder.addCase(subscribeToPortfolio.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(subscribeToPortfolio.rejected, (state, action) => {
            state.isLoading = false;
            if (IsDefined(action.payload)) {
                state.errors = action.payload;
            }
        });
        builder.addCase(subscribeToPortfolio.fulfilled, (state) => {
            state.isLoading = false;
            state.isUserSubscribed = true;
        });
    },
});
export const { setPortfolioForSubscription, startSubscriptionProccess, submitStep, editStep, goToNextStep, cancelEditStep, selectExchange, selectInvestAmount, selectStopCopyingLimit, setBidAmount, setPaymentMethod, setQueuePosition, exitSubscriptionProcess, prefillSubscriptionData, startEdittingSubscriptionData, stopEdittingSubscriptionData, finishSubscriptionEditProcess, } = PortfolioSubscriptionSlice.actions;
export const portfolioSubscriptionReducer = PortfolioSubscriptionSlice.reducer;
