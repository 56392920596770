import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { formatPercent } from '@shared/lib/numbers';
import { BodySmall } from '@shared/ui/display/Typography';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { IsDefined } from '@utils/js-ts';
const Container = styled(ContainerColumn)(() => ({
    width: 'auto',
    justifyContent: 'center',
}));
const PositionText = styled(BodySmall)(props => ({
    color: props.theme.palette.text.secondary,
    lineHeight: '15px',
}));
const MonthlyReturnPositions = ({ className = undefined, first, last = undefined, }) => {
    const firstPosition = `${formatPercent(first.returnValue, { enablePlusSign: false })} #1`;
    const secondPosition = IsDefined(last) ?
        `${formatPercent(last.returnValue, { enablePlusSign: false })} #${last.position}` :
        '';
    return (_jsxs(Container, { className: className, children: [_jsx(PositionText, { children: firstPosition }), !!secondPosition && (_jsx(PositionText, { children: secondPosition }))] }));
};
export default MonthlyReturnPositions;
