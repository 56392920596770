import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { forwardRef, useCallback, useImperativeHandle, } from 'react';
import { logout as logoutAction } from '@entities/auth';
import { TextEmphasizedMediumBold } from '@shared/ui/display/Typography';
import { useAppDispatch } from '@app/store/Hooks';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn } from '@shared/ui/display/Containers';
import SuccessIcon from '@shared/ui/icons/SuccessIcon';
import SidebarContainer from '../components/SidebarContainer';
import SidebarButtonsContainer from '../components/SidebarButtonsContainer';
const MessageContainer = styled(ContainerColumn)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
}));
const SuccessComponent = (
// eslint-disable-next-line react/require-default-props
props, ref) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const logout = useCallback(() => {
        dispatch(logoutAction());
    }, [dispatch]);
    useImperativeHandle(ref, () => ({
        onClose: logout,
    }), [logout]);
    return (_jsxs(SidebarContainer, { children: [_jsxs(MessageContainer, { children: [_jsx(SuccessIcon, { size: 70 }), _jsx(TextEmphasizedMediumBold, { children: t('settings.sidebars.delete_account.account_has_been_deleted') })] }), _jsx(SidebarButtonsContainer, { children: _jsx(Button, { onClick: logout, fullWidth: true, children: t('settings.sidebars.delete_account.actions.go_to_main_page') }) })] }));
};
const Success = forwardRef(SuccessComponent);
export default Success;
