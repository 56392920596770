import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ActiveTextBold } from '@shared/ui/display/Typography';
const Container = styled(ActiveTextBold, {
    shouldForwardProp: propName => propName !== 'disabled',
})(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: props.size === 'medium' ? 100 : 6,
    // TODO: move colors
    backgroundColor: props.disabled ?
        props.theme.palette.primary.light : props.theme.customColors.portfolio.hover,
    color: props.disabled ?
        props.theme.customColors.button.secondary.color :
        props.theme.customColors.portfolio.onHover,
    padding: props.size === 'medium' ? '0px 5px' : '4px 6px',
    boxSizing: 'border-box',
}));
const CounterBadge = ({ text, color = undefined, backgroundColor = undefined, disabled = undefined, fontSize = undefined, minWidth = undefined, size = 'medium', }) => (_jsx(Container, { style: {
        color,
        backgroundColor,
        fontSize,
        minWidth,
    }, disabled: disabled, size: size, children: text }));
export default CounterBadge;
