import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { CircularProgress, useTheme } from '@mui/material';
import styled from '@emotion/styled/macro';
import { useMemo } from 'react';
import { Body, BodySemiBold, BodyExtraSmall, BodySmall, } from '@shared/ui/display/Typography';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { useAppSelector } from '@app/store/Hooks';
import { numberToFixed2, formatPercent } from '@shared/lib/numbers';
import { IsDefined } from '@utils/js-ts';
import { useGetPortfolioCopiersQueueDetailsQuery } from '@shared/api/portfolio/api';
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const TextRow = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'baseline',
    gap: props.theme.spacing_sizes.xs * 0.5,
}));
const SlotPositionDetailsRowContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '14px 21px',
}));
const Item = styled(SlotPositionDetailsRowContainer, { shouldForwardProp: propName => propName !== 'main' })(props => ({
    borderBottom: `1px solid ${props.theme.customColors.menu.item.hover}`,
    backgroundColor: props.main ?
        props.theme.customColors.surface.surface :
        props.theme.customColors.menu.item.select
}));
const ItemColumn = styled(ContainerColumn)(() => ({
    height: 'auto',
    alignItems: 'baseline',
    justifyContent: 'center',
    flex: 1,
}));
const ItemColumnWide = styled(ItemColumn)(() => ({
    flex: 1.5,
}));
const DetailsContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    gap: '5px',
}));
const HeaderContainer = styled(SlotPositionDetailsRowContainer)(() => ({
    paddingTop: 0,
    paddingBottom: 0,
}));
const DetailsItemsContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    borderRadius: 4,
    border: `1px solid ${props.theme.customColors.badge.profileTab.surface}`,
    [`${Item}:last-child`]: {
        borderBottom: 'none'
    }
}));
const UsersPositionDetailsContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 0.5,
    marginTop: props.theme.spacing_sizes.s,
}));
const UsersPositionDetailsColumn = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'alignEnd' })(props => ({
    height: 'auto',
    alignItems: props.alignEnd ? 'end' : 'baseline',
}));
const EmptyStateContainer = styled.div(props => ({
    display: 'flex',
    padding: `${props.theme.spacing_sizes.m}px ${props.theme.spacing_sizes.l}px`,
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs * 1.25,
    borderRadius: 4,
    border: `1px solid ${props.theme.customColors.button.pill.border}`,
    background: props.theme.customColors.menu.item.select,
}));
const QueuePositionDetailsItem = ({ item, items, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const delayLabel = useMemo(() => {
        let label = t('portfolio.subscription.steps.set_queue_priority.delay');
        if (item.isUsersPosition) {
            label = t('portfolio.subscription.steps.set_queue_priority.your_delay');
        }
        else if (items.length &&
            item.position <= items[0].position) {
            label = t('portfolio.subscription.steps.set_queue_priority.minimum');
        }
        else if (items.length &&
            item.position >= items[items.length - 1].position) {
            label = t('portfolio.subscription.steps.set_queue_priority.maximum');
        }
        return label;
    }, [item, items, t]);
    const incomeDiffWithFirst = useMemo(() => (items.length ?
        item.projected_income - items[0].projected_income :
        0), [item, items]);
    const incomeDiffWithLast = useMemo(() => (items.length ?
        item.projected_income - items[items.length - 1].projected_income :
        0), [item, items]);
    const incomeDiffColor = (diff) => {
        if (diff < 0) {
            return theme.palette.secondary.main;
        }
        return theme.palette.success.main;
    };
    return (_jsxs(Item, { main: item.isUsersPosition, children: [_jsxs(ItemColumn, { children: [_jsx(BodySmall, { color: theme.palette.text.secondary, children: delayLabel }), _jsxs(TextRow, { children: [_jsx(BodySemiBold, { children: numberToFixed2(item.delay) }), _jsx(BodySmall, { color: theme.palette.text.secondary, children: t('portfolio.subscription.steps.set_queue_priority.seconds') })] })] }), _jsx(ItemColumnWide, { children: _jsx(BodySemiBold, { color: theme.palette.success.main, children: item.isUsersPosition ?
                        `≈ ${formatPercent(item.projected_income, { disableSign: true })}` :
                        formatPercent(item.projected_income) }) }), _jsxs(ItemColumnWide, { children: [!item.isUsersPosition && (_jsx(BodySmall, { children: `# ${item.position}` })), item.isUsersPosition && (_jsxs(_Fragment, { children: [_jsxs(TextRow, { children: [_jsx(BodySmall, { children: `# ${item.position}` }), _jsx(BodySmall, { color: theme.palette.text.secondary, children: `(${t('portfolio.subscription.steps.set_queue_priority.you')})` })] }), items.length > 1 && (_jsxs(UsersPositionDetailsContainer, { children: [_jsxs(UsersPositionDetailsColumn, { children: [_jsx(BodySmall, { color: incomeDiffColor(incomeDiffWithFirst), children: formatPercent(incomeDiffWithFirst) }), _jsx(BodySmall, { color: incomeDiffColor(incomeDiffWithLast), children: formatPercent(incomeDiffWithLast) })] }), _jsxs(UsersPositionDetailsColumn, { children: [_jsx(BodySmall, { color: theme.palette.text.secondary, children: t('portfolio.subscription.steps.set_queue_priority.profit_diff_from') }), _jsx(BodySmall, { color: theme.palette.text.secondary, children: t('portfolio.subscription.steps.set_queue_priority.profit_diff_from') })] }), _jsxs(UsersPositionDetailsColumn, { alignEnd: true, children: [_jsx(BodySmall, { children: `#${items[0].position}` }), _jsx(BodySmall, { children: `#${items[items.length - 1].position}` })] })] }))] }))] })] }));
};
const QueuePositionDetails = ({ className = undefined, usersPosition = undefined, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { portfolio } = useAppSelector(state => state.portfolioSubscription);
    const { data, isFetching, isError, } = useGetPortfolioCopiersQueueDetailsQuery({
        portfolioId: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.id) || '',
    });
    const detailItems = useMemo(() => {
        const items = [];
        if (!IsDefined(data) || !IsDefined(usersPosition)) {
            return items;
        }
        // TODO: create helper functions for the object creation
        // and to round math calculations
        const usersPositionDetails = {
            isUsersPosition: true,
            delay: data.delay_step * usersPosition,
            position: usersPosition,
            projected_income: (data.projected_income.total - (data.projected_income.step * (usersPosition - 1))),
        };
        if (IsDefined(data.first)) {
            if (usersPosition === data.first.position) {
                items.push(usersPositionDetails);
                items.push(Object.assign(Object.assign({}, data.first), { delay: data.first.delay + data.delay_step, position: data.first.position + 1, projected_income: (data.projected_income.total - (data.projected_income.step * data.first.position)) }));
            }
            else {
                items.push(data.first);
                items.push(usersPositionDetails);
            }
        }
        if (IsDefined(data.last)) {
            if (usersPosition <= data.last.position) {
                items.push(Object.assign(Object.assign({}, data.last), { delay: data.last.delay + data.delay_step, position: data.last.position + 1, projected_income: (data.projected_income.total - (data.projected_income.step * data.last.position)) }));
            }
            else {
                items.splice(1, 0, data.last);
            }
        }
        return items;
    }, [data, usersPosition]);
    return (_jsxs(Container, { children: [isFetching && (_jsx(CircularProgress, {})), !isFetching &&
                !isError &&
                !detailItems.length && (_jsx(EmptyStateContainer, { children: _jsx(Body, { children: t('portfolio.subscription.steps.set_queue_priority.queue_details_empty') }) })), !isFetching &&
                !isError &&
                IsDefined(data) &&
                detailItems.length > 0 && (_jsxs(DetailsContainer, { children: [_jsxs(HeaderContainer, { children: [_jsx(ItemColumn, { children: _jsx(BodyExtraSmall, { color: theme.palette.text.secondary, children: t('portfolio.subscription.steps.set_queue_priority.delay') }) }), _jsx(ItemColumnWide, { children: _jsx(BodyExtraSmall, { color: theme.palette.text.secondary, children: t('portfolio.subscription.steps.set_queue_priority.historical_profit') }) }), _jsx(ItemColumnWide, { children: _jsx(BodyExtraSmall, { color: theme.palette.text.secondary, children: t('portfolio.subscription.steps.set_queue_priority.position') }) })] }), _jsx(DetailsItemsContainer, { children: detailItems.map((item, idx) => (_jsx(QueuePositionDetailsItem, { item: item, items: detailItems }, idx))) })] }))] }));
};
export default QueuePositionDetails;
