import { RouteNames } from './types';
const ProfileRouteNamesConfigGeneral = {
    followers: RouteNames.PROFILE_FOLLOWERS,
    followings: RouteNames.PROFILE_FOLLOWINGS,
    activity: RouteNames.PROFILE_ACTIVITY,
    copiers: RouteNames.PROFILE_COPIERS,
    portfolios: RouteNames.PROFILE_PORTFOLIOS,
    closed_portfolios: RouteNames.PROFILE_CLOSED_PORTFOLIOS,
    portfolio_information: RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION,
    portfolio_information_closed: RouteNames.PROFILE_CLOSED_PORTFOLIOS_PORTFOLIO_INFORMATION,
    portfolio_current_positions: RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS,
    portfolio_positions_closed: RouteNames.PROFILE_CLOSED_PORTFOLIOS_POSITIONS,
    portfolio_history: RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY,
    portfolio_history_closed: RouteNames.PROFILE_CLOSED_PORTFOLIOS_HISTORY,
    portfolio_copiers: RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS,
};
const ProfileRouteNamesConfigMyProfile = {
    followers: RouteNames.MY_PROFILE_FOLLOWERS,
    followings: RouteNames.MY_PROFILE_FOLLOWINGS,
    activity: RouteNames.MY_PROFILE_ACTIVITY,
    copiers: RouteNames.MY_PROFILE_COPIERS,
    portfolios: RouteNames.MY_PROFILE_PORTFOLIOS,
    closed_portfolios: RouteNames.MY_PROFILE_CLOSED_PORTFOLIOS,
    portfolio_information: RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION,
    portfolio_information_closed: RouteNames.MY_PROFILE_CLOSED_PORTFOLIOS_PORTFOLIO_INFORMATION,
    portfolio_current_positions: RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS,
    portfolio_positions_closed: RouteNames.MY_PROFILE_CLOSED_PORTFOLIOS_POSITIONS,
    portfolio_history: RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY,
    portfolio_history_closed: RouteNames.MY_PROFILE_CLOSED_PORTFOLIOS_HISTORY,
    portfolio_copiers: RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS,
};
const createProfileRouteChildren = (routeNames) => ([
    {
        name: routeNames.portfolios,
        path: 'portfolios',
        children: [
            {
                name: routeNames.portfolio_information,
                path: ':portfolioId/general-portfolio-information',
            },
            {
                name: routeNames.portfolio_current_positions,
                path: ':portfolioId/current-positions',
            },
            {
                name: routeNames.portfolio_history,
                path: ':portfolioId/history',
            },
            {
                name: routeNames.portfolio_copiers,
                path: ':portfolioId/copiers',
            },
        ],
    },
    {
        name: routeNames.closed_portfolios,
        path: 'closed-portfolios',
        children: [
            {
                name: routeNames.portfolio_information_closed,
                path: ':portfolioId/general-portfolio-information',
            },
            {
                name: routeNames.portfolio_positions_closed,
                path: ':portfolioId/current-positions',
            },
            {
                name: routeNames.portfolio_history_closed,
                path: ':portfolioId/history',
            },
        ],
    },
    {
        name: routeNames.followers,
        path: 'followers',
    },
    {
        name: routeNames.followings,
        path: 'followings',
    },
    {
        name: routeNames.activity,
        path: 'activity',
    },
    {
        name: routeNames.copiers,
        path: 'copiers',
    },
]);
const routes = [
    {
        name: RouteNames.NON_ROUTABLE_LAYOUT_GENERAL,
        children: [
            {
                name: RouteNames.HOME,
                path: '/',
                helpers: {
                    getDisplayName: (t) => t('pages.home', { ns: 'common' }),
                },
            },
            {
                name: RouteNames.NON_ROUTABLE_LAYOUT_AUTHORIZED,
                path: '/',
                children: [
                    {
                        name: RouteNames.NON_ROUTABLE_LAYOUT_HOME,
                        children: [
                            {
                                name: RouteNames.HOME_OVERVIEW,
                                path: 'overview',
                                helpers: {
                                    getDisplayName: (t) => t('pages.overview.overview', { ns: 'common' }),
                                },
                            },
                            {
                                name: RouteNames.HOME_BALANCE,
                                path: 'balance',
                                helpers: {
                                    getDisplayName: (t) => t('pages.overview.balance', { ns: 'common' }),
                                },
                            },
                            {
                                name: RouteNames.MY_PROFILE,
                                path: 'my-profile',
                                helpers: {
                                    getDisplayName: (t) => t('pages.my_profile', { ns: 'common' }),
                                },
                                children: createProfileRouteChildren(ProfileRouteNamesConfigMyProfile),
                            },
                        ],
                    },
                    {
                        name: RouteNames.PROFILE,
                        path: 'profile/:nickname',
                        helpers: {
                            getDisplayName: (t) => t('pages.profile', { ns: 'common' }),
                        },
                        children: createProfileRouteChildren(ProfileRouteNamesConfigGeneral),
                    },
                    {
                        name: RouteNames.OVERVIEW_WALLET_DETAILS,
                        path: 'overview/:walletId',
                        helpers: {
                            getDisplayName: (t) => t('pages.profile', { ns: 'common' }),
                        },
                    },
                    {
                        name: RouteNames.MARKETPLACE,
                        path: 'marketplace',
                        helpers: {
                            getDisplayName: (t) => t('pages.marketplace.marketplace', { ns: 'common' }),
                        },
                    },
                    {
                        name: RouteNames.MARKETPLACE_LIST,
                        path: 'marketplace/list',
                        helpers: {
                            getDisplayName: (t) => t('pages.marketplace.marketplace', { ns: 'common' }),
                        },
                    },
                    {
                        name: RouteNames.SETTINGS,
                        path: 'settings',
                        helpers: {
                            getDisplayName: (t) => t('pages.settings', { ns: 'common' }),
                        },
                        children: [
                            {
                                name: RouteNames.SETTINGS_PERSONAL_INFORMATION,
                                path: 'personal-information',
                            },
                            {
                                name: RouteNames.SETTINGS_SUBSCRIPTION,
                                path: 'subscription',
                            },
                        ],
                    },
                    {
                        name: RouteNames.SUPPORT,
                        path: 'support',
                        helpers: {
                            getDisplayName: (t) => t('pages.support', { ns: 'common' }),
                        },
                    },
                    {
                        name: RouteNames.DEV_COMPONENTS,
                        path: 'dev-components',
                        helpers: {
                            getDisplayName: () => 'Dev Components',
                        },
                    },
                ],
            },
            {
                name: RouteNames.NON_ROUTABLE_NOT_FOUND,
                path: '*',
            },
            {
                name: RouteNames.AUTH,
                path: '/auth',
                helpers: {
                    getDisplayName: (t) => t('app_title', { ns: 'common' }),
                },
            },
            {
                name: RouteNames.CONFIRM_EMAIL,
                path: '/auth/verify-email/:token',
                helpers: {
                    getDisplayName: (t) => t('pages.confirm_email', { ns: 'common' }),
                },
            },
            {
                name: RouteNames.CHANGE_PASSWORD,
                path: '/auth/password/recovery/:token',
                helpers: {
                    getDisplayName: (t) => t('pages.confirm_password', { ns: 'common' }),
                },
            },
        ],
    },
];
export default routes;
