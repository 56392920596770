import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerRow } from '@shared/ui/display/Containers';
import ProfileMoreActionsDropdown from '@shared/ui/menus/ProfileMoreActionsDropdown';
import PortfolioPrimaryButton from './PortfolioPrimaryButton';
const Container = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const PortfolioActions = ({ portfolio, displayState, primaryActionType, disabled = false, onAction = undefined, moreActionBtnOptions = undefined, }) => (_jsxs(Container, { children: [displayState === 'default' && (_jsx(PortfolioPrimaryButton, { portfolio: portfolio, actionType: primaryActionType, onAction: onAction, disabled: disabled })), _jsx(ProfileMoreActionsDropdown, { profile: portfolio.profile, moreActionBtnOptions: moreActionBtnOptions, size: 'medium', variant: 'plain', color: 'secondary', buttonPadding: '9px', disabled: disabled })] }));
export default PortfolioActions;
