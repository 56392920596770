import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import LinkComponent from '@shared/ui/navigation/Link';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import Button from '@shared/ui/buttons/Button';
import Input from '@shared/ui/inputs/Input';
import { BodyMedium } from '@shared/ui/display/Typography';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import SelectTag from '@shared/ui/inputs/SelectTag';
import { StepFormContainer, StepText, Title, } from '@widgets/sidebars/shared';
import { useWizard } from '@shared/ui/misc/Wizard';
import RecieveAmountBadge from '../../RecieveAmountBadge';
import { BalanceManagementSteps, } from '../../../types';
const InputContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.theme.spacing_sizes.xm,
}));
const SelectItemContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.theme.spacing_sizes.s * 10,
}));
const InfoText = styled(BodyMedium)(props => ({
    color: props.theme.palette.text.secondary,
    fontWeight: 500,
    textAlign: 'center',
}));
const EnterAmount = ({ onAmountFormSubmit, onSidebarClose, }) => {
    const { t } = useTranslation();
    const { goTo } = useWizard();
    const { setValue, watch, control, handleSubmit, } = useForm({
        defaultValues: { amount: 0 },
    });
    const amount = watch('amount');
    const onSubmit = (values) => {
        onAmountFormSubmit(values);
        goTo(BalanceManagementSteps.CardDetails);
    };
    const goToPrevStep = () => {
        goTo(BalanceManagementSteps.ChoosePaymentMethod);
    };
    const handleItemSelect = (itemValue) => {
        if (typeof itemValue === 'string') {
            setValue('amount', parseFloat(itemValue));
        }
    };
    return (_jsx(SidebarInner, { title: (_jsx(BackLinkButton, { onClick: goToPrevStep, children: t('overview.balance_management.back_btn') })), cancelBtnProps: {
            onClick: onSidebarClose,
        }, displayCancelBtn: true, padding: '50px 30px', children: _jsx(StepFormContainer, { title: _jsx(Title, { children: t('overview.balance_management.enter_amount.title') }), onSubmit: handleSubmit(onSubmit), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { stepNumber: t('overview.balance_management.enter_amount.step'), text: t('overview.balance_management.enter_amount.text') }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'amount', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { label: t('overview.balance_management.enter_amount.input_label'), placeholder: t('overview.balance_management.enter_amount.input_placeholder'), InputLabelProps: { shrink: true }, startAdornment: '$' }))) }) }), _jsx(SelectItemContainer, { children: _jsx(SelectTag, { options: [
                                {
                                    value: '100',
                                    label: _jsx("span", { children: "$ 100" }),
                                },
                                {
                                    value: '300',
                                    label: _jsx("span", { children: "$ 300" }),
                                },
                                {
                                    value: '500',
                                    label: _jsx("span", { children: "$ 500" }),
                                },
                                {
                                    value: '1000',
                                    label: _jsx("span", { children: "$ 1000" }),
                                },
                            ], value: `${amount}`, onChange: handleItemSelect }) }), _jsx(RecieveAmountBadge, { amount: amount })] })), contentAlign: 'start', contentFlex: 1, containerGap: 60, buttons: (_jsxs(_Fragment, { children: [_jsx(InfoText, { children: Trans({
                            i18nKey: 'overview.balance_management.enter_amount.info_text',
                            values: {
                                privacyPolicy: t('overview.balance_management.enter_amount.privacy_policy_text'),
                                termsOfService: t('overview.balance_management.enter_amount.terms_of_usage_text'),
                            },
                            components: [
                                _jsx(LinkComponent, {}, 'privacy-policy'),
                                _jsx(LinkComponent, {}, 'terms-of-usage'),
                            ],
                        }) }), _jsx(Button, { size: 'large', type: 'submit', disabled: Boolean(amount < 5), fullWidth: true, children: t('overview.balance_management.enter_amount.continue_btn') })] })), buttonsGap: 18 }) }));
};
export default EnterAmount;
