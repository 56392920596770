import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { ReactComponent as ArrowIcon } from '@icons/wolfkit-light/arrow-small-right-light.svg';
import { getCurrentMonthText } from '@shared/lib/date-time';
import { Title as TitleComponent } from '@shared/ui/display/Typography';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import Button from '@shared/ui/buttons/Button';
import TopPortfoliosInCategory from '@pages/marketplace/ui/TopPortfolios/TopPortfoliosInCategory';
import { PortfolioCategories, PortfolioTypes } from './types';
import PortfolioTypeSwitch from './PortfolioTypeSwitch';
const Container = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
    gap: '200px',
}));
const Title = styled(TitleComponent)(props => ({
    color: `${props.theme.palette.text.disabled}`,
    fontSize: '60px',
    lineHeight: '80px',
    userSelect: 'none',
}));
const FiltersContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    alignItems: 'center',
    gap: '55px',
}));
const ButtonsContainer = styled.div(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.l,
    alignItems: 'center',
}));
const PortfoliosContainer = styled(ContainerColumn)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const TopPortfolios = () => {
    const { t, i18n } = useTranslation();
    const { navigate, routes } = useAppNavigation();
    const [currentPortfolioType, setCurrentPortfolioType] = useState(PortfolioTypes.All);
    const goToMarketplaceList = () => {
        navigate(routes.MARKETPLACE_LIST);
    };
    const handlePortfolioTypeChange = (type) => {
        setCurrentPortfolioType(type);
    };
    return (_jsxs(Container, { children: [_jsxs(FiltersContainer, { children: [_jsx(Title, { children: t('marketplace.main_view.title', { currentMonth: getCurrentMonthText(i18n.language) }) }), _jsxs(ButtonsContainer, { children: [_jsx(PortfolioTypeSwitch, { currentType: currentPortfolioType, onTypeChange: handlePortfolioTypeChange }), _jsx(Button, { color: 'primary', variant: 'filled', endIcon: ArrowIcon, onClick: goToMarketplaceList, children: t('marketplace.main_view.filters.see_all') })] })] }), _jsxs(PortfoliosContainer, { children: [_jsx(TopPortfoliosInCategory, { category: PortfolioCategories.BestOverall, portfolioType: currentPortfolioType, hideHeader: true }), _jsx(TopPortfoliosInCategory, { category: PortfolioCategories.BestOverall, portfolioType: currentPortfolioType }), _jsx(TopPortfoliosInCategory, { category: PortfolioCategories.BestPnl, portfolioType: currentPortfolioType })] })] }));
};
export default TopPortfolios;
