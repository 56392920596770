import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from '@icons/wolfkit-light/chevron-small-left-light.svg';
import { ReactComponent as ArrowRightIcon } from '@icons/wolfkit-light/chevron-small-right-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { BodySemiBold } from '@shared/ui/display/Typography';
const PreviousNextButton = ({ next = false, disabled, onClick, }) => {
    const { t } = useTranslation();
    const icon = useMemo(() => (next ? ArrowRightIcon : ArrowLeftIcon), [next]);
    const text = useMemo(() => (next ? t('navigation.next', { ns: 'common' }) : t('navigation.previous', { ns: 'common' })), [next, t]);
    return (_jsx(Button, { variant: 'plain', color: 'secondary', startIcon: (!next && (_jsx(Icon, { size: 16, IconComponent: icon }))), endIcon: (next && (_jsx(Icon, { size: 16, IconComponent: icon }))), onClick: onClick, disabled: disabled, children: _jsx(BodySemiBold, { children: text }) }));
};
export default PreviousNextButton;
