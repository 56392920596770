import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useRef, useContext, useMemo } from 'react';
const ScrollableContext = createContext(null);
const useScrollableContext = () => {
    const context = useContext(ScrollableContext);
    if (!context) {
        throw new Error('useScrollableContext must be used within a ScrollableProvider');
    }
    return context;
};
const ScrollableProvider = ({ children }) => {
    const scrollableContainerRef = useRef(null);
    const contextValue = useMemo(() => ({ scrollableContainerRef }), [scrollableContainerRef]);
    return (_jsx(ScrollableContext.Provider, { value: contextValue, children: children }));
};
// NOTE:
// our page layout is built to support fixed navbar/topbar/innerContentSidebar/globalSidebar
// this changes what our scrollable content is (it is not a window/body as it would be in a classic multi-page website).
// if we want to scroll our content in any direction, we aren't able to do so using window.scrollTo(0, 0), for example
// because our scrollable content is a custom div element inside the page layout,
// we should work with scroll on that element instead of window.
// ScrollableProvider is used to give access to our ScrollableContent to the whole application,
// so in order to scroll our page up, for example, we can use scrollableContainerRef?.current?.scrollTo(0, 0).
export { useScrollableContext, ScrollableProvider };
