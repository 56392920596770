import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Logo from '@shared/ui/display/Logo';
import LazyImgLoader from '@shared/ui/misc/LazyImgLoader';
import Link from '@shared/ui/navigation/Link';
import { ContainerColumn, ContainerRow, alignToCenter } from '@shared/ui/display/Containers';
import { Body, BodyLarge, Title } from '@shared/ui/display/Typography';
import Button from '@shared/ui/buttons/Button';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import ENDREX_IMAGES_URL from '@shared/model';
import { AuthenticationMode, setAuthenticationMode } from '@entities/auth';
const PageContainer = styled(ContainerRow)(props => ({
    padding: '60px 140px',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.s * 5,
    maxWidth: 1920,
    margin: '0 auto',
}));
const Container = styled(alignToCenter(ContainerColumn))(() => ({
    flex: 1,
    height: 'inherit',
    justifyContent: 'space-between',
    alignItems: 'start',
}));
const ControlsContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const ImageContainer = styled(Container)();
const LogoContainer = styled.div();
const Image = styled.img(() => ({
    width: '100%',
    height: '100%',
    borderRadius: 24,
    objectFit: 'cover',
}));
const TitleStyled = styled(Title)((props) => ({
    fontSize: 80,
    textTransform: 'uppercase',
    fontWeight: 700,
    color: '#080A0B',
    lineHeight: 1,
    marginBottom: props.theme.spacing_sizes.xs * 3.5,
}));
const Description = styled(BodyLarge)(props => ({
    maxWidth: 415,
    lineHeight: '30px',
    marginBottom: props.theme.spacing_sizes.xs * 6,
}));
const ButtonsGroup = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const Hint = styled(Body)(() => ({
    fontSize: 16,
    lineHeight: '36px',
}));
const MainPage = () => {
    const { navigate, routes } = useAppNavigation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const goToAuthPage = () => {
        navigate(routes.AUTH);
    };
    const handleSignIn = () => {
        dispatch(setAuthenticationMode(AuthenticationMode.SIGN_IN));
        goToAuthPage();
    };
    const handleSignUp = () => {
        dispatch(setAuthenticationMode(AuthenticationMode.SIGN_UP));
        goToAuthPage();
    };
    const imageUrl = `${process.env.REACT_APP_IMAGES_URL}${ENDREX_IMAGES_URL['endrex-welcome-image'].medium}`;
    return (_jsxs(PageContainer, { children: [_jsxs(Container, { children: [_jsx(LogoContainer, { children: _jsx(Logo, {}) }), _jsxs(ControlsContainer, { children: [_jsx(TitleStyled, { children: t('main.title') }), _jsx(Description, { children: t('main.text') }), _jsxs(ButtonsGroup, { children: [_jsx(Button, { size: 'large', onClick: handleSignUp, children: t('main.sign_up') }), _jsx(Button, { size: 'large', variant: 'tinted', onClick: handleSignIn, children: t('main.sign_in') })] })] }), _jsx(Hint, { children: Trans({
                            i18nKey: 'main.hint',
                            components: [
                                _jsx(Link
                                // TODO: move email name to .env
                                , { 
                                    // TODO: move email name to .env
                                    href: 'mailto:"hello@endrex.net"' }, 'support-team-link'),
                            ],
                        }) })] }), _jsx(ImageContainer, { children: _jsx(LazyImgLoader, { url: imageUrl, children: _jsx(Image, { src: imageUrl, alt: 'endrex-welcome-image' }) }) })] }));
};
export default MainPage;
