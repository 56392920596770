export const X_POINT_MATCHER = /[^:]*/;
export const Y_POINT_MATCHER = /:(.*?)-/;
export const CURVE_KEY_MATCHER = /(?<=-)[^-]+/;
// nearest point should not be far more than 16px from pointer
export const LAP_VALUE = 16;
export const TooltipElementHeight = 28;
export const TooltipElementWidth = 260;
export const TooltipElementsSmallXGap = 6;
export const TooltipElementsLargeXGap = 6;
export const TooltipElementsYGap = 6;
export const GraphWidth = 824;
export const GraphHeight = 338;
export const CurveWidth = 748;
export const CurveHeight = 246;
export const GridLineAdditionalLength = 24;
export const TooltipDotSize = 6;
export const Margin = {
    top: 12,
    right: 12,
    bottom: 12,
    left: 24,
};
