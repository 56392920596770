import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import IconWithText from '@shared/ui/display/IconWIthText';
import Button from '@shared/ui/buttons/Button';
import { StepContainer } from '@widgets/sidebars/shared';
import { useAppSelector } from '@app/store/Hooks';
import { CreateTraderProfileMode } from '@entities/profile';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { CheckmarkIcon, StepBodyText, } from '../shared';
const ContentContainer = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: '16px'
}));
const FinalStep = ({ goToNextStep }) => {
    const { t } = useTranslation();
    const { createTraderProfileMode } = useAppSelector(state => state.profile);
    const handleClick = () => {
        if (goToNextStep) {
            goToNextStep();
        }
    };
    return (_jsx(StepContainer, { content: (_jsxs(ContentContainer, { children: [_jsx(IconWithText, { IconElement: _jsx(CheckmarkIcon, {}), text: t('profile.create_trader.steps.final.title', { ns: 'common' }) }), createTraderProfileMode === CreateTraderProfileMode.Verify && (_jsx(StepBodyText, { children: t('profile.create_trader.steps.final.verification_description', { ns: 'common' }) }))] })), contentFlex: 1, buttons: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: handleClick, children: t('profile.create_trader.steps.final.btn_text', { ns: 'common' }) })) }));
};
export default FinalStep;
