var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { filterPortfoliosListFromResponse, shouldFilterByAssessmentPassed, } from '@entities/portfolio';
import { getWalletsFromExchanges } from '@entities/wallet';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import { useGetCurrentProfilePortfoliosQuery, useGetCurrentProfilePortfolioSubscriptionsQuery, } from '@shared/api/portfolio';
import { useAppSelector } from '@app/store/Hooks';
import { IsDefined } from '@shared/lib/js-ts';
const getListType = (skipPortfoliosQuery, skipPortfolioSubscrpitionsQuery) => {
    if (skipPortfoliosQuery && skipPortfolioSubscrpitionsQuery) {
        return undefined;
    }
    if (skipPortfoliosQuery)
        return 'subscribed-portfolios';
    return 'portfolios';
};
const useGetProfilePortfolios = ({ isClosedPortfolios = undefined, profileId, profileType, isMyProfile = false, isVerifiedTrader, }) => {
    const { user } = useAppSelector(state => state.user);
    const { data: { exchangesList = [], wallets = [] }, isFetching: isFetchingWallets, isLoading: isLoadingWallets, status: exchangesStatus, isError: isExchangesError, } = useGetExchangeAccountsQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: {
                    exchangesList: (data || []),
                    wallets: getWalletsFromExchanges(data || []),
                } }));
        },
    });
    const areExchangesFetched = exchangesStatus !== QueryStatus.uninitialized &&
        exchangesStatus !== QueryStatus.pending;
    const areExchangesEmpty = areExchangesFetched && exchangesList.length === 0;
    const skipPortfoliosQuery = IsDefined(profileId) && profileType === 'TRADER' ?
        !areExchangesFetched : true;
    const skipPortfolioSubscriptionsQuery = profileType === 'CLIENT' && isMyProfile ?
        !areExchangesFetched : true;
    /* portfolio queries */
    const { currentData: portfoliosList = [], isFetching: isFetchingPortfolios, isLoading: isLoadingPortfolios, status: portfoliosStatus, isError: isPortfoliosError, } = useGetCurrentProfilePortfoliosQuery({
        profileId: profileId,
        assessmentPassed: shouldFilterByAssessmentPassed({
            portfolioProfileId: profileId,
            isVerifiedTrader,
            currentUser: user,
        }),
    }, {
        skip: skipPortfoliosQuery,
        selectFromResult: (_a) => {
            var { currentData } = _a, rest = __rest(_a, ["currentData"]);
            return (Object.assign(Object.assign({}, rest), { currentData: filterPortfoliosListFromResponse(currentData || [], isClosedPortfolios) }));
        },
    });
    const { currentData: subscribedPortfoliosList = [], isFetching: isFetchingPortfolioSubscriptions, status: portfolioSubscriptionsStatus, isError: isPortfolioSubscriptionsError, } = useGetCurrentProfilePortfolioSubscriptionsQuery({ profileId: profileId }, {
        skip: skipPortfolioSubscriptionsQuery,
    });
    const areSubscribedPortfoliosFetching = portfolioSubscriptionsStatus === QueryStatus.pending;
    const arePortfoliosFetching = portfoliosStatus === QueryStatus.pending;
    const arePortfoliosFetched = portfoliosStatus !== QueryStatus.uninitialized &&
        portfoliosStatus !== QueryStatus.pending;
    const areSubscribedPortfoliosFetched = portfolioSubscriptionsStatus !== QueryStatus.uninitialized &&
        portfolioSubscriptionsStatus !== QueryStatus.pending;
    const arePortfoliosEmpty = arePortfoliosFetched && portfoliosList.length === 0;
    const areSubscribedPortfoliosEmpty = areSubscribedPortfoliosFetched && wallets.length === 0;
    const fetchingPortfoliosType = getListType(skipPortfoliosQuery, skipPortfolioSubscriptionsQuery);
    return {
        areExchangesEmpty,
        exchangesStatus,
        portfoliosList,
        isFetchingPortfolios,
        portfoliosStatus,
        subscribedPortfoliosList,
        portfolioSubscriptionsStatus,
        isFetchingPortfolioSubscriptions,
        arePortfoliosEmpty,
        areSubscribedPortfoliosEmpty,
        displayPortfoliosList: !arePortfoliosEmpty || arePortfoliosFetching,
        displaySubscribedPortfoliosList: !areSubscribedPortfoliosEmpty || areSubscribedPortfoliosFetching,
        // TODO: discuss
        isFetching: isFetchingPortfolios || isFetchingPortfolioSubscriptions || isFetchingWallets,
        isLoadingWallets,
        isLoadingPortfolios,
        isError: isExchangesError,
        isPortfoliosError,
        isPortfolioSubscriptionsError,
        hasWallets: (exchangesList && exchangesList.length > 0) || isLoadingWallets,
        displayWallets: !areExchangesEmpty,
        wallets,
        exchangesList,
        fetchingPortfoliosType,
    };
};
export default useGetProfilePortfolios;
