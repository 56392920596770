import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from '@app/store/Hooks';
import HomePage from '../HomePage';
import MainPage from '../MainPage';
const HomeController = () => {
    const { auth } = useAppSelector(state => state.auth);
    if (!auth) {
        return _jsx(MainPage, {});
    }
    return _jsx(HomePage, {});
};
export default HomeController;
