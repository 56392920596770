export var ImageEditStatus;
(function (ImageEditStatus) {
    ImageEditStatus["Deleted"] = "DELETED";
    ImageEditStatus["Updated"] = "UPDATED";
})(ImageEditStatus || (ImageEditStatus = {}));
export var ProfileViewMode;
(function (ProfileViewMode) {
    ProfileViewMode["View"] = "VIEW";
    ProfileViewMode["Edit"] = "EDIT";
})(ProfileViewMode || (ProfileViewMode = {}));
export var CreateTraderProfileMode;
(function (CreateTraderProfileMode) {
    CreateTraderProfileMode["Create"] = "CREATE";
    CreateTraderProfileMode["Verify"] = "VERIFY";
})(CreateTraderProfileMode || (CreateTraderProfileMode = {}));
