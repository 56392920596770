import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerRow } from '@shared/ui/display/Containers';
import { ReactComponent as SquareIcon } from '@icons/wolfkit-light/square-on-square-light.svg';
import CopyLink from '@shared/ui/misc/CopyLink';
import Button from '@shared/ui/buttons/Button';
import Input from '@shared/ui/inputs/Input';
const ShareLinkContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 1.25,
    alignItems: 'end',
}));
const InputStyled = styled(Input)(props => ({
    '& .MuiOutlinedInput-input': {
        color: props.theme.palette.primary.main,
    },
}));
const ShareLinkInput = ({ label = undefined, link, onCopySuccess = undefined, size = 'medium', buttonSize = 'large', buttonType = 'button', }) => (_jsxs(ShareLinkContainer, { children: [_jsx(InputStyled, { label: label, value: link, size: size, disabled: true }), _jsx(CopyLink, { link: link, onCopySuccess: onCopySuccess, children: _jsx(Button, { startIcon: SquareIcon, size: buttonSize, type: buttonType }) })] }));
export default ShareLinkInput;
