var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { setAuthenticationMethod, setAuthenticationMode, setConfirmationEmail as setConfirmationEmailAction, setEmailForReset, closeResetForm as closeResetFormAction, returnToSignIn as returnToSignInAction, openResetPassword as openResetPasswordAction, AuthenticationMethod, AuthenticationMode, } from '@entities/auth';
import { useAppSelector } from '@store/Hooks';
import { useRegisterMutation, useLoginMutation, useLazyGetResetPasswordLinkQuery, useLazyGetEmailVerificationLinkQuery, } from '@shared/api/auth';
import { ResetPasswordVariants } from './constants';
const useAuth = () => {
    const { authMethod, authMode, authStep, auth, emailForConfirmation, emailForReset, } = useAppSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [register, { isLoading: isRegistering }] = useRegisterMutation();
    const [loginReq, { isLoading: isSigningIn }] = useLoginMutation();
    const [getPasswordLinkReq, { isLoading: isResetting }] = useLazyGetResetPasswordLinkQuery();
    const [getEmailVerificationLinkReq, { isLoading: isGettingEmailVerificationLink },] = useLazyGetEmailVerificationLinkQuery();
    // const [getBinanceLink] = useLazyGetBinanceLinkQuery()
    const openSignIn = () => {
        dispatch(setAuthenticationMode(AuthenticationMode.SIGN_IN));
    };
    const openSignUp = () => {
        dispatch(setAuthenticationMode(AuthenticationMode.SIGN_UP));
    };
    const openResetPassword = () => {
        dispatch(openResetPasswordAction());
    };
    const openEmailForm = () => {
        dispatch(setAuthenticationMethod(AuthenticationMethod.EMAIL));
    };
    const openPhoneForm = () => {
        dispatch(setAuthenticationMethod(AuthenticationMethod.PHONE_NUMBER));
    };
    const handleSignUp = (input) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield register(input);
        if (res === null || res === void 0 ? void 0 : res.data) {
            return true;
        }
        return { error: res === null || res === void 0 ? void 0 : res.error };
    });
    const setEmailForPasswordReset = (email) => {
        dispatch(setEmailForReset(email));
    };
    const obtainResetLink = (variant_1, ...args_1) => __awaiter(void 0, [variant_1, ...args_1], void 0, function* (variant, email = undefined) {
        if (variant === ResetPasswordVariants.EMAIL) {
            if (email) {
                setEmailForPasswordReset(email);
            }
            const requestedEmail = email || emailForReset;
            if (requestedEmail) {
                const res = yield getPasswordLinkReq({ email: requestedEmail });
                return !res.error;
            }
        }
        return false;
    });
    const setConfirmationEmail = (email) => {
        dispatch(setConfirmationEmailAction(email));
    };
    const closeResetForm = () => {
        dispatch(closeResetFormAction());
    };
    const handleBinanceAuth = () => __awaiter(void 0, void 0, void 0, function* () {
        // const response = await getBinanceLink()
        // if (response?.data?.binanceLink) {
        //     window.location.replace(response.data.binanceLink.link)
        // }
    });
    const returnToSignIn = () => {
        dispatch(returnToSignInAction());
    };
    return {
        openSignIn,
        openEmailForm,
        openPhoneForm,
        openSignUp,
        openResetPassword,
        handleSignUp,
        obtainResetLink,
        setConfirmationEmail,
        setEmailForPasswordReset,
        returnToSignIn,
        closeResetForm,
        loginReq,
        authData: auth,
        authMethod,
        authMode,
        authStep,
        emailForConfirmation,
        emailForReset,
        handleBinanceAuth,
        isSigningUp: isRegistering,
        isSigningIn,
        isResetting,
        getEmailVerificationLinkReq,
        isGettingEmailVerificationLink,
    };
};
export default useAuth;
