import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerRow } from '@shared/ui/display/Containers';
import PortfolioActions from '../../components/Actions';
import PortfolioHeadline from '../../../PortfolioHeadline';
import ListContent from './ListConent';
import ApiConnectionFailureInline from '../components/ApiConnectionFailureInline';
const Container = styled(ContainerRow)((props) => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.m,
}));
const ActionsContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const PortfolioCardViewList = ({ portfolio = undefined, displayState, primaryActionType, onAction = () => { }, moreActionBtnOptions = undefined, showActionsBtn = true, isLoading = false, }) => (_jsxs(Container, { children: [_jsx(PortfolioHeadline, { portfolio: portfolio, isLoading: isLoading }), displayState === 'default' && (_jsx(ListContent, { portfolio: portfolio, isLoading: isLoading })), _jsxs(ActionsContainer, { children: [displayState === 'error' && portfolio && (_jsx(ApiConnectionFailureInline, { portfolio: portfolio, onAction: onAction })), showActionsBtn && !isLoading && portfolio && (_jsx(PortfolioActions, { portfolio: portfolio, primaryActionType: primaryActionType, displayState: displayState, onAction: onAction, moreActionBtnOptions: moreActionBtnOptions }))] })] }));
export default PortfolioCardViewList;
