import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LogoIcon from '../LogoIcon';
const LogoContainer = styled.div((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs,
    cursor: 'pointer',
}));
const Title = styled(Typography)(() => ({
    display: 'inline',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.456px',
    pointerEvents: 'none',
    userSelect: 'none',
}));
const Logo = ({ inverted = false, onClick = () => { } }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsxs(LogoContainer, { role: 'img', onClick: onClick, children: [_jsx(LogoIcon, { variant: inverted ? 'inverted' : 'primary' }), _jsx(Title, { color: inverted ?
                    theme.customColors.portfolio.onHover :
                    theme.palette.text.primary, children: t('app_name', { ns: 'common' }) })] }));
};
export default Logo;
