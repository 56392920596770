import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { ContainerColumn } from '@shared/ui/display/Containers';
import Input from '@shared/ui/inputs/Input';
const FormInnerColumn = styled(ContainerColumn)(() => ({
    maxWidth: 395,
}));
const InputContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.hasError ?
        props.theme.spacing_sizes.xs * 0.5 : props.theme.spacing_sizes.s * 5,
}));
const UnauthorizedStateInputs = ({ control, hasNameError = undefined }) => {
    const { t } = useTranslation();
    return (_jsxs(FormInnerColumn, { children: [_jsx(InputContainer, { hasError: Boolean(hasNameError), children: _jsx(Controller, { name: 'name', control: control, render: ({ field, fieldState }) => {
                        var _a, _b;
                        return (_jsx(Input, Object.assign({}, field, { label: t('support.send_request.name.title'), placeholder: t('support.send_request.name.placeholder'), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, InputLabelProps: { shrink: true }, maxCount: 40, required: true })));
                    } }) }), _jsx(Controller, { name: 'email', control: control, render: ({ field, fieldState }) => {
                    var _a, _b;
                    return (_jsx(Input, Object.assign({}, field, { label: t('support.send_request.email.title'), placeholder: t('support.send_request.email.placeholder'), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, InputLabelProps: { shrink: true }, required: true })));
                } })] }));
};
export default UnauthorizedStateInputs;
