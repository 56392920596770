import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useState } from 'react';
import { ReactComponent as AvatarIconComponent } from '@icons/wolfkit-light/avatar-icon.svg';
import { getProfileNameInitials } from '@utils/profile';
import LazyImgLoader from '@shared/ui/misc/LazyImgLoader';
const AVATAR_SIZES = {
    small: {
        fontSize: '24px',
    },
    medium: {
        fontSize: '36px',
    },
    large: {
        fontSize: '58px',
    },
    thumbnail: {
        fontSize: '14px',
    },
    activityImage: {
        fontSize: '10px',
    },
    avatarSwitcher: {
        fontSize: '12px',
    },
};
const InitialsAvatarContainer = styled.div(props => ({
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${props.theme.customColors.avatar.background}`,
    color: `${props.theme.customColors.avatar.text}`,
    fontSize: AVATAR_SIZES[props.size].fontSize,
    fontWeight: 500,
    userSelect: 'none',
    cursor: 'inherit',
}));
const AvatarIcon = styled(AvatarIconComponent)(() => ({
    width: '100%',
    height: '100%',
}));
const AvatarImageComponent = styled.img(() => ({
    borderRadius: 'inherit',
    width: '100%',
    height: '100%',
}));
const InitialsAvatar = ({ children, size, }) => _jsx(InitialsAvatarContainer, { size: size, children: children });
const AvatarImage = ({ publicName = undefined, imageUrl = undefined, size = 'small', lazy = false }) => {
    const [imageState, setImageState] = useState('loading');
    const handleLoadSuccess = () => setImageState('success');
    const handleLoadError = () => setImageState('error');
    if (imageUrl && imageState !== 'error') {
        return lazy ?
            (_jsx(LazyImgLoader, { url: imageUrl, onLoadSuccess: handleLoadSuccess, onLoadError: handleLoadError, children: _jsx(AvatarImageComponent, { src: imageUrl }) })) :
            (_jsx(AvatarImageComponent, { src: imageUrl }));
    }
    if (publicName === null || publicName === void 0 ? void 0 : publicName.length) {
        const initials = getProfileNameInitials(publicName);
        return _jsx(InitialsAvatar, { size: size, children: initials });
    }
    return _jsx(AvatarIcon, {});
};
export default AvatarImage;
