import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import Badge from '@shared/ui/badges/Badge';
import { BodySmall, Title } from '@shared/ui/display/Typography';
import { ReactComponent as CheckmarkIconComponent } from '@icons/wolfkit-light/checkmark-light.svg';
import { ReactComponent as XmarkIcon } from '@icons/wolfkit-light/xmark-light.svg';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import Icon from '@shared/ui/icons/Icon';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import { formatUSD } from '@utils/numbers';
import { formatDateDefault } from '@utils/date-time';
import { numberToWord } from '@app/locale';
import AvailabilityBadge from './components/AvailabilityBadge';
import { defineInfoCellStyles } from '../utils';
const PlanListContainer = styled(ContainerRow)((props) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const defineActiveStyles = ({ theme, active, isPlanDescription, }) => {
    if (active) {
        return {
            backgroundColor: theme.customColors.shades.primaryLight,
            boxShadow: theme.customColors.switcher.boxShadowActive,
            border: 'unset',
        };
    }
    if (isPlanDescription) {
        return {
            backgroundColor: '#F8F9FA',
            boxShadow: 'unset',
            border: 'unset',
        };
    }
    return {
        backgroundColor: 'transparent',
        boxShadow: 'unset',
        border: 'unset',
    };
};
const InfoContainer = styled(PlanListContainer)(props => (Object.assign({ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: props.flex, borderRadius: 12, maxWidth: 320, gap: 0 }, defineActiveStyles({
    theme: props.theme,
    active: props.active,
    isPlanDescription: props.isPlanDescription,
}))));
const PlanTitle = styled(Title)(props => ({
    fontSize: 30,
    lineHeight: '36px',
    textAlign: 'center',
    fontWeight: 600,
    position: 'relative',
    marginBottom: props.theme.spacing_sizes.xs * 0.75,
}));
const PlanDescription = styled(BodySmall)(props => ({
    height: 40,
    fontSize: 16,
    color: props.theme.palette.text.secondary,
    padding: `0 ${props.theme.spacing_sizes.xs * 4.5}px`,
    lineHeight: '19px',
    textAlign: 'center',
}));
const ButtonContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
    boxSizing: 'border-box',
    alignItems: 'center',
    marginBottom: props.theme.spacing_sizes.l,
}));
const CheckmarkIcon = styled(CheckmarkIconComponent)(props => ({
    path: {
        stroke: props.theme.palette.text.disabled,
        strokeWidth: 0,
    },
}));
const PlanTextContainer = styled(ContainerColumn)(props => ({
    alignItems: 'center',
    padding: `${props.theme.spacing_sizes.l}px 0`,
    width: '100%',
    height: 80,
    borderBottom: '1px solid #EFF1F3',
}));
const BadgeContainer = styled.span(props => ({
    position: 'absolute',
    top: 4,
    color: props.theme.palette.warning.main,
    backgroundColor: props.theme.palette.warning.light,
    padding: `${props.theme.spacing_sizes.s}px ${props.theme.spacing_sizes.xs * 1.25}px`,
    fontSize: 14,
    marginLeft: 14,
    borderRadius: 6,
    boxSizing: 'border-box',
    lineHeight: '20px',
}));
const InfoCell = styled(ContainerColumn, {
    shouldForwardProp: propName => propName !== 'align' &&
        propName !== 'hasBorder' && propName !== 'isPlanDescription' &&
        propName !== 'isFirstElement',
})(props => (Object.assign({ alignItems: props.align || 'unset', height: 'auto', borderBottom: props.hasBorder ? `1px solid ${props.theme.palette.primary.light}` : 'unset', boxSizing: 'border-box' }, defineInfoCellStyles({
    theme: props.theme,
    isFirstElement: props.isFirstElement,
    isPlanDescription: props.isPlanDescription,
}))));
const FeeCell = styled(InfoCell)(props => ({
    fontSize: `${props.theme.baseSize * 4}px`,
    fontWeight: 600,
    padding: `${props.theme.spacing_sizes.l}px 0px`,
}));
const RecommendedBadge = () => {
    const { t } = useTranslation();
    return (_jsx(BadgeContainer, { children: t('settings.tabs.subscription.subscription_plan.recommended_badge') }));
};
const LimitsNumber = styled.span(() => ({
    fontWeight: 600,
    lineHeight: '19px',
}));
const LimitsText = styled.span(() => ({
    fontSize: 12,
}));
const SubscriptionPlanCard = ({ plan = undefined, currentSubscription = undefined, isLoading = false, isSelected = false, onSelect = undefined, onCancel = () => { }, onlyDisplayFieldDescription = false, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isWithSubscription = currentSubscription && currentSubscription.platformType === (plan === null || plan === void 0 ? void 0 : plan.name);
    const getFee = () => {
        if (onlyDisplayFieldDescription) {
            return null;
        }
        const fee = Number(plan === null || plan === void 0 ? void 0 : plan.unitAmount);
        return !Number.isNaN(fee) && fee === 0 ?
            t('settings.tabs.subscription.subscription_plan.free') :
            `${formatUSD(fee)} / ${t('periods.month', { ns: 'common' }).toString().toLowerCase()}`;
    };
    const handleSelect = () => {
        if (onSelect && plan) {
            onSelect(plan.id);
        }
    };
    const handleCancel = () => {
        if (plan) {
            onCancel(plan.id);
        }
    };
    const getButtons = () => {
        var _a;
        if (onlyDisplayFieldDescription) {
            return null;
        }
        if (isSelected) {
            return (_jsxs(ButtonContainer, { children: [_jsx(Badge, { variant: 'plain', color: 'primary', style: {
                            padding: theme.spacing_sizes.xs * 1.25,
                        }, startAdornment: (_jsx(Icon, { IconComponent: CheckmarkIcon })), children: t('settings.tabs.subscription.current_plan') }), isWithSubscription &&
                        currentSubscription.isRecurring && (_jsx(BodySmall, { color: theme.palette.text.secondary, children: t('settings.tabs.subscription.subscription_plan.monthly_renew', { date: formatDateDefault(new Date(currentSubscription.currentPeriodEnd), i18n.language) }) })), isWithSubscription && (_jsx(Button, { variant: 'plain', color: 'primary', size: 'small', onClick: handleCancel, startIcon: XmarkIcon, children: t('settings.tabs.subscription.subscription_plan.cancel_plan') }))] }));
        }
        if (!((_a = plan === null || plan === void 0 ? void 0 : plan.metadata) === null || _a === void 0 ? void 0 : _a.isAvailable)) {
            return (_jsx(Badge, { variant: 'tinted', style: {
                    backgroundColor: theme.palette.primary.light,
                    fontSize: 14,
                }, children: t('settings.tabs.subscription.subscription_plan.coming_soon') }));
        }
        return (_jsx(ButtonContainer, { children: _jsx(Button, { variant: 'tinted', color: 'primary', size: 'medium', onClick: handleSelect, children: t('settings.tabs.subscription.subscription_plan.select_plan_btn') }) }));
    };
    return (_jsxs(InfoContainer, { active: isSelected, flex: onlyDisplayFieldDescription ? 1 : 2, isPlanDescription: !onlyDisplayFieldDescription, children: [_jsxs(PlanTextContainer, { children: [_jsx(PlanTitle, { children: _jsx(WithSkeleton, { isLoading: isLoading, width: 200, height: 36, children: (_a = plan === null || plan === void 0 ? void 0 : plan.metadata) === null || _a === void 0 ? void 0 : _a.title[i18n.language] }) }), _jsx(PlanDescription, { children: _jsx(WithSkeleton, { isLoading: isLoading, width: 250, height: 18, children: (_b = plan === null || plan === void 0 ? void 0 : plan.metadata) === null || _b === void 0 ? void 0 : _b.description[i18n.language] }) })] }), _jsx(InfoCell, { align: !onlyDisplayFieldDescription ? 'center' : 'start', isFirstElement: true, isPlanDescription: !onlyDisplayFieldDescription, hasBorder: true, children: _jsx(WithSkeleton, { isLoading: isLoading, width: 150, height: 34, children: !onlyDisplayFieldDescription ? (_jsxs(_Fragment, { children: [_jsx(LimitsNumber, { children: `${numberToWord(((_c = plan === null || plan === void 0 ? void 0 : plan.metadata) === null || _c === void 0 ? void 0 : _c.portfoliosLimit) || 0, t)} ${t('portfolio.portfolio')}` }), _jsx(LimitsText, { children: t('settings.tabs.subscription.subscription_plan.per_exchange') })] })) : t('settings.tabs.subscription.subscription_plan.copy_trading_limit') }) }, 'limits'), _jsx(InfoCell, { align: !onlyDisplayFieldDescription ? 'center' : 'start', isPlanDescription: !onlyDisplayFieldDescription, hasBorder: true, children: _jsx(WithSkeleton, { isLoading: isLoading, variant: 'circular', width: 24, height: 24, children: onlyDisplayFieldDescription ?
                        t('settings.tabs.subscription.subscription_plan.spot_copy') :
                        (_jsx(AvailabilityBadge, { isAvailable: !!((_d = plan === null || plan === void 0 ? void 0 : plan.metadata) === null || _d === void 0 ? void 0 : _d.spotCopy) })) }) }, 'spot-copy'), _jsx(InfoCell, { align: !onlyDisplayFieldDescription ? 'center' : 'start', isPlanDescription: !onlyDisplayFieldDescription, hasBorder: true, children: _jsx(WithSkeleton, { isLoading: isLoading, variant: 'circular', width: 24, height: 24, children: onlyDisplayFieldDescription ?
                        t('settings.tabs.subscription.subscription_plan.margin') :
                        (_jsx(AvailabilityBadge, { isAvailable: !!((_e = plan === null || plan === void 0 ? void 0 : plan.metadata) === null || _e === void 0 ? void 0 : _e.marginCopy) })) }) }, 'margin'), _jsx(InfoCell, { align: !onlyDisplayFieldDescription ? 'center' : 'start', isPlanDescription: !onlyDisplayFieldDescription, hasBorder: true, children: _jsx(WithSkeleton, { isLoading: isLoading, variant: 'circular', width: 24, height: 24, children: onlyDisplayFieldDescription ?
                        t('settings.tabs.subscription.subscription_plan.futures') :
                        (_jsx(AvailabilityBadge, { isAvailable: !!((_f = plan === null || plan === void 0 ? void 0 : plan.metadata) === null || _f === void 0 ? void 0 : _f.futuresCopy) })) }) }, 'futures'), _jsx(InfoCell, { align: !onlyDisplayFieldDescription ? 'center' : 'start', isPlanDescription: !onlyDisplayFieldDescription, hasBorder: true, children: _jsx(WithSkeleton, { isLoading: isLoading, width: 150, height: 34, children: onlyDisplayFieldDescription ?
                        t('settings.tabs.subscription.subscription_plan.futures') :
                        (_g = plan === null || plan === void 0 ? void 0 : plan.metadata) === null || _g === void 0 ? void 0 : _g.support[i18n.language] }) }, 'support'), _jsx(FeeCell, { align: !onlyDisplayFieldDescription ? 'center' : 'start', children: _jsx(WithSkeleton, { isLoading: isLoading, width: 150, height: 34, children: getFee() }) }), _jsx(WithSkeleton, { isLoading: isLoading, children: getButtons() })] }));
};
export default SubscriptionPlanCard;
