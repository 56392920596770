import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { BodyMedium } from '@shared/ui/display/Typography';
import Button from '@shared/ui/buttons/Button';
const Container = styled(ContainerColumn)((props) => ({
    alignItems: 'center',
    justifyContent: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const ActiveBalancePieError = ({ onUpdateApiKeyClick, }) => {
    const { t } = useTranslation();
    return (_jsxs(Container, { children: [_jsx(BodyMedium, { children: t('table.common.no_data') }), _jsx(Button, { variant: 'plain', color: 'primary', size: 'medium', onClick: onUpdateApiKeyClick, children: t('portfolio.update_api_key') })] }));
};
export default ActiveBalancePieError;
