import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeIcon } from '@icons/wolfkit-light/house-light.svg';
import { ReactComponent as MessageIcon } from '@icons/wolfkit-light/message-bubble-light.svg';
import { ReactComponent as MarketplaceIcon } from '@icons/wolfkit-light/compass-light.svg';
import { ReactComponent as SettingsIcon } from '@icons/wolfkit-light/gear-light.svg';
import { ReactComponent as SupportIcon } from '@icons/wolfkit-light/question-circle-light.svg';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { toggleNavSidebar } from '@store/slices/application';
import { getAbsolutePath } from '@app/routing/helpers';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import Icon from '@shared/ui/icons/Icon';
import { ContainerColumn } from '@shared/ui/display/Containers';
import { formatUSD } from '@shared/lib/numbers';
import { IsDefined } from '@shared/lib/js-ts';
import SidebarSubItem from './components/SidebarSubItem';
import SidebarItem from './components/SidebarItem';
import SidebarProfileItem from './components/SidebarProfileItem';
import HoverMenuSubItem from './components/HoverMenuSubItem';
// NOTE: +1 pixel to take into account our border-right
const SidebarCollapsedWidth = 71;
const SidebarOpenedWidth = 292;
const SubItemElementHeight = 36;
const SubItemListMargin = 12;
const Container = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'collapsed' })(props => ({
    width: props.collapsed ? SidebarCollapsedWidth : SidebarOpenedWidth,
    height: 'calc(100vh - 72px)',
    zIndex: 1,
    cursor: 'pointer',
    boxSizing: 'border-box',
    maxWidth: SidebarOpenedWidth,
    padding: `${props.theme.spacing_sizes.l}px 15px ${props.theme.spacing_sizes.xs * 2}px`,
    backgroundColor: `${props.theme.customColors.surface.surface}`,
    borderRight: `1px solid ${props.theme.customColors.surface.outline}`,
    transition: 'width 0.25s',
}));
const NavContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'collapsed' })(props => ({
    gap: props.collapsed ? props.theme.spacing_sizes.xs * 0.5 : 0,
}));
const StaticContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    flexShrink: 0,
    gap: props.theme.spacing_sizes.s,
    borderTop: '1px solid #ECEEF0',
    paddingTop: props.theme.spacing_sizes.s,
}));
const Sidebar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { navigate, routes } = useAppNavigation();
    const { user } = useAppSelector((state) => state.user);
    const { currentUserProfile } = useAppSelector((state) => state.profile);
    const isSidebarOpen = useAppSelector(state => state.app.isNavSidebarOpen);
    const isRouteSelected = useCallback((matchingRoutes) => {
        for (let i = 0; i < matchingRoutes.length; i += 1) {
            if (matchPath({ path: matchingRoutes[i], end: false }, location.pathname)) {
                return true;
            }
        }
        return false;
    }, [location]);
    const containerOnClick = () => {
        dispatch(toggleNavSidebar());
    };
    const goToOverview = () => {
        navigate(routes.HOME_OVERVIEW);
    };
    const goToPlatformBalance = () => {
        navigate(routes.HOME_BALANCE);
    };
    const goToProfile = () => {
        // here we dont use href because of checking
        if (currentUserProfile) {
            navigate(routes.MY_PROFILE);
        }
    };
    // TODO: for other elements we could add memo func which could define height of each subItemList
    const subItemHeight = SubItemElementHeight * 3 + SubItemListMargin;
    const subItemCollapsedHeight = 0;
    return (_jsxs(Container, { onClick: containerOnClick, collapsed: !isSidebarOpen, role: 'navigation', children: [_jsxs(NavContainer, { collapsed: !isSidebarOpen, role: 'list', children: [_jsxs(SidebarItem, { "aria-label": 'Home', href: getAbsolutePath(routes.MY_PROFILE), text: t('pages.home', { ns: 'common' }), Icon: _jsx(Icon, { IconComponent: HomeIcon }), selected: isRouteSelected([
                            String(getAbsolutePath(routes.MY_PROFILE)),
                            String(getAbsolutePath(routes.HOME_BALANCE)),
                            String(getAbsolutePath(routes.HOME_OVERVIEW)),
                        ]), collapsed: !isSidebarOpen, elementOrder: 0, subItemCollapsedHeight: subItemCollapsedHeight, subItemHeight: subItemHeight, hoverMenuItems: (_jsxs(_Fragment, { children: [_jsx(HoverMenuSubItem, { text: t('pages.overview.overview', { ns: 'common' }), collapsed: !isSidebarOpen, onClick: goToOverview, selected: isRouteSelected([
                                        String(getAbsolutePath(routes.HOME_OVERVIEW)),
                                    ]) }), _jsx(HoverMenuSubItem, { text: t('pages.overview.balance', { ns: 'common' }), badge: IsDefined(user === null || user === void 0 ? void 0 : user.totalAssetsUsd) ?
                                        formatUSD(user === null || user === void 0 ? void 0 : user.totalAssetsUsd) : undefined, collapsed: !isSidebarOpen, onClick: goToPlatformBalance, selected: isRouteSelected([
                                        String(getAbsolutePath(routes.HOME_BALANCE)),
                                    ]) }), _jsx(HoverMenuSubItem, { text: t('pages.my_profile', { ns: 'common' }), onClick: goToProfile, collapsed: !isSidebarOpen, selected: isRouteSelected([
                                        String(getAbsolutePath(routes.MY_PROFILE)),
                                    ]) })] })), children: [_jsx(SidebarSubItem, { "aria-label": 'Overview', href: getAbsolutePath(routes.HOME_OVERVIEW), text: t('pages.overview.overview', { ns: 'common' }), collapsed: !isSidebarOpen, selected: isRouteSelected([
                                    String(getAbsolutePath(routes.HOME_OVERVIEW)),
                                ]) }), _jsx(SidebarSubItem, { "aria-label": 'Balance', href: getAbsolutePath(routes.HOME_BALANCE), text: t('pages.overview.balance', { ns: 'common' }), badge: IsDefined(user === null || user === void 0 ? void 0 : user.totalAssetsUsd) ?
                                    formatUSD(user === null || user === void 0 ? void 0 : user.totalAssetsUsd) : undefined, collapsed: !isSidebarOpen, selected: isRouteSelected([
                                    String(getAbsolutePath(routes.HOME_BALANCE)),
                                ]) }), _jsx(SidebarSubItem, { "aria-label": 'My Profile', text: t('pages.my_profile', { ns: 'common' }), onClick: goToProfile, collapsed: !isSidebarOpen, selected: isRouteSelected([
                                    String(getAbsolutePath(routes.MY_PROFILE)),
                                ]) })] }), _jsx(SidebarItem, { "aria-label": 'Marketplace', href: getAbsolutePath(routes.MARKETPLACE), text: t('pages.marketplace.marketplace', { ns: 'common' }), Icon: _jsx(Icon, { IconComponent: MarketplaceIcon }), selected: isRouteSelected([String(getAbsolutePath(routes.MARKETPLACE))]), collapsed: !isSidebarOpen, elementOrder: 1 }), _jsx(SidebarItem, { text: t('pages.messages', { ns: 'common' }), Icon: _jsx(Icon, { IconComponent: MessageIcon }), collapsed: !isSidebarOpen, badge: t('pages.coming_soon', { ns: 'common' }), counterSize: 'large', elementOrder: 2, disabled: true })] }), _jsxs(StaticContainer, { role: 'list', children: [_jsx(SidebarProfileItem, { collapsed: !isSidebarOpen }), _jsxs(ContainerColumn, { children: [_jsx(SidebarItem, { "aria-label": 'Settings', href: getAbsolutePath(routes.SETTINGS), text: t('pages.settings', { ns: 'common' }), Icon: _jsx(Icon, { IconComponent: SettingsIcon }), selected: isRouteSelected([String(getAbsolutePath(routes.SETTINGS))]), collapsed: !isSidebarOpen }), _jsx(SidebarItem, { "aria-label": 'Support', href: getAbsolutePath(routes.SUPPORT), text: t('pages.support', { ns: 'common' }), Icon: _jsx(Icon, { IconComponent: SupportIcon }), collapsed: !isSidebarOpen })] })] })] }));
};
export default Sidebar;
