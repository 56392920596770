import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { Body, BodySmall } from '@shared/ui/display/Typography';
import Badge from '@shared/ui/badges/Badge';
const Container = styled(ContainerRow)(props => ({
    boxSizing: 'border-box',
    width: 'auto',
    alignItems: 'center',
    padding: '10px 0',
    gap: props.theme.spacing_sizes.m,
}));
const StatusBar = styled.div(() => ({
    width: 3,
    height: '100%',
    borderRadius: 4,
}));
const TitleContainer = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
    justifyContent: 'space-between',
}));
const Asset = ({ asset, assetShoulder, positive, }) => {
    const theme = useTheme();
    return (_jsxs(Container, { children: [_jsx(StatusBar, { style: {
                    backgroundColor: positive ?
                        theme.palette.success.main :
                        theme.palette.error.main,
                } }), _jsxs(TitleContainer, { children: [_jsx(Body, { children: asset.toUpperCase() }), _jsx(Body, { children: "Perpetual" })] }), _jsx(Badge, { variant: 'tinted', color: 'secondary', children: _jsx(BodySmall, { children: `${assetShoulder}X` }) })] }));
};
const RenderAsset = ({ cellParams }) => (_jsx(Asset, { asset: cellParams.rowData.asset, assetShoulder: cellParams.rowData.asset_shoulder, positive: cellParams.rowData.pnl > 0 }));
export default RenderAsset;
