import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import { BodySemiBold } from '@shared/ui/display/Typography';
const PortfolioTestEditorContainer = styled(ContainerColumn) `
    width: 500px;
    height: auto;
`;
const ButtonsContainer = styled(ContainerRow) `
    height: auto;
    gap: ${props => props.theme.spacing_sizes.s}px;
    justify-content: right;
    margin-top: ${props => props.theme.spacing_sizes.l}px;
`;
const stringifyBeautify = (portfolio) => JSON.stringify(portfolio, undefined, 4);
// TODO: for testing purposes. remove
const PortfolioTestEditor = ({ portfolio, onSave, onReset }) => {
    const [portfolioData, setPortfolioData] = useState(stringifyBeautify(portfolio));
    useEffect(() => {
        setPortfolioData(stringifyBeautify(portfolio));
    }, [portfolio, setPortfolioData]);
    const onSubscribe = () => {
        try {
            let newPortfolio = JSON.parse(portfolioData);
            if (typeof newPortfolio !== 'object') {
                newPortfolio = portfolio;
            }
            if (!newPortfolio.user_subscription_summary) {
                newPortfolio.user_subscription_summary = {
                    queue_position: 1,
                    duration: 14,
                    past_month_difference: 11.11,
                    unrealized_pl_value: 14.63,
                    unrealized_pl_percentange: 4.8,
                    unrealized_pl_currency: '$',
                    unrealized_pl_trend: 'up',
                    realized_pl_value: 24.23,
                    realized_pl_currency: '$',
                    realized_pl_trend: 'up',
                    realized_pl_percentange: 5.63,
                    subscription_date: new Date('December 17, 2023'),
                };
            }
            onSave(stringifyBeautify(newPortfolio));
        }
        catch (ex) {
            onSave(stringifyBeautify(portfolio));
            console.warn(ex);
            alert('Ошибка считывания портфолио! Проверьте целостность JSON или нажмите Reset чтобы внести изменения еще раз.');
        }
    };
    const onUnsubscribe = () => {
        try {
            let newPortfolio = JSON.parse(portfolioData);
            if (typeof newPortfolio !== 'object') {
                newPortfolio = portfolio;
            }
            if (newPortfolio.user_subscription_summary) {
                delete newPortfolio.user_subscription_summary;
            }
            onSave(stringifyBeautify(newPortfolio));
        }
        catch (ex) {
            console.warn(ex);
            alert('Ошибка считывания портфолио! Проверьте целостность JSON или нажмите Reset чтобы внести изменения еще раз.');
        }
    };
    return (_jsxs(PortfolioTestEditorContainer, { children: [_jsx(TextField, { variant: 'outlined', hiddenLabel: true, multiline: true, 
                // minRows={5}
                maxRows: 20, value: portfolioData, onChange: (event) => setPortfolioData(event.target.value) }), _jsxs(ButtonsContainer, { children: [_jsx(Button, { variant: 'contained', disableElevation: true, onClick: () => onSubscribe(), children: _jsx(BodySemiBold, { children: "Subscribe" }) }), _jsx(Button, { variant: 'contained', disableElevation: true, onClick: () => onUnsubscribe(), children: _jsx(BodySemiBold, { children: "Unsubscribe" }) }), _jsx(Button, { variant: 'contained', disableElevation: true, onClick: () => onSave(portfolioData), children: _jsx(BodySemiBold, { children: "Save" }) }), _jsx(Button, { variant: 'secondary', color: 'secondary', disableElevation: true, onClick: onReset, children: _jsx(BodySemiBold, { children: "Reset" }) })] })] }));
};
export default PortfolioTestEditor;
