import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
import { Body } from '@shared/ui/display/Typography';
const Container = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 4,
}));
const ExchangeName = withTextEllipsis(Body);
const Exchange = ({ exchange, }) => (_jsxs(Container, { children: [_jsx(ExchangeIcon, { type: exchange.type }), _jsx(ExchangeName, { tooltipText: exchange.properties.connectionName, children: exchange.properties.connectionName })] }));
export default Exchange;
