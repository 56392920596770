import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { PortfolioCopiersTable } from '@features/portfolio/portfolio-copiers';
import { isUserPortfolioOwner, isUserSubsbribedToPortfolio, usePortfolioOutletContext } from '@entities/portfolio';
import { ContainerRow, SurfaceColumn } from '@shared/ui/display/Containers';
import { BidToQueue } from '@features/portfolio/portfolio-copy';
import { useGetCurrentProfilePortfolioSubscriptionsQuery, useGetPortfolioQuery } from '@shared/api/portfolio';
import { IsDefined } from '@shared/lib/js-ts';
import { useAppSelector } from '@app/store/Hooks';
const Container = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
}));
const CopiersTableContainer = styled(SurfaceColumn)(props => ({
    height: 'auto',
    padding: props.theme.spacing_sizes.l,
    // TODO: move shadow to theme
    boxShadow: '0px 1px 3px 0px #E6E8EB',
    position: 'sticky',
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
}));
const BidToQueueContainer = styled(SurfaceColumn)(() => ({
    maxWidth: 423,
    height: 'auto',
    boxShadow: '0px 1px 3px 0px #E6E8EB',
}));
const PortfolioCopiers = () => {
    const { portfolioId } = usePortfolioOutletContext();
    const { user } = useAppSelector(state => state.user);
    const { data: portfolio } = useGetPortfolioQuery({ portfolioId }, { skip: !IsDefined(portfolioId) });
    const { currentData } = useGetCurrentProfilePortfolioSubscriptionsQuery({ profileId: user === null || user === void 0 ? void 0 : user.activeProfileId }, { skip: !IsDefined(user === null || user === void 0 ? void 0 : user.activeProfileId) });
    const isOwner = isUserPortfolioOwner(portfolio, user);
    const subscription = useMemo(() => isUserSubsbribedToPortfolio(currentData || [], user), [user, currentData]);
    return (_jsxs(Container, { children: [!isOwner &&
                _jsx(BidToQueueContainer, { children: _jsx(BidToQueue, { portfolioId: String(portfolioId), userSubscription: subscription }) }), _jsx(CopiersTableContainer, { children: _jsx(PortfolioCopiersTable, { portfolioId: String(portfolioId) }) })] }));
};
export default PortfolioCopiers;
