import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { SiteContentSidebarProvider, SiteContentSidebar } from '@shared/ui/sidebars/SiteContentSidebar';
import GlobalSidebar from '@shared/ui/sidebars/GlobalSidebar';
import { ContainerColumn, ContainerRow } from '@shared/ui/display/Containers';
import NavSidebar from '@widgets/navigation/Sidebar';
import NavTopbar from '@widgets/navigation/Topbar';
import Footer from '@widgets/navigation/Footer';
import { useAppSelector } from '@store/Hooks';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import { useGetMeQuery } from '@shared/api/current-user';
import { useScrollableContext } from '../ScrollableContent';
const MinContentWidth = 1366;
const AppLayoutContainer = styled(ContainerColumn)(() => ({
    overflow: 'hidden',
}));
const SidebarWithContent = styled(ContainerRow)(() => ({
    height: 'auto',
    overflow: 'hidden',
}));
const Content = styled(ContainerRow)((props) => ({
    overflow: 'hidden',
    height: 'auto',
    backgroundColor: props.theme.customColors.surface.background,
    transition: 'transform 0.25s, width 0.25s',
}));
const ContentScrollable = styled(ContainerColumn)(() => ({
    overflow: 'auto',
    transition: 'transform 0.25s, width 0.25s',
}));
const ContentNarrowContainer = styled(ContainerColumn)(() => ({
    minWidth: MinContentWidth,
}));
const OutletContainer = styled(ContainerColumn)(() => ({
    flex: 1,
    width: 'auto',
    height: 'auto',
}));
const LayoutAuthorized = () => {
    const { i18n } = useTranslation();
    const { scrollableContainerRef } = useScrollableContext();
    const { navigate, routes } = useAppNavigation();
    const { isFetching } = useGetMeQuery();
    const { auth } = useAppSelector(state => state.auth);
    const { language: currentLanguage } = useAppSelector(state => state.app);
    const isAuthError = !isFetching && !auth;
    useEffect(() => {
        i18n.changeLanguage(currentLanguage);
    }, [currentLanguage, i18n]);
    useEffect(() => {
        if (isAuthError) {
            navigate(routes.AUTH);
        }
    }, [
        isAuthError,
        routes,
        navigate
    ]);
    if (isAuthError) {
        return null;
    }
    return (_jsxs(AppLayoutContainer, { children: [_jsx(NavTopbar, {}), _jsxs(SidebarWithContent, { children: [_jsx(NavSidebar, {}), _jsx(SiteContentSidebarProvider, { children: _jsxs(Content, { children: [_jsx(ContentScrollable, { ref: scrollableContainerRef, children: _jsxs(ContentNarrowContainer, { children: [_jsx(OutletContainer, { role: 'main', children: _jsx(Outlet, {}) }), _jsx(Footer, {})] }) }), _jsx(SiteContentSidebar, {})] }) }), _jsx(GlobalSidebar, {})] })] }));
};
export default LayoutAuthorized;
