import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Body } from '@shared/ui/display/Typography';
const BodyStyled = styled(Body)((props) => ({
    color: props.theme.palette.text.secondary,
}));
const Copyrights = () => {
    const { t } = useTranslation();
    return (_jsx(BodyStyled, { children: t('links.copyrights', { ns: 'common', year: new Date().getFullYear() }) }));
};
export default Copyrights;
