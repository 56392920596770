import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerRow } from '@shared/ui/display/Containers';
const BalanceUnitContainer = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'disabled' })(props => ({
    display: 'inline-flex',
    gap: props.theme.spacing_sizes.xs * 0.5,
    alignItems: 'center',
    width: 'auto',
    height: 20,
    padding: `${props.theme.spacing_sizes.xs * 0.25}px 0`,
    boxSizing: 'border-box',
    opacity: props.disabled ? 0.5 : 1,
}));
const BalanceUnitCircle = styled.span(props => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: props.color,
}));
const BalanceUnitSum = styled.span(() => ({
    fontSize: 12,
}));
const PortfolioBalanceUnit = ({ amount, color, currency, disabled = undefined, }) => (_jsxs(BalanceUnitContainer, { disabled: disabled, children: [_jsx(BalanceUnitCircle, { color: color }), _jsx(BalanceUnitSum, { children: `${currency} ${amount}` })] }));
export default PortfolioBalanceUnit;
