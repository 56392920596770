import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { ContainerRow } from '@shared/ui/display/Containers';
import Button from '@shared/ui/buttons/Button';
import { StepContainer } from '@widgets/sidebars/shared';
const ErrorContainer = styled(ContainerRow)();
const Error = () => {
    const { t } = useTranslation();
    return (_jsx(SidebarInner, { children: _jsx(ErrorContainer, { children: _jsx(StepContainer, { title: 'Profile Management', content: (_jsx(_Fragment, { children: "test" })), buttons: (_jsx(Button, {})) }) }) }));
};
export default Error;
