import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ArrowUpIcon } from '@icons/wolfkit-light/arrow-small-up-light.svg';
import Button from '@shared/ui/buttons/Button';
import { useScrollableContext } from '@app/layouts/ScrollableContent';
import { useSiteContentSidebar } from '@shared/ui/sidebars/SiteContentSidebar';
const ButtonDisplayScrollThreshold = 300;
const ScrollUpButton = () => {
    const { t } = useTranslation();
    const { scrollableContainerRef } = useScrollableContext();
    const [showButton, setShowButton] = useState(false);
    const { sidebarContainerRef } = useSiteContentSidebar();
    const [sidebarWidth, setSidebarWidth] = useState(0);
    const scrollUp = () => {
        var _a;
        (_a = scrollableContainerRef === null || scrollableContainerRef === void 0 ? void 0 : scrollableContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handleScrollChange = useCallback(() => {
        var _a;
        setShowButton((((_a = scrollableContainerRef === null || scrollableContainerRef === void 0 ? void 0 : scrollableContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) || 0) >= ButtonDisplayScrollThreshold);
    }, [scrollableContainerRef]);
    useEffect(() => {
        const scrollableContainer = scrollableContainerRef === null || scrollableContainerRef === void 0 ? void 0 : scrollableContainerRef.current;
        scrollableContainer === null || scrollableContainer === void 0 ? void 0 : scrollableContainer.addEventListener('scroll', handleScrollChange);
        return () => {
            scrollableContainer === null || scrollableContainer === void 0 ? void 0 : scrollableContainer.removeEventListener('scroll', handleScrollChange);
        };
    }, [
        scrollableContainerRef,
        handleScrollChange
    ]);
    useEffect(() => {
        const sidebar = sidebarContainerRef;
        if (!sidebar) {
            return;
        }
        const checkSidebarWidth = () => {
            setSidebarWidth(sidebar.scrollWidth);
        };
        // using resizeObserver in order to track changes in sidebar width
        // it is used to offset the ScrollUp button when inner-sidebar is open
        const resizeObserver = new ResizeObserver(checkSidebarWidth);
        resizeObserver.observe(sidebar);
        // initial check
        checkSidebarWidth();
        // cleanup on unmount
        // eslint-disable-next-line consistent-return
        return () => {
            resizeObserver.disconnect();
        };
    }, [
        sidebarContainerRef,
    ]);
    if (!showButton) {
        return null;
    }
    return (_jsx(Button, { variant: 'tinted', style: {
            position: 'fixed',
            right: 96 + sidebarWidth,
            bottom: 52,
            zIndex: 1,
        }, startIcon: ArrowUpIcon, onClick: scrollUp, children: t('actions.go_up', { ns: 'common' }) }));
};
export default ScrollUpButton;
