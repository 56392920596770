var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import styled from '@emotion/styled/macro';
import { useDispatch } from 'react-redux';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { ReactComponent as SwitchIcon } from '@icons/wolfkit-light/arrow-2-cw-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { useAppSelector } from '@app/store/Hooks';
import { useSwitchProfileMutation } from '@shared/api/profile';
import { cancelProfileEdit } from '@entities/profile';
const AvatarWrapper = styled.div(() => ({
    display: 'flex',
    flexShrink: 0,
    width: 20,
    height: 20,
    borderRadius: '50%',
}));
const IconStyled = styled(Icon)();
const SwitchContainer = styled('div', {
    shouldForwardProp: propName => propName !== 'orientation' &&
        propName !== 'flexDirection',
})(props => ({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 1.25,
    borderRadius: 14,
    backgroundColor: props.theme.customColors.badge.profileTabActive.surface,
    flexDirection: props.flexDirection,
    padding: '4px 6px',
    cursor: 'pointer',
    outlineWidth: '3px',
    outlineStyle: 'solid',
    outlineColor: 'transparent',
    transition: 'outline-color 300ms ease-out, background-color 300ms ease-out',
    [`${IconStyled}`]: {
        rotate: props.orientation === 'horizontal' ? '90deg' : '0deg',
        transition: 'rotate 300ms ease-out',
    },
    '&:hover': {
        backgroundColor: '#F8FAFF',
        outlineColor: props.theme.palette.primary.light,
        [`${IconStyled}`]: {
            rotate: props.orientation === 'horizontal' ? '180deg' : '90deg',
        },
    },
    '&:active': {
        outlineColor: props.theme.customColors.shades.primaryMidLight,
        backgroundColor: props.theme.palette.primary.light,
    },
}));
const ProfileSwitcher = ({ className = undefined, orientation = 'horizontal', flexDirection = 'row', }) => {
    var _a, _b, _c;
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useAppSelector(state => state.user);
    const [switchProfile, { reset }] = useSwitchProfileMutation();
    const handleClick = (event) => __awaiter(void 0, void 0, void 0, function* () {
        if (user === null || user === void 0 ? void 0 : user.unactiveProfileId) {
            event.stopPropagation();
            reset();
            yield switchProfile({ profileId: user.unactiveProfileId }).then(() => {
                dispatch(cancelProfileEdit());
            });
        }
    });
    return (_jsxs(SwitchContainer, { className: className, onClick: handleClick, orientation: orientation, flexDirection: flexDirection, children: [_jsx(IconStyled, { size: 20, color: theme.customColors.portfolio.hover, IconComponent: SwitchIcon }), _jsx(AvatarWrapper, { children: _jsx(AvatarImage, { imageUrl: (_b = (_a = user === null || user === void 0 ? void 0 : user.unactiveProfile) === null || _a === void 0 ? void 0 : _a.profilePicture) === null || _b === void 0 ? void 0 : _b.thumbnail, publicName: (_c = user === null || user === void 0 ? void 0 : user.unactiveProfile) === null || _c === void 0 ? void 0 : _c.displayName, size: 'avatarSwitcher' }) })] }));
};
export default ProfileSwitcher;
