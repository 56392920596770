import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import ListItemText from '@mui/material/ListItemText';
import { ContainerRow } from '@shared/ui/display/Containers';
const MenuHeader = styled(ContainerRow)(props => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    padding: `${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.m}px 
              ${props.theme.spacing_sizes.s}px ${props.theme.spacing_sizes.xs * 1.25}px`,
    margin: 0,
}));
const MenuHeaderText = styled(ListItemText)(props => ({
    margin: 0,
    color: props.theme.palette.text.secondary,
}));
const DropdownMenuHeader = ({ children = undefined }) => (_jsx(MenuHeader, { children: _jsx(MenuHeaderText, { children: children }) }));
export default DropdownMenuHeader;
