export var PortfolioTypes;
(function (PortfolioTypes) {
    PortfolioTypes["All"] = "all";
    PortfolioTypes["Spot"] = "spot";
    PortfolioTypes["Margin"] = "margin";
    // Futures = 'futures',
})(PortfolioTypes || (PortfolioTypes = {}));
export var PortfolioCategories;
(function (PortfolioCategories) {
    PortfolioCategories["BestOverall"] = "best_overall";
    PortfolioCategories["BestPnl"] = "best_pnl";
})(PortfolioCategories || (PortfolioCategories = {}));
