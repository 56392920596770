import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import { ContainerColumn, NarrowSection } from '@shared/ui/display/Containers';
import ErrorComponent from '@shared/ui/errors/Error/ErrorComponent';
const FrameContainer = styled(ContainerColumn, {
    shouldForwardProp: propName => propName !== 'gap' && propName !== 'align',
})(props => ({
    alignItems: props.align,
    gap: props.gap,
    backgroundColor: `${props.theme.customColors.surface.background}`,
}));
const PageHeader = styled(ContainerColumn, {
    shouldForwardProp: propName => propName !== 'gap' &&
        propName !== 'align' && propName !== 'padding',
})(props => ({
    height: 'auto',
    flexShrink: 0,
    alignItems: 'center',
    backgroundColor: `${props.theme.customColors.surface.surface}`,
    padding: props.padding,
    gap: props.gap,
    boxSizing: 'border-box',
    borderBottom: `1px solid ${props.theme.customColors.surface.outline}`,
}));
const NarrowContainer = styled(NarrowSection.withComponent(ContainerColumn))((props) => ({
    gap: props.theme.spacing_sizes.l,
}));
const PageFrameContainer = ({ align = 'center', gap = undefined, header, headerStyles = {}, bottom = undefined, error = undefined, isLoading = false, }) => {
    if (error) {
        return (_jsx(FrameContainer, { children: _jsx(ErrorComponent, { variant: error }) }));
    }
    return (_jsxs(FrameContainer, { align: align, gap: gap, children: [_jsx(LoadingIndicator, { isLoading: isLoading, takeFullContainerSpace: true }), _jsx(PageHeader, Object.assign({}, headerStyles, { children: _jsx(NarrowContainer, { children: header }) })), _jsx(NarrowContainer, { children: bottom || null })] }));
};
export default PageFrameContainer;
